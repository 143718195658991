import { apiWA } from "../apiServer/apiWA"
import { setIsLoadingAC } from "./AppReducer"
import { thunkActionRootType } from "./storeRedux"


export const WaReducer = (state: any, action: any) => {
    switch(action.type){
        default:
            return state
    }
}



export const sendWaMessageClientTC = (clientId: string, message: string):thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiWA.sendToClient(clientId,message)
    } catch (error) {
        
    } finally {
        dispatch(setIsLoadingAC(false))
    }
}