import styled, { css } from "styled-components"
import { theme } from "./Theme.styled"
import { Icon } from "./Icon"
import React from "react"




type propsType = {
    variant: "switch" | "primary"
    value: boolean,
    render: () => React.JSX.Element,
    disabled?: boolean
}
type labelType = {
    value: boolean,
    disabled?: boolean
}
type circlePropsType = {
    disabled?: boolean
}
export const CheckBox = (props: propsType) => {

    return (
        <>
            {
                props.variant === "switch" &&
                <Wrapper value={props.value}>
                    {props.variant === "switch" && <Circle disabled = {props.disabled} className="circle" />}

                    {
                        props.render()
                    }
                </Wrapper>
            }
            {
                props.variant === "primary" &&
                <Box disabled = {props.disabled} value = {props.value}>
                    <Icon svgID="check" height={14} width={14}/>
                    {
                        props.render()
                    }
                </Box>
            }
        </>

    )
}




const Wrapper = styled.label<labelType>`
    width: 43px;
    height: 26px;
    background: ${props => props.value ? "#41DB7F" : "rgb(118, 118, 118)"} ;
    border-radius: 13px;
    position: relative;
    
    input {
        opacity: 0;
    };
   ${props => props.value && css<labelType>`
        .circle {
            transform: translateX(16px);
            transition: 0.5s;
        }
   `};
   ${props => !props.value && css<labelType>`
        .circle {
            transform: translateX(0);
            transition: 0.5s;
        }
   `};
   
`
const Circle = styled.div<circlePropsType>`
    width: 24px;
    height: 24px;
    background: #fafafa;
    border-radius: 50%;
    position: absolute;
    z-index: 2;
    top: 1px;
    left: 1px;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
`
const Box = styled.label<labelType>`
    width: 20px;
    height: 20px;
    border-radius: 6px;
    border: 1px solid;
    border-color: ${theme.colors.main};
    opacity: 0.3;
    ${props => props.disabled && css<labelType>`
            cursor: not-allowed;
        `};
    svg {
        opacity: 0;
    };
    ${props => props.value === true && css<labelType>`
        background: rgba(93, 95, 239, 0.05);
        box-shadow: 0px 0px 0px 4px rgba(93, 95, 239, 0.10); 
        border: 2px solid;
        opacity: 1;
        border-color: ${theme.colors.main};
        svg {
            opacity: 1;
        }
    `};
   
    input {
        opacity: 0;

    };
    &:hover {
        opacity: 1;
    };
`
