import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import {
  loginResponseType,
  userCompleteRegisterT,
  userRegisterResponseType,
} from "../apiTypes/responses/userResponsesType";
import { TcompleteRegister } from "../apiTypes/formTypes/completeRegisterType";
import { tokenService } from "./tokenService";
import {
  store,
  thunkActionRootType,
  thunkDispatchRootType,
} from "../store/storeRedux";
import { authMeTC, userResetAC } from "../store/UserReducer";
import { logoutTC } from "../store/AuthReducer";
import { setIsLoadingAC } from "../store/AppReducer";

const authInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_ENDPOINT}/api/users`,
  withCredentials: true,
});
const tokenInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_ENDPOINT}/api/users`,
  withCredentials: true,
});

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_ENDPOINT}/api/users`,
  withCredentials: true,
});

authInstance.interceptors.request.use(
  (config) => {
    const token = tokenService.getToken();
    if (token) config!.headers!.Authorization = token;
    return config;
  },
  (error) => Promise.reject(error)
)
authInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error) => {
    const { config } = error;
    if (error.response.status === 401 && !config._retry) {
      config._retry = true;
      try {
        const resp = await apiAuth.refreshToken()
        tokenService.saveToken(resp.data.accessToken)
        authInstance.defaults.headers.common.Authorization = tokenService.getToken()
        return authInstance(config)
      } catch (error) {
        tokenService.removeToken()
      }
     
    } 
    return Promise.reject(error)
  }
);

export const apiAuth = {
  login(email: string, password: string) {
    return instance.post<loginResponseType>("/login", { email, password });
  },
  register(payload: { email: string; password: string }) {
    return instance.post<userRegisterResponseType>("/register", payload);
  },
  completeRegister(payload: TcompleteRegister, id: string) {
    return instance.post<userCompleteRegisterT>(
      `/register/complete/${id}`,
      payload
    );
  },
  completeUserInfo(userId: string){
    return instance.put(`/register/userInfo/complete`,{userId})
  },
  authMe() {
    return authInstance.get<loginResponseType>("/auth-me");
  },
  refreshToken() {
    return tokenInstance.post<{ accessToken: string }>("/refresh-token");
  },
  logout() {
    return authInstance.post("/logout");
  },
  passwordRecovery(email: string) {
    return instance.post("/password-recovery", { email });
  },
  checkRecoveryCode(code: string, userId: string, password: string) {
    return instance.post("/restore-password", { code, userId, password });
  },
  try() {
    return authInstance.get("/try");
  },

};
