export enum TimeTablePeriod  {
    WEEK = "week",
    DAY = "day"
}

export type commonReducerAT = selectMasterAT | toggleSelectModeAT |  selectModeAT | selectPeriodTimeTableOptionAT
export const CommonReducer = (state: CommonReducerType = initState, action:commonReducerAT):CommonReducerType => {
    switch(action.type){
        case "COMMON/SELECT-MASTER":
            return {...state, selectedMaster: action.master}
        case "COMMON/TOGGLE-SELECT":
            return {...state,enableSelect: action.enableSelect}
            case "APP/SET-SELECT-MODE":
                return {...state, selectMode: action.selectMode}
            case "APP/SET-PERIOD-OPTION":
                return {...state, optionItem: action.payload}
        default:
            return state
    }
}

export const selectMasterAC = (master: selectedMasterType): selectMasterAT => {
    return {type: "COMMON/SELECT-MASTER",master}
}
export const toggleSelectModeAC = (enableSelect: boolean):toggleSelectModeAT => {
    return {type: "COMMON/TOGGLE-SELECT",enableSelect}
}
export const setSelectModeAC = (selectMode: "single" | "broad"):selectModeAT => {
    return {type: "APP/SET-SELECT-MODE", selectMode}
}
export const setPeriodOptionTimeTableAC = (payload: TimeTablePeriod):selectPeriodTimeTableOptionAT => {
    return {type: "APP/SET-PERIOD-OPTION",payload}
}
export const optionItems: { title: string, value: TimeTablePeriod }[] = [
    { title: "Неделя", value: TimeTablePeriod.WEEK },
    { title: "День", value: TimeTablePeriod.DAY },
]
export type CommonReducerType = {
    selectedMaster: selectedMasterType,
    enableSelect: boolean,
    selectMode: "single" | "broad",
    optionItem: TimeTablePeriod
}


const initState:CommonReducerType = {
    selectedMaster: {
        masterID: "",
        avatarUrl: "",
        masterName: ""
    },
    enableSelect: true,
    selectMode: "single",
    optionItem: TimeTablePeriod.WEEK
}


export type selectedMasterType = {
    masterID: string,
    avatarUrl: string,
    masterName: string
}



type selectMasterAT = {
    type: "COMMON/SELECT-MASTER",
    master:selectedMasterType
}
type toggleSelectModeAT = {
    type: "COMMON/TOGGLE-SELECT",
    enableSelect: boolean
}
type selectModeAT = {
    type: "APP/SET-SELECT-MODE",
    selectMode: "single" | "broad"
}
type selectPeriodTimeTableOptionAT = {
    type: "APP/SET-PERIOD-OPTION",
    payload: TimeTablePeriod
}