import { useEffect, useState } from "react"
import { css, styled } from "styled-components"
import { useDispatch, useSelector } from "react-redux"
import { ThunkDispatch } from "redux-thunk"
import { AppActionsType, AppRootState } from "../../store/storeRedux"
import { RightsType, teammateProSettingsType, teamMateViewType, updateTeammateRightsInputType } from "../../apiTypes/root/userProPlus.type"
import { changeTeamMateRighsTC } from "../../store/UserProPlusReducer"

type dragEvtType = React.DragEvent<HTMLDivElement>
type nameType = keyof updateTeammateRightsInputType["rights"]
type propsType = {
    element: {value: RightsType, user: teammateProSettingsType},
    teamMateID: string,
    name: nameType,
    isAllowed: boolean
}
const selectAppState = (state: AppRootState) => state.appState
export const DragDown = (props: propsType) => {
    const dispatch: ThunkDispatch<AppRootState, unknown, AppActionsType> = useDispatch()

    const appState = useSelector(selectAppState)
    const [activeName, setActiveName] = useState<RightsType>(props?.element?.value as RightsType)
    const [position, setPosition] = useState<number>(0)
    const [show, setShow] = useState(false)
    const [draggableEl, setDraggableEl] = useState<RightsType | null>(null)
    const dragStartHandler = (el: RightsType, e: dragEvtType) => {
        setDraggableEl(el)
        setPosition(e.pageX)
        console.log("start")
    }
    const dropHandler = (el: RightsType, e: dragEvtType, name: nameType) => {
       const data:updateTeammateRightsInputType = {rights: {settingsRights: props.element.user.settingsRights, clientRights: props.element.user.clientRights,timeTableRights: props.element.user.timeTableRights}}
        data.rights[name] = el
        console.log("ert",el, data)

        dispatch(changeTeamMateRighsTC(data,props.teamMateID))
       console.log("drop")
    }
    const dragOverHandler = (el: RightsType, e: dragEvtType) => {
        e.preventDefault()
        e.pageX <= position ? setShow(true) : setShow(false)
       console.log("over")
    }
   
   
    const dragEndHandler = (e: dragEvtType) => {
       console.log("end")
        // setDraggableEl(null)
        setShow(false)
    }
    useEffect(() => {
        setActiveName(props.element!.value)
    }, [props.element?.value])
   
    return (
        <DragDownWrapper>
            {
                appState.enableSwitch &&
                <DragDownContainer isAllowed = {props.isAllowed}  activeName={activeName} show={show} dragStartEl={draggableEl}>
                    <Circle dragStartEl={draggableEl} activeName={activeName} name={RightsType.A} draggable={props.isAllowed} onDragStart={(e) => dragStartHandler(RightsType.A, e)} onDrop={(e) => dropHandler(RightsType.A, e,props.name)} onDragOver={(e) => dragOverHandler(RightsType.A, e)} onDragEnd={dragEndHandler} />
                    <Circle dragStartEl={draggableEl} activeName={activeName} name={RightsType.B} draggable={props.isAllowed} onDragStart={(e) => dragStartHandler(RightsType.B, e)} onDrop={(e) => dropHandler(RightsType.B, e,props.name)} onDragOver={(e) => dragOverHandler(RightsType.B, e)} onDragEnd={dragEndHandler} />
                    <Circle dragStartEl={draggableEl} activeName={activeName} name={RightsType.C} show={show} draggable={props.isAllowed} onDragStart={(e) => dragStartHandler(RightsType.C, e)} onDrop={(e) => dropHandler(RightsType.C, e,props.name)} onDragOver={(e) => dragOverHandler(RightsType.C, e)} onDragEnd={dragEndHandler} />
                </DragDownContainer>
}            

        </DragDownWrapper>

    )
}

const DragDownWrapper = styled.div`
    display: flex;
    width: 66px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    height: 6px;
`
const DragDownContainer = styled.div<{ dragStartEl: RightsType | null, activeName: RightsType, show: boolean, isAllowed: boolean }>`
    height: 6px;
    background: #5D5FEF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    opacity: ${props => props.isAllowed ? 1 : 0.6};
    ${props => (props.dragStartEl === "A" || props.dragStartEl === "B") && css<{ dragStartEl: RightsType | null }>`
        gap: 14px;
    `};
    ${props => props.activeName === "B" && css<{ activeName: RightsType }>`
        gap: 14px;
    `};
    ${props => props.activeName === "C" && css<{ activeName: RightsType, show: boolean }>`
        gap:  14px;
    `};
    
`
const Circle = styled.div<{ activeName: RightsType, name: RightsType, dragStartEl: RightsType | null, show?: boolean }>`
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: #5D5FEF;
    display: none;
   
    ${props => props.dragStartEl === "A" && css<{ dragStartEl: RightsType | null, name: RightsType }>`
        ${props => props.name === "B" && css<{ dragStartEl: RightsType | null, name: RightsType }>`
            display: block;
        `}
    `};
    ${props => props.dragStartEl === "B" && css<{ dragStartEl: RightsType | null, name: RightsType, show?: boolean }>`
        ${props => props.name === "C" && css<{ dragStartEl: RightsType | null, name: RightsType, show?: boolean }>`
            display: ${props => props.show ? "none" : "block"};
            
        `}
    `}
    ${props => props.name === "A" && css<{ activeName: "A" | "B" | "C", name: "A" | "B" | "C" }>`
        display: block;
        
    `};
    ${props => props.activeName === "B" && css<{ activeName: "A" | "B" | "C", name: "A" | "B" | "C" }>`
        ${props => props.name === "B" && css<{ activeName: "A" | "B" | "C", name: "A" | "B" | "C" }>`
         display: block;
        `};
        
    `};
    ${props => props.activeName === "C" && css<{ activeName: "A" | "B" | "C", name: "A" | "B" | "C" }>`
        ${props => props.name === "C" && css<{ activeName: "A" | "B" | "C", name: "A" | "B" | "C" }>`
         display: block;
        `};
        ${props => props.name === "B" && css<{ activeName: "A" | "B" | "C", name: "A" | "B" | "C" }>`
         display: block;
        `};
        
    `};
`