import { css } from "styled-components";
import { styled } from "styled-components";

type propsType = {
    duration: string,
    time: string,
    device: "PC" | "mobile",
    isLast: boolean
    over?:boolean
}
export const NoteBox = styled.div<propsType>`
    width: 100%;
    position: absolute;
    z-index: 2;
    bottom: 0;
    right: 0;
    left: 0;
    height: calc(${props => parseInt(props.duration) / 60 * 100 - 1}%);
    top: calc(${props => (parseInt(props.time.split(":")[1]) / 60 * 100)}%);
    background: #5D5FEF;
    border-radius: 8px;
   
    .nameContainer {
     flex-direction: ${props => parseInt(props.duration) <= 35 ? "row" : "column"};
     gap: 5px;
    }
   
    .noteMedia {
       display: ${props => parseInt(props.duration) < 35 ? "none" : "flex"};
    };
    
    .sign {
        display:  ${props => parseInt(props.duration) < 40 ? "none" : "flex"};
    };
    .phone {
        display: ${props => parseInt(props.duration) < 35 ? "none" : "flex"};
    }
    ${props => props.device === "PC" && css<propsType>`
    &:hover {
        height: 120px;
        width: 150%;
        z-index: 4; 
        ${props => props.isLast && css<propsType>`
            transform: translateX(-32%);
        `} 
    };
    `}
    
    ${props => props.device === "mobile" && css<propsType>`
    svg {
        transform: scale(1.3);
    };
    .textareaMob {
            display: none;
            
        }
    .avaMobile {
        object-fit: cover;
        border-radius: 50%;
        ${props => parseInt(props.duration) >= 90  && css<propsType>`
            width: 60px !important;
            height: 60px !important;
            min-width: 60px !important;
            max-width: 60px !important;
            
        `};
        ${props => parseInt(props.duration) <= 80 && parseInt(props.duration) >= 40 && css<propsType>`
            width: 45px !important;
            height: 45px !important;
            min-width: 45px !important;
        `};
        ${props => parseInt(props.duration) <= 40 && parseInt(props.duration) >= 20 && css<propsType>`
            width: 22px !important;
            height: 22px !important;
            min-width: 22px !important;
        `};
        ${props => parseInt(props.duration) <= 20  && css<propsType>`
            display: none;
            .phone {
                display: none
            }
        `};
    };
    
    ${props => props.over && css<propsType>`
        height: 120px;
        z-index: 8; 
        .textareaMob {
            display: block;
            border-radius: 8px;
            margin-top: 5px;
        };
        .mobContent {
            margin-top: 5px;
        };
        .removeMobile {
            display: block;
            right: 0;
            top: 0;
            transform: scale(0.7);
        }
        .avaMobile {
            display: block;
            width: 55px !important;
            height: 55px !important;
            max-width: 55px !important;
            max-height: 55px !important;
            border-radius: 50% !important;
            min-width: 55px !important;
            min-height: 55px !important;
        };
        .phone {
            display: block;
        }
    `}
    
           
    
    `}
    
    
`
