import { useRef } from "react"
import { calendarStateType, workWithEndTimeWindowAC, workWithStartTimeWindowAC } from "../store/CalendarReducer"
import { useDispatch, useSelector } from "react-redux"
import { AppRootState } from "../store/storeRedux"
import { FlexBox } from "../styles/components/FlexBox"
import { Icon } from "../styles/components/Icon"
import { Text } from "../styles/components/Text"
import { theme } from "../styles/components/Theme.styled"
import { showTimePickerAC } from "../store/ModalWindowsReducer"
import { tariffType } from "../store/UserReducer"

const selectUser = (state:AppRootState) => state.user

type propsType = {
    disabledActions?: boolean
}
export const ButtonsPeriod = (props: propsType) => {
    const dispatch = useDispatch()
    const user = useSelector(selectUser)
    const calendarState = useSelector<AppRootState, calendarStateType>( state => state.calendarState)
    const divRefStart = useRef(null)
    const divRefEnd = useRef(null)
    const startText = calendarState.startTime !== '' ? calendarState.startTime : 'Начало'
    const endText = calendarState.endTime !== '' ? calendarState.endTime : 'Конец'
    const setPicker = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if(user.user?.tariff === tariffType.SuperPRO){
        if(props.disabledActions) return
        dispatch(showTimePickerAC(true))
        defineNeedWindow(e)
        return
      } else {
        dispatch(showTimePickerAC(true))
        defineNeedWindow(e)
      }
        
    }
    const defineNeedWindow = (e: any) => {
        //@ts-ignore
        if (divRefStart.current.contains(e.target)) {
           dispatch( workWithStartTimeWindowAC(true) )
           dispatch ( workWithEndTimeWindowAC(false) )
        }
        //@ts-ignore
        else if (divRefEnd.current.contains(e.target)) {
           dispatch ( workWithEndTimeWindowAC(true) )
           dispatch( workWithStartTimeWindowAC(false) )
        }
    }
    return (
        <FlexBox  direction="row" width="100%" gap={5} height="50px" padding="5px" marginTop="5px" >
            <FlexBox borderRadius="8px" padding="5px 5px 5px 28px" direction="row" align="center" justify="space-between" basis="100%" background="#fff" border="1px solid rgba(68, 68, 68, 0.4)" ref={divRefStart} onClick={(e) => {
               setPicker(e)

            }} >
                <Text font="mont" color={theme.colors.fonts.secondary} size={18}> {startText}</Text>
                <Icon svgID="row" width={8} height={4}/>
            </FlexBox>
            <FlexBox borderRadius="8px" padding="5px 5px 5px 28px" direction="row" justify="space-between" align="center" basis="100%" background="#fff" border="1px solid rgba(68, 68, 68, 0.4)" ref={divRefEnd} onClick={(e) => {
               setPicker(e)
            }} >
                <Text font="mont" color={theme.colors.fonts.secondary} size={18}>{endText}</Text>
                <Icon svgID="row" width={8} height={4}/>
            </FlexBox>
        </FlexBox>
    )
}