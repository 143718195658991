import axios, { AxiosError, isAxiosError } from "axios";
import { Dispatch } from "redux";
import { thunkActionRootType, thunkDispatchRootType } from "./storeRedux";
import { apiUser } from "../apiServer/apiUser";
import { setIsLoadingAC, setNetSessionsAC } from "./AppReducer";
import { socket } from "../apiServer/socket";
import { setLoginEmailErrorAC, setLoginPasswordErrorAC, setProfileReqErrorAC, setRegisterEmailErrorAC, setRegisterLoginErrorAC, setRegisterPhoneErrorAC } from "./ErrorMessagesReducer";
import { logoutTC, registerAC, setRegisterDataAC, setRegisterUserAC, userInfoType } from "./AuthReducer";
import { apiAuth } from "../apiServer/apiAuth";
import { tokenService } from "../apiServer/tokenService";
import { apiUserPay } from "../apiServer/apiUserPay";
import { showPayWindowAC } from "./ModalWindowsReducer";
import { deleteNewUserInvitationAC } from "./NotificationReducer";

export type actionUserTypes = getUsersType   | updateDataType | setProfileInfoType | selectTariffAT | checkBalanceAT | generateWorkIdAT
    | setIsCompletedFirstAT | completeRegisterAT | logoutAT | userPayAT | resetUserAT

export enum tariffType {
    PRO = "PRO",
    FREE = "FREE",
    SuperPRO = "SuperPRO"
}





export type rootUserType = {
    id: string,
    email: string,
    firstName: string,
    lastName: string
    thirdName: string,
    login: string,
    avatarUrl: string,
    color: string,
    isAdmin: boolean,
    phoneNumber: string,
    registerDate: string,
    clientCount: number,
    comments: string,
    tariff: tariffType,
    workID: string,
    userInfo: userInfoType,
    position: string,
    completeRegisterData: {
        firstLevel: boolean,
        secondLevel: boolean,
        completeUserInfo: boolean;

    },
    balance: number
    
}
export type stateUserType = {
    user: rootUserType | null,
    isAuth: boolean,
    userToken: string | null,
    
}
type getUsersType = {
    type: 'SET-USERS',
    user: rootUserType,
    userToken: string,
    isAuth: boolean
}

type completeRegisterAT = {
    type: "USER/COMPLETE-REGISTER",
    userId: string,
    firstLevel: boolean
}



type updateDataType = {
    type: 'UPDATE-DATA',
    color: string,
    phone: string
}

type setProfileInfoType = {
    type: 'SET-PROFILE-INFO',
    userAvatar: string,
    surname: string,
    name: string,
    patronymic: string,
}
type selectTariffAT = {
    type: "SELECT-TARIFF",
    tariff: tariffType
}




type checkBalanceAT = {
    type: "CHECK-BALANCE",
    balance?: number
}
type generateWorkIdAT = {
    type: "PROFILE/WORK-ID",
    workID: string
}
type setIsCompletedFirstAT = {
    type: "USER/SET-IS-COMPLETED-FIRST",
    value: boolean,
    userId:string
}
type logoutAT = {
    type: "USER/LOGOUT"
}
type userPayAT = {
    type: "USER/PAY",
    balance: number
}

type resetUserAT = {
    type: "USER/RESET"
}

const initState: stateUserType = {
    user: null,
    userToken: "",
    isAuth: false,
   

}



export const UserReducer = (state: stateUserType = initState, action: actionUserTypes): stateUserType => {
    switch (action.type) {
        case "SET-USERS": {
            return { ...state, userToken: action.userToken, user: {...action.user, avatarUrl: action.user.avatarUrl}, isAuth: action.isAuth }
        }
        case "USER/LOGOUT":
            state = initState
            return state
        case "UPDATE-DATA":
            return { ...state, user: { ...state.user as rootUserType,  color: action.color, phoneNumber: action.phone } }
        // case "CHECK-BALANCE":
        //     return { ...state, user: { ...state.user as rootUserType, balance: action.balance as number } }
        case "PROFILE/WORK-ID":
            return { ...state, user: { ...state.user as rootUserType, workID: action.workID } }
        case "SELECT-TARIFF":
            return { ...state, user: { ...state.user as rootUserType, tariff: action.tariff } }
        case "SET-PROFILE-INFO":
            return {
                ...state,
                user: { ...state.user as rootUserType, lastName: action.surname, firstName: action.name, thirdName: action.patronymic, avatarUrl: action.userAvatar }
            }
        case "USER/SET-IS-COMPLETED-FIRST":
            return {...state, user: {...state.user as rootUserType, id: action.userId, completeRegisterData: {firstLevel: false, secondLevel: false, completeUserInfo: false}}}
        case "USER/COMPLETE-REGISTER":
            return {...state, user: {...state.user as rootUserType, id: action.userId, completeRegisterData: {firstLevel: true, secondLevel: false, completeUserInfo: false}}}
        case "USER/PAY":
            return {...state, user: {...state.user as rootUserType, balance: state.user!.balance + action.balance}}
        case "USER/RESET":
            return initState
        default:
            return state
    }
}

const updateDataAC = ( color: string, phone: string):updateDataType => {
    return { type: "UPDATE-DATA", color, phone }
}
export const setUserAC = (user: rootUserType, userToken: string, isAuth: boolean): getUsersType => {
    return { type: "SET-USERS", user, userToken, isAuth }
}

  
export const logoutAC = ():logoutAT => ({ type: "USER/LOGOUT" })


const setProfileInfoAC = (userAvatar: string, surname: string, name: string, patronymic: string): setProfileInfoType => {
    return { type: 'SET-PROFILE-INFO', surname, name, patronymic, userAvatar }

}

const selectTariffAC = (tariff: tariffType): selectTariffAT => {
    return { type: "SELECT-TARIFF", tariff }
}
const checkBalanceAC = (balance: number): checkBalanceAT => {
    return { type: "CHECK-BALANCE", balance }
}
const generateWorkIdAC = (workID: string): generateWorkIdAT => {
    return { type: "PROFILE/WORK-ID", workID }
}

const userPayAC = (balance: number):userPayAT => ({type: "USER/PAY",balance})

export const userResetAC = ():resetUserAT => ({type: "USER/RESET"})




export const selectTariffTC = (id: string | null, tariff: tariffType): thunkActionRootType => async dispatch => {
    try {
        const res = await apiUser.selectTariff(id, tariff)
        dispatch(selectTariffAC(res.data.tariff))
    } catch (error: AxiosError | any) {
        if (isAxiosError(error)) {
          if (error.response?.status === 401) {
            dispatch(logoutTC());
          }
        }
      } finally {

    }
}

export const setProfileInfoTC = (id: string | null, userAvatar: File, surname: string, name: string, patronymic: string): thunkActionRootType => async dispatch => {
    try {
        const res = await apiUser.setProfileInfo( userAvatar, surname, name, patronymic)
        dispatch(setProfileInfoAC(res.data.avatarUrl, res.data.fullNameData.lastName, res.data.fullNameData.firstName, res.data.fullNameData.thirdName))
    } catch (error: AxiosError | any) {
        if (isAxiosError(error)) {
          if (error.response?.status === 401) {
            dispatch(logoutTC());
          }
        }
      } finally {

    }

}
export const generateWorkIdTC = (id: string): thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiUser.generateWorkID()
        dispatch(generateWorkIdAC(res.data.workID))
    } catch (error: AxiosError | any) {
        if (isAxiosError(error)) {
          if (error.response?.status === 401) {
            dispatch(logoutTC());
          }
        }
      } finally {
        dispatch(setIsLoadingAC(false))
    }
}





export const updateUserDataTC = (  oldPassword: string, newPassword: string, color: string, phone: string):thunkActionRootType => async dispatch => {
   dispatch(setIsLoadingAC(true))
    try {
        const res = await apiUser.updateUserData(oldPassword, newPassword,color,phone)
        
        dispatch(updateDataAC(res.data.color, res.data.phoneNumber))
        if(res.data.successData.field === "pass") dispatch(setProfileReqErrorAC("","",res.data.successData.message))
    } catch (error: AxiosError | any) {
        if(axios.isAxiosError(error)){
            if(error?.response?.data.field === "newPassword"){
                dispatch(setProfileReqErrorAC(error.response.data.message, "",""))
            }
            if(error?.response?.data.field === "oldPassword"){
                dispatch(setProfileReqErrorAC("", error.response.data.message,""))
            }
            if (error.response?.status === 401) {
                dispatch(logoutTC());
              }
        }
        
    } finally {
        dispatch(setIsLoadingAC(false))
    }
}
    
    



export const loginUserTC = (email: string, password: string): thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiAuth.login(email, password) 
        tokenService.saveToken(res.data.accessToken)
        const {id, email:emails, firstName, lastName, thirdName, avatarUrl, color, login, phoneNumber, isActivated,isAdmin, registerDate, comments, tariff, clientCount, completedSecondLayerRegister, completedTirdLayerRegister, workID, userInfo,balance, position, completeUserInfo} = res.data.user
        const user:rootUserType = {
            id, email:emails,firstName,lastName, thirdName, color, avatarUrl, login, phoneNumber, isAdmin, registerDate, comments, tariff,clientCount, completeRegisterData: {firstLevel: completedSecondLayerRegister, secondLevel: completedTirdLayerRegister, completeUserInfo}, workID,
            userInfo, balance, position
        }
        dispatch(setUserAC(user, res.data.accessToken, true))
        dispatch(setRegisterDataAC({firstLevel: completedSecondLayerRegister, secondLevel: completedTirdLayerRegister, completeUserInfo},true))
        socket.auth = {
            token: tokenService.getToken()
        }
        // socket.connect()
       
    } catch (error: any | AxiosError) {
      if( error.response.data && Array.isArray(error.response.data.errorMessages)) {
        error.response.data.errorMessages.map( (el:any) => {
            if(el.field === "password") {
                dispatch(setLoginPasswordErrorAC(el.message))
            } 
            if(el.field === "email") {
                dispatch(setLoginEmailErrorAC(el.message))
            } 

        } )
      }  
        if (error.response.data.message === "Пароли не совпадают") dispatch(setLoginPasswordErrorAC(error.response.data.message))
        if (error.response.data.message === "Вам необходимо активировать аккаунт") dispatch(setLoginPasswordErrorAC(error.response.data.message))
    } finally {
        dispatch(setIsLoadingAC(false))
    }
}



export const authMeTC = ():thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiAuth.authMe()
        const {id, email:emails, firstName, lastName, thirdName, avatarUrl, color, login, phoneNumber, isActivated,isAdmin, registerDate, comments, tariff, clientCount, completedSecondLayerRegister, completedTirdLayerRegister, workID, userInfo,balance, position, completeUserInfo} = res.data.user
        const user:rootUserType = {
            id, email:emails,firstName,lastName, thirdName, color, avatarUrl, login, phoneNumber, isAdmin, registerDate, comments, tariff,clientCount, completeRegisterData: {secondLevel: completedTirdLayerRegister , firstLevel: completedSecondLayerRegister, completeUserInfo}, workID,
            userInfo,
            balance,
            position
        }
        dispatch(setUserAC(user, res.data.accessToken, true))
        dispatch(setRegisterDataAC({firstLevel: completedSecondLayerRegister, secondLevel: completedTirdLayerRegister, completeUserInfo},true))

    } catch (error: AxiosError | any) {
        if(isAxiosError(error)){
            if(error.response?.status === 401){
                dispatch(logoutTC())
            }
        }
    } finally {
        dispatch(setIsLoadingAC(false))
    }
}

export const userPayTC = (balance: number):thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiUserPay.create(balance)
        dispatch(userPayAC(balance))
        dispatch(showPayWindowAC(false))
    } catch (error: AxiosError | any) {
        if (isAxiosError(error)) {
          if (error.response?.status === 401) {
            dispatch(logoutTC());
          }
        }
      } finally {
        dispatch(setIsLoadingAC(false))
    }
}

export  const acceptTeammateTC = (ownerId: string, notifId: string):thunkActionRootType=> async dispatch => {
    try {
        const res = await apiUser.acceptTeammate(ownerId, notifId)
        dispatch(deleteNewUserInvitationAC(notifId))
        dispatch(authMeTC())
    } catch (error) {
        
    }
}
export  const declineTeammateTC = (ownerId: string, notifId: string):thunkActionRootType=> async dispatch => {
    try {
        const res = await apiUser.declineTeammate(ownerId, notifId)
        dispatch(deleteNewUserInvitationAC(notifId))
    } catch (error) {
        
    }
}
export default UserReducer