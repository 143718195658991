import { NoteBox } from "../styles/components/NoteBox";
import { NoteContent } from "../styles/components/NoteContent";
import { NoteInfo } from "../styles/components/NoteInfo";
import { NoteMedia } from "../styles/components/NoteMedia";
import { Avatar } from "../styles/components/Avatar";
import { TextContainer } from "../styles/components/TextContainer";
import { notesStateType } from "../store/NotesReducer";
import { SuperButton } from "../styles/components/SuperButton";
import { Icon } from "../styles/components/Icon";
import { FlexBox } from "../styles/components/FlexBox";
import { Box } from "../styles/components/Box";
import { TextField } from "../styles/components/TextField";
import { Text } from "../styles/components/Text";
import { useDispatch, useSelector } from "react-redux";
import { AppRootState, thunkDispatchRootType } from "../store/storeRedux";
import {
  editNoteWindowAC,
  showRmNoteWindowAC,
} from "../store/ModalWindowsReducer";
import { noteHelper } from "../utils/note/note.helper";
import { tariffType } from "../store/UserReducer";

type propsType = {
  el: notesStateType;
  isLast: boolean
};
const selectUser = (state:AppRootState) => state.user
export const Note = (props: propsType) => {
  const dispatch: thunkDispatchRootType = useDispatch();
  const user = useSelector(selectUser)
  const { el } = props;
  const editNote = () => {
    if(user.user && user.user.tariff === tariffType.FREE){
      return
    } else {
      dispatch(editNoteWindowAC({ value: true, note: el }));
  }
    }
    
  return (
    <NoteBox
    isLast = {props.isLast}
      device="PC"
      onClick={editNote}
      duration={el.duration}
      time={el.time}
      key={el.id}
    >
      <NoteContent>
        <Box
        className="noteContentStatus"
          width="22px"
          height="18px"
          position="absolute"
          right="6px"
          top="0px"
          direction="row"
          style={{alignItems: "center"}}
        >
          <Icon
            transform="scale(0.6)"
            stroke="#fff"
            fill="none"
            svgID={noteHelper.getSvgId(el.status)}
            width={22}
            height={22}
          />
        </Box>
        <NoteMedia className="noteMedia">
          <SuperButton
            onClick={(e) => {
              e.stopPropagation();
              dispatch(showRmNoteWindowAC(true, el.id));
            }}
            className="remove"
            varinat="removeNote"
          >
            <Icon transform="scale(0.8)" svgID="closeOrRemove" width={24} height={24} />
          </SuperButton>

         
            <Avatar className="ava" src={el.clientAvatar} size="extraSmall" />
          
          <FlexBox className="sign" direction="row" gap={3}>
            <SuperButton varinat="fake">
              <Icon svgID="chat" width={10} height={10} />
            </SuperButton>
            <SuperButton varinat="fake">
              <Icon svgID="envelope" width={10} height={10} />
            </SuperButton>
          </FlexBox>
        </NoteMedia>
        <NoteInfo>
          <TextContainer gap={3}  justify="start" column component="TimeTable">
            <FlexBox  direction="column">
              <Text weight={600} className="name" size={10} font="Inter" color="#fff">
                {el.surname}
              </Text>
              <Text className="name" size={8} font="Inter" color="#fff">
                {el.name} {el.patronymic}
              </Text>
            </FlexBox>
            <FlexBox direction="row">
              <Text className="phone" size={8} font="Inter" color="#fff">
                {el.phone}
              </Text>
            </FlexBox>
          </TextContainer>
          <Box
            className="descript"
            bottom={"5px"}
            height="auto"
            position="absolute"
            gap={5}
          >
            <Text weight={300} size={8} color="#fff" font="Inter">
              Комментарии:
            </Text>
            <TextField justify="start" height="37px" width="90%" as="div">
              <Text font="Inter" size={11} color="#262626">
                {el.comments}
              </Text>
            </TextField>
          </Box>
        </NoteInfo>
      </NoteContent>
    </NoteBox>
  );
};
