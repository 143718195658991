import { useEffect, useState } from "react";
import { FlexBox } from "../../../styles/components/FlexBox";
import { SuperButton } from "../../../styles/components/SuperButton";
import { WaTimeTablePatternWindow } from "./Wa-TimeTable-pattern-window";
import { AppRootState, thunkDispatchRootType } from "../../../store/storeRedux";
import { useDispatch, useSelector } from "react-redux";
import { deleteWaTimeTablePatternTC, getWaTimeTablePatternTC, waPatternTimeTableType } from "../../../store/ProsettingsReducer";
import { theme } from "../../../styles/components/Theme.styled";
import { Text } from "../../../styles/components/Text";
import { Icon } from "../../../styles/components/Icon";
import { tariffType } from "../../../store/UserReducer";
import { WaTimeTablePatternEdit } from "./Wa-TimeTablePattern-edit";

const seletcProsettings = (state: AppRootState) => state.proSettings;
const selectUser = (state: AppRootState) => state.user;

export const WaTimeTablePatterns = () => {
  const dispatch: thunkDispatchRootType = useDispatch();
  const [modal, setModal] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<boolean>(false);
const [editEditedPattern, setEditedPattern] = useState<waPatternTimeTableType | null>(null)
  const proSettings = useSelector(seletcProsettings);
  const user = useSelector(selectUser);

  const editHandler = (v: waPatternTimeTableType) => {
    setEditedPattern(v)
    setEditModal(true)
  }
  const removeHandler = (id: string) => {
    dispatch(deleteWaTimeTablePatternTC(id, user.user?.tariff as tariffType))
  }
  useEffect(() => {
    if (user.user && user.user.tariff)
      dispatch(getWaTimeTablePatternTC(user.user.tariff));
  }, []);

  return (
    <FlexBox
      gap={15}
      marginTop="10px"
      direction="column"
      height="100%"
      width="100%"
    >
      <FlexBox height="40px" direction="row" width="130px">
        <SuperButton
          onClick={() => setModal(true)}
          hover
          hoverColor="#000"
          varinat="contained"
        >
          Добавить шаблон
        </SuperButton>
      </FlexBox>
      {modal && <WaTimeTablePatternWindow setModal={setModal} />}
      {editModal && editEditedPattern && <WaTimeTablePatternEdit setEditedPattern={setEditedPattern} pattern={editEditedPattern} setEditModal={setEditModal}/>}
      <FlexBox direction="column" gap={10} width="100%" height="100%">
        {proSettings.waTimeTablePatterns.map((w) => (
          <FlexBox
            align="center"
            padding="0 20px"
            borderRadius="8px"
            height="54px"
            background="grey"
            justify="space-between"
            direction="row"
            key={w.id}
          >
            <Text size={12} color="#fff" font="mont">
              {w.name}
            </Text>
            <FlexBox
              direction="row"
              align="center"
              justify="center"
              gap={8}
            >
              <SuperButton varinat="sort" onClick={(e) => editHandler(w)}>
                <Icon svgID="edit" width={18} height={16} />
              </SuperButton>
              <SuperButton varinat="sort" onClick={(e) => removeHandler(w.id)}>
                <Icon svgID="delete" width={18} height={16} />
              </SuperButton>
            </FlexBox>
          </FlexBox>
        ))}
      </FlexBox>
    </FlexBox>
  );
};
