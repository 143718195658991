import { apiNotifications } from "../apiServer/apiNotifications";
import { apiUserPro } from "../apiServer/apiUserPro";
import { notificationStateType, userInviteNotificationViewType, userTeammateNotifViewType } from "../apiTypes/root/notofocation.type";
import { clientRemindersViewForNotifType } from "../apiTypes/root/reminders.type";
import { setIsLoadingAC } from "./AppReducer";
import { thunkActionRootType, thunkDispatchRootType } from "./storeRedux";


const initState:notificationStateType = {
    clientReminders: [],
    userIviteNotifications: [],
    userTeammateNotifInfo: []
}


type getClientRemondersAT = {
    type: "NOTIFICATIONS/CLIENT",
    payload: clientRemindersViewForNotifType[]
  }
  type deactivateClientReminderAT = {
    type: "NOTIFICATION/DEACTIVATE/CLIENT-REMINDER",
    id: string
  }

  type getUserInvitationAT = {
    type: "NOTIFICATIONS/USER-INVITE",
    payload: userInviteNotificationViewType[]
  }
  type addNewUserInvitationAT  = {
    type: "NOTIFICATIONS/USER-INVITE-ADD-NOTIF",
    payload: userInviteNotificationViewType
  }
  type deleteNewUserInvitationAT  = {
    type: "NOTIFICATIONS/USER-INVITE-DELETE-NOTIF",
    id: string
  }
  type addUserTeammateNotifInfoAT = {
    type: "NOTIF/USER-TEAMATE-INFO-ADD",
    payload: userTeammateNotifViewType
  }
  type deleteUserTeammateNotifInfoAT = {
    type: "NOTIF/USER-TEAMATE-INFO-DELETE",
    id: string
  }

export type notificationAT = getClientRemondersAT | deactivateClientReminderAT | getUserInvitationAT | addNewUserInvitationAT 
| deleteNewUserInvitationAT | addUserTeammateNotifInfoAT | deleteUserTeammateNotifInfoAT


export const NotificationReducer = (state: notificationStateType = initState, action: notificationAT):notificationStateType => {
    switch(action.type){
        case "NOTIFICATIONS/CLIENT":
            return {...state, clientReminders: action.payload}
          case "NOTIFICATION/DEACTIVATE/CLIENT-REMINDER":
            return {...state, clientReminders: state.clientReminders.filter(r => r.id !== action.id)}
        case "NOTIFICATIONS/USER-INVITE":
            return {...state, userIviteNotifications: action.payload}
        case "NOTIFICATIONS/USER-INVITE-ADD-NOTIF":
            return {...state, userIviteNotifications: [...state.userIviteNotifications, action.payload]}
        case "NOTIFICATIONS/USER-INVITE-DELETE-NOTIF":
            return {...state, userIviteNotifications: state.userIviteNotifications.filter(el =>  el.id !== action.id)}
        case "NOTIF/USER-TEAMATE-INFO-ADD":
            return {...state, userTeammateNotifInfo: [...state.userTeammateNotifInfo, action.payload]}
        case "NOTIF/USER-TEAMATE-INFO-DELETE":
            return {...state, userTeammateNotifInfo: state.userTeammateNotifInfo.filter(el => el.id !== action.id)}
        default:
            return state
    }
}

const getClientRemindersAC = (payload: clientRemindersViewForNotifType[]):getClientRemondersAT => ({type: "NOTIFICATIONS/CLIENT",payload})
const deactivateClientReminderAC = (id: string):deactivateClientReminderAT => ({type: "NOTIFICATION/DEACTIVATE/CLIENT-REMINDER", id})
const getUserInvitationAC = (payload: userInviteNotificationViewType[]):getUserInvitationAT => ({type: "NOTIFICATIONS/USER-INVITE",payload})
export const deleteNewUserInvitationAC = (id: string):deleteNewUserInvitationAT => {
    return {type: "NOTIFICATIONS/USER-INVITE-DELETE-NOTIF",id}
}
export const addNewUserInvitationAC = (payload: userInviteNotificationViewType):addNewUserInvitationAT => {
    return {type: "NOTIFICATIONS/USER-INVITE-ADD-NOTIF",payload}
}

export const addUserTeammateNotifInfoAC = (payload:userTeammateNotifViewType ):addUserTeammateNotifInfoAT => {
    return {type: "NOTIF/USER-TEAMATE-INFO-ADD",payload}
}
const deleteUserTeammateNotifInfoAC = (id: string ):deleteUserTeammateNotifInfoAT => {
    return {type: "NOTIF/USER-TEAMATE-INFO-DELETE",id}
}

export const getClientRemindersTC = ():thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiUserPro.getClReminderForNotif()
        dispatch(getClientRemindersAC(res.data))
    } catch (error) {
      
    } finally {
      dispatch(setIsLoadingAC(false))
    }
  }
  
  export const deactivateClientReminderTC = (id: string):thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
      const res = await apiUserPro.deactivateClientReminder(id)
      dispatch(deactivateClientReminderAC(id))
    } catch (error) {
      
    } finally {
      dispatch(setIsLoadingAC(false))
    }
  }

  export const getUserInvitationTC = ():thunkActionRootType => async dispatch => {
    try {
        const res = await apiNotifications.getUserInvitation()
        dispatch(getUserInvitationAC(res.data))
    } catch (error) {
        
    }
  }

  export const deleteTeammateInfoNotifTC = (id: string):thunkActionRootType => async dispatch => {
    try {
        const res = await apiNotifications.deleteTeammateInfo(id)
        dispatch(deleteUserTeammateNotifInfoAC(id))
    } catch (error) {
        
    }
  }
    
  