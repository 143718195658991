import React, { useEffect, useState } from "react";
import { getNotesTC, notesStateType, Status } from "../../store/NotesReducer";
import { useDispatch, useSelector } from "react-redux";
import { AppRootState, thunkDispatchRootType } from "../../store/storeRedux";
import { stateUserType, tariffType } from "../../store/UserReducer";
import { clientsStateType } from "../../store/ClientsReducer";
import { searchStateType } from "../../store/SearchReducer";
import {
  calendarStateType,
  getWorkDaysTC,
  getWorkTimesForWeek,
  setNewEntryPeriodDataAC,
  setRenderTimesForDateAC,
  setWeekPeriodAC,
  sliceWeekPeriodAC,
} from "../../store/CalendarReducer";
import {
  getFreeTimesForWeekTC,
  sessionsType,
  setDateAndTimeForNoteAC,
} from "../../store/SessionsReducer";
import {
  ModalWindowStateType,
  queueRescheduleWindowAC,
  selectClientAC,
  setDateAndTimeAC,
  setNewEntryFromQueueStateAC,
  showAdditionalWindowOptionsAC,
  showCalendarWorkDaysAC,
  showEditSessionWindowAC,
  showNewEntryAC,
  showRmNoteWindowAC,
} from "../../store/ModalWindowsReducer";
import { TableRow } from "../../styles/components/TableRow";
import { AdditionaWindow } from "../additionalOptions/AdditionalWindow";
import { DropDownContainer } from "../../styles/components/DropDownContainer";
import { DropDownHeader } from "../../styles/components/DropDownHeader";
import { DropDownListContainer } from "../../styles/components/DropDownListContainer";
import { ListItem } from "../../styles/components/ListItem";
import { NextPrevButton } from "../../styles/components/NextButton";
import { TimeTableForDay } from "./pro-plus/TimeTableForDay";
import { TeamMateHeader } from "../TeamMateHeader";
import { Note } from "../Note";
import { Column } from "../../styles/components/Column";
import { TimeTableCellContainer } from "../../styles/components/TimeTableCellContainer";
import { ButtonNote } from "../../styles/components/ButtonNote";
import { Icon } from "../../styles/components/Icon";
import { Table } from "../../styles/components/Table";
import { Box } from "../../styles/components/Box";
import { TableHeader } from "../../styles/components/TableHeader";
import { Text } from "../../styles/components/Text";
import { FlexBox } from "../../styles/components/FlexBox";
import { SuperButton } from "../../styles/components/SuperButton";
import {
  timeTableHelper,
  weekPeriodType,
} from "../../utils/timeTable/timeTable-helper";
import { addDays, subDays } from "date-fns";
import { calendarHelper } from "../../utils/calendar/calendar-helper";
import { theme } from "../../styles/components/Theme.styled";
import { CustomSelect } from "../../styles/components/CustomSelect";
import { NoteMobile } from "../NoteMobile";
import { CalendarTraffic } from "../CalendarTraffic";
import { Link, NavLink } from "react-router-dom";
import { getCallListForPeriodTC } from "../../store/CallListReducer";
import { getQueueForMonthTC, removeQueueTC } from "../../store/QueueReducer";
import c from "./timeTables.module.css";
import { CallList } from "../CallList";
import { Queue } from "../Queue";
import { selectedMasterType, selectMasterAC } from "../../store/CommonReducers";

export type noteType = {
  id: string;
  date: string;
  time: string;
};

const selectSessions = (state: AppRootState) => state.sessions;
const selectCallList = (state: AppRootState) => state.callList;
const selectQueue = (state: AppRootState) => state.queueState;

const TimeTables = () => {
  const [dateForWeek, setDateForWeek] = useState<Date>(new Date());
  const [activeDate, setActiveDate] = useState<Date>(new Date());
  const dispatch: thunkDispatchRootType = useDispatch();

  const optionItems: { title: string; value: "week" | "day" }[] = [
    { title: "Неделя", value: "week" },
    { title: "День", value: "day" },
  ];
  const [optionItem, setOptionItem] = useState<string>("week");
  const [widthCount, setWidthCount] = useState<number>(0);
  const [options, setOptions] = useState(false);
  const calendarState = useSelector<AppRootState, calendarStateType>(
    (state) => state.calendarState
  );

  const user = useSelector<AppRootState, stateUserType>((state) => state.user);
  const sessions = useSelector(selectSessions);
  const queues = useSelector(selectQueue);
  const notes = useSelector<AppRootState, notesStateType[]>(
    (state) => state.notes
  );
  const client = useSelector<AppRootState, clientsStateType[]>(
    (state) => state.client
  );
  const modalState = useSelector<AppRootState, ModalWindowStateType | null>(
    (state) => state.ModalWindowReducer
  );
  const callList = useSelector(selectCallList);

  const validateTarrif = () =>
    user?.user?.tariff === tariffType.PRO ||
    (user?.user?.tariff as tariffType) === tariffType.SuperPRO;
  const disabledDay = (value: "week" | "day"): boolean => {
    const isNotProPlus =
      user.user?.tariff === tariffType.FREE ||
      user.user?.tariff === tariffType.PRO;
    return isNotProPlus && value === "day";
  };

  const prevDay = () => setActiveDate((prev) => subDays(prev, 1));
  const nextDay = () => setActiveDate((prev) => addDays(prev, 1));
  const prevWeek = () => setDateForWeek((prev) => subDays(prev, 7));
  const nextWeek = () => setDateForWeek((prev) => addDays(prev, 7));
  const showNoteWindow = (
    date?: string,
    time?: string,
    duration?: number,
    master?: null | selectedMasterType
  ) => {
    if (optionItem === "day") {
      const date = calendarHelper
        .localeStringToDate(activeDate.toLocaleDateString())
        .toISOString();
      dispatch(setNewEntryPeriodDataAC({ date: date, start: null, end: null }));
    }
    if (optionItem === "week") {
      const startPeriod = calendarState.weekPeriod[0].date
        ? new Date(
            calendarState.weekPeriod[0].date
              .toLocaleDateString()
              .split(".")
              .reverse()
              .join("-")
          ).toISOString()
        : "";
      const endPeriod = calendarState.weekPeriod[
        calendarState.weekPeriod.length - 1
      ].date
        ? new Date(
            calendarState.weekPeriod[calendarState.weekPeriod.length - 1].date
              .toLocaleDateString()
              .split(".")
              .reverse()
              .join("-")
          ).toISOString()
        : "";
      dispatch(
        setNewEntryPeriodDataAC({
          date: null,
          start: startPeriod,
          end: endPeriod,
        })
      );
    }
    if (master) dispatch(selectMasterAC(master));
    if (date && time && duration)
      dispatch(setDateAndTimeForNoteAC(date, time, duration.toString()));
    dispatch(showNewEntryAC({ value: true, fromQueue: false }));
  };

  const showAdditionalWindow = () => {
    dispatch(
      showAdditionalWindowOptionsAC(!modalState?.additionalWindowOptions)
    );
  };

  const mobileDateHandler = (date: Date) => {
    setDateForWeek(date);
  };

  useEffect(() => {
    if (optionItem === "day") {
    }
  }, [optionItem]);

  useEffect(() => {
    if (modalState?.queueMonth) {
      const date = new Date();
      dispatch(getQueueForMonthTC(date.getFullYear(), date.getMonth() + 1));
    }
  }, [modalState?.callList, modalState?.queueMonth]);

  useEffect(() => {
    dispatch(setWeekPeriodAC(timeTableHelper.weekParser(dateForWeek)));
    dispatch(
      getWorkDaysTC(
        user.user?.id as string,
        calendarHelper.getPeriodString(dateForWeek)
      )
    );
  }, [dateForWeek]);
  useEffect(() => {
    if (
      calendarState.weekPeriod.length &&
      !modalState?.queueMonth &&
      !modalState?.callList
    ) {
      const startPeriod = calendarState.weekPeriod[0].date
        ? new Date(
            calendarState.weekPeriod[0].date
              .toLocaleDateString()
              .split(".")
              .reverse()
              .join("-")
          ).toISOString()
        : "";
      const endPeriod = calendarState.weekPeriod[
        calendarState.weekPeriod.length - 1
      ].date
        ? new Date(
            calendarState.weekPeriod[calendarState.weekPeriod.length - 1].date
              .toLocaleDateString()
              .split(".")
              .reverse()
              .join("-")
          ).toISOString()
        : "";
      if (startPeriod && endPeriod) {
        dispatch(getNotesTC(user.user?.id as string, startPeriod, endPeriod));
        if (user.user?.tariff !== tariffType.FREE) {
          dispatch(
            getCallListForPeriodTC(
              user.user?.id as string,
              startPeriod,
              endPeriod
            )
          );
        }
      }
    }
  }, [
    calendarState.weekPeriod.length,
    JSON.stringify(calendarState.weekPeriod),
    queues.length,
    notes.length
  ]);
  useEffect(() => {
    if (calendarState.weekPeriod.length) {
      const startPeriod = calendarState.weekPeriod[0].date
        ? calendarState.weekPeriod[0].date
        : "";
      const endPeriod = calendarState.weekPeriod[
        calendarState.weekPeriod.length - 1
      ].date
        ? calendarState.weekPeriod[calendarState.weekPeriod.length - 1].date
        : "";
      if (startPeriod && endPeriod) {
        const start = calendarHelper
          .localeStringToDate(startPeriod.toLocaleDateString())
          .toISOString();
        const end = calendarHelper
          .localeStringToDate(endPeriod.toLocaleDateString())
          .toISOString();
        const id = user.user?.id as string;
        dispatch(getWorkTimesForWeek(id, start, end));
        dispatch(getFreeTimesForWeekTC(id, start, end));
      }
    }
  }, [
    calendarState.weekPeriod.length,
    JSON.stringify(calendarState.weekPeriod),
    notes.length,
    JSON.stringify(notes),
  ]);
  useEffect(() => {
    if (calendarState.workTimesForWeek.length) {
      dispatch(
        setRenderTimesForDateAC(
          calendarState.workTimesForWeek,
          dateForWeek.toISOString()
        )
      );
    }
  }, [calendarState.workTimesForWeek.length, dateForWeek]);

  useEffect(() => {
    if (
      (modalState?.callList && !modalState?.queueMonth) ||
      (!modalState?.callList && modalState?.queueMonth)
    ) {
      dispatch(sliceWeekPeriodAC(6));
      setWidthCount(12.5);
    } else if (modalState?.callList && modalState?.queueMonth) {
      dispatch(sliceWeekPeriodAC(5));
      setWidthCount(25);
    } else if (
      !modalState?.callList &&
      !modalState?.queueMonth &&
      calendarState.weekPeriod.length
    ) {
      dispatch(setWeekPeriodAC(timeTableHelper.weekParser(dateForWeek)));
      setWidthCount(0);
    }
  }, [modalState?.callList, modalState?.queueMonth]);

  return (
    <Box overFlowY="hidden" direction="row" position="relative" width="100%">
      <FlexBox direction="column" width="100%" height="100%">
        <FlexBox className="timeTableHead" direction="row" padding="10px 0">
          <Text font="Inter" color={theme.colors.fonts.main} size={20}>
            Расписание
          </Text>
        </FlexBox>
        {
          <FlexBox
            className="timeTableHeader"
            direction="row"
            background={theme.colors.colorForm}
            border="1px solid rgba(0, 0, 0, 0.1)"
            borderRadius="8px"
            align="center"
            justify="space-between"
            height="64px"
            padding="10px 10px 10px 20px"
          >
            <FlexBox
              className="mobileTimeTablePeriod"
              width="118px"
              direction="column"
              justify="start"
              align="start"
            >
              <Text color="#444" font="mont" size={13}>
                Неделя
              </Text>
              <Text color="#444" font="mont" size={11}>
                {calendarState.weekPeriod[0] &&
                  calendarState.weekPeriod[0].date.toLocaleString("ru", {
                    day: "2-digit",
                    month: "2-digit",
                  })}{" "}
                -{" "}
                {calendarState.weekPeriod.at(-1) &&
                  calendarState.weekPeriod
                    .at(-1)
                    ?.date.toLocaleString("ru", {
                      day: "2-digit",
                      month: "2-digit",
                    })}
              </Text>
            </FlexBox>
            <Text className="timeTableTitle" color="#444" size={16} font="mont">
              Календарь
            </Text>
            <FlexBox
              className="timeTableHeaderNav"
              direction="row"
              width="100%"
              height="100%"
              align="center"
              justify="end"
              gap={20}
            >
              <div>
                <SuperButton
                  onClick={() => dispatch(showCalendarWorkDaysAC(true))}
                  varinat="calendar"
                >
                  <Icon svgID="calendar2" width={16} height={16} />
                </SuperButton>
                {modalState?.calendarWorkDays && (
                  <CalendarTraffic
                    setActiveDate={setActiveDate}
                    setDateForWeek={setDateForWeek}
                  />
                )}
              </div>
              <CustomSelect height={36} />
              <Link
                className="mobileTimeTableSeetingLink"
                to="/timeTable/settings"
              >
                <Icon svgID="setting" width={22} height={22} />
              </Link>
              <DropDownContainer>
                <DropDownHeader onClick={() => setOptions(!options)}>
                  <span>
                    {
                      optionItems.find((el) => el.value === optionItem)
                        ?.title as string
                    }
                  </span>
                  <Icon svgID="row" width={16} height={3} />
                </DropDownHeader>
                {options && (
                  <DropDownListContainer>
                    {optionItems.map((el) => (
                      <ListItem
                        key={new Date().getTime().toString + el.value}
                        disabled={disabledDay(el.value)}
                      >
                        <button
                          key={new Date().getTime().toString() + el.value}
                          disabled={disabledDay(el.value)}
                          onClick={() => {
                            setOptionItem(() => el.value);
                            setOptions(false);
                          }}
                        >
                          {" "}
                          {el.title}
                        </button>
                        <div className="tooltip">Для тарифов PRO+</div>
                      </ListItem>
                    ))}
                  </DropDownListContainer>
                )}
              </DropDownContainer>
              <FlexBox
                className="selectPeriodContainer"
                height="36px"
                direction="row"
                background="#444444"
                align="center"
                justify="center"
                width="260px"
                borderRadius="8px"
                gap={7}
              >
                <FlexBox
                  direction="column"
                  width="162px"
                  align="center"
                  justify="start"
                >
                  {optionItem === "day" ? (
                    <FlexBox direction="column" justify="start" align="start">
                      <Text color="#fff" font="mont" size={14}>
                        День
                      </Text>
                      <Text color="#fff" font="mont" size={12}>
                        {activeDate.toLocaleDateString()}
                      </Text>
                    </FlexBox>
                  ) : (
                    <FlexBox direction="column" justify="start" align="start">
                      <Text color="#fff" font="mont" size={14}>
                        Неделя
                      </Text>
                      <Text color="#fff" font="mont" size={12}>
                        {calendarState.weekPeriod[0] &&
                          calendarState.weekPeriod[0].localeDate}{" "}
                        -{" "}
                        {calendarState.weekPeriod.at(-1) &&
                          calendarState.weekPeriod.at(-1)?.localeDate}
                      </Text>
                    </FlexBox>
                  )}
                </FlexBox>
                <FlexBox direction="row" gap={7}>
                  <NextPrevButton
                    onClick={() =>
                      optionItem === "day" ? prevDay() : prevWeek()
                    }
                    type="prev"
                  />
                  <NextPrevButton
                    onClick={() =>
                      optionItem === "day" ? nextDay() : nextWeek()
                    }
                    type="next"
                  />
                </FlexBox>
              </FlexBox>
              <FlexBox
                className="newEntryBtnContainer"
                direction="row"
                width="200px"
              >
                <SuperButton
                  component="timeTable"
                  height="36px"
                  onClick={() => showNoteWindow()}
                  varinat="add"
                >
                  <Icon width={20} height={13} svgID="blMan" />
                  Новая запись
                </SuperButton>
              </FlexBox>
              <SuperButton
              disabled = {user.user?.tariff === tariffType.FREE}
                isAllowed={user.user?.tariff !== tariffType.FREE}
                varinat="moreOptions"
                onClick={showAdditionalWindow}
              >
                <Icon svgID="3points" width={36} height={36} />
                <div className="tooltip">Для тарифов PRO и PRO+</div>
              </SuperButton>
            </FlexBox>
          </FlexBox>
        }
        {optionItem === "day" ? (
          <TeamMateHeader day={activeDate} userID={user.user?.id as string} />
        ) : (
          <TableHeader type="dynamic" offset="medium">
            {modalState && modalState.additionalWindowOptions && (
              <AdditionaWindow />
            )}
            <Column
              className="weekTitle"
              maxWidth="100%"
              axis="column"
              component="TimeTable"
            >
              <Text size={13} color="#fff" font="mont">
                Неделя
              </Text>
            </Column>
            {calendarState.weekPeriod.map((el, i) => (
              <Column
                className="btnDateContainer"
                maxWidth="100%"
                axis="column"
                component="TimeTable"
              >
                <Text
                  className="timeTableHeaderContent"
                  size={13}
                  color="#fff"
                  font="mont"
                >
                  {el.dayName}{" "}
                </Text>
                <Text
                  className="timeTableHeaderContent"
                  size={13}
                  color="#fff"
                  font="mont"
                >
                  {calendarState.weekPeriod[i]?.localeDate}
                </Text>
                <SuperButton
                  onClick={() =>
                    mobileDateHandler(calendarState.weekPeriod[i].date)
                  }
                  active={
                    calendarState.weekPeriod[i].date.toLocaleDateString() ===
                    dateForWeek.toLocaleDateString()
                  }
                  varinat="timeTableMobileDate"
                >
                  <Text font="mont" color="#444" size={10}>
                    {calendarState.weekPeriod[i]?.date.toLocaleString("ru", {
                      weekday: "short",
                    })}
                  </Text>
                  <Text font="mont" color="#444" size={10}>
                    {calendarState.weekPeriod[i]?.date.toLocaleString("ru", {
                      day: "2-digit",
                      month: "2-digit",
                    })}
                  </Text>
                </SuperButton>
              </Column>
            ))}
            {modalState?.callList && (
              <Column
                className="weekTitle"
                maxWidth="100%"
                axis="column"
                component="TimeTable"
              >
                <Text size={13} color="#fff" font="mont">
                  Список обзвона
                </Text>
              </Column>
            )}

            {modalState?.queueMonth && (
              <Column
                className="weekTitle"
                maxWidth="100%"
                axis="column"
                component="TimeTable"
              >
                <Text size={13} color="#fff" font="mont">
                  Очередь на месяц
                </Text>
              </Column>
            )}
          </TableHeader>
        )}

        {
          optionItem === "week" ? (
            <Box direction="row" position="relative" overFlowY="auto">
              <Table
                style={{ width: `${100 - widthCount}%` }}
                component="TimeTable"
              >
                {!calendarState.workTimesForWeek.length && (
                  <FlexBox
                    align="center"
                    justify="center"
                    height="100%"
                    width="100%"
                    direction="column"
                  >
                    <Text color={theme.colors.fonts.main} size={16} font="mont">
                      У вас нет ниодного активного дня, для заполнения рабочего
                      времени
                    </Text>
                    <p>
                      <NavLink to={"settings"}>перейдите по ссылке</NavLink>
                    </p>
                  </FlexBox>
                )}
                {calendarState.renderTimes.map((t, i) => (
                  <TableRow component="TimeTable" key={i} colors="other">
                    <Column maxWidth="100%" component="TimeTable">
                      {t}
                    </Column>

                    {calendarState.weekPeriod.map((d, dayIdx) =>
                      calendarState.workTimesForWeek.find(
                        (val) =>
                          new Date(val.date).toLocaleDateString() ===
                            d.date.toLocaleDateString() &&
                          val.workTimes.find(
                            (time) => parseInt(time.start) === parseInt(t)
                          )
                      ) ? (
                        <TimeTableCellContainer width="100%">
                          {notes.map((el) =>
                            parseInt(el.time) === parseInt(t) &&
                            new Date(el.date).toLocaleDateString() ===
                              d.date.toLocaleDateString() ? (
                              <Note
                                isLast={
                                  dayIdx + 1 === calendarState.weekPeriod.length
                                }
                                el={el}
                              />
                            ) : (
                              <></>
                            )
                          )}
                          {sessions.freeTimeForWeek.map(
                            (el, i) =>
                              parseInt(el.start) === parseInt(t) &&
                              new Date(el.date).toLocaleDateString() ===
                                d.date.toLocaleDateString() && (
                                <ButtonNote
                                  onClick={() =>
                                    showNoteWindow(
                                      el.date,
                                      el.start,
                                      el.endNumber - el.startNumber
                                    )
                                  }
                                  duration={el.endNumber - el.startNumber}
                                  time={el.start}
                                  key={el.startNumber + i}
                                >
                                  <Icon width={18} height={16} svgID="people" />
                                </ButtonNote>
                              )
                          )}
                        </TimeTableCellContainer>
                      ) : (
                        <TimeTableCellContainer
                          free
                          width="100%"
                        ></TimeTableCellContainer>
                      )
                    )}
                  </TableRow>
                ))}
              </Table>

              <Table component="MobileTimeTable">
                {calendarState.renderTimesForDate.map((t, i) => (
                  <TableRow
                    component="MobileTimeTable"
                    colors="other"
                    key={t + i}
                  >
                    <FlexBox
                      direction="row"
                      height="100%"
                      width="59px"
                      align="center"
                    >
                      {" "}
                      {t}
                    </FlexBox>
                    <TimeTableCellContainer width="100%">
                      {notes.map((el) =>
                        parseInt(el.time) === parseInt(t) &&
                        new Date(el.date).toLocaleDateString() ===
                          dateForWeek.toLocaleDateString() ? (
                          <NoteMobile el={el} />
                        ) : (
                          <></>
                        )
                      )}
                      {sessions.freeTimeForWeek.map(
                        (el, i) =>
                          parseInt(el.start) === parseInt(t) &&
                          new Date(el.date).toLocaleDateString() ===
                            dateForWeek.toLocaleDateString() && (
                            <ButtonNote
                              onClick={() =>
                                showNoteWindow(
                                  el.date,
                                  el.start,
                                  el.endNumber - el.startNumber
                                )
                              }
                              duration={el.endNumber - el.startNumber}
                              time={el.start}
                              key={el.startNumber + i}
                            >
                              <Icon width={18} height={16} svgID="people" />
                            </ButtonNote>
                          )
                      )}
                    </TimeTableCellContainer>
                  </TableRow>
                ))}
              </Table>
              {modalState && modalState?.additionalOptionsArray.length > 0 && (
                <Box
                  direction="row"
                  right="0px"
                  position="absolute"
                  height="100%"
                  width={`${widthCount}%`}
                >
                  {modalState?.callList && <CallList items={callList} />}
                  {modalState?.queueMonth && <Queue queues={queues} />}
                </Box>
              )}
            </Box>
          ) : (
            <></>
          )
          // <TimeTableForDay  showNoteWindow={showNoteWindow} sessions={sessions}   user={user} day={activeDate} />
        }
      </FlexBox>
    </Box>
  );
};

export default TimeTables;
