import { css, styled } from "styled-components"
import sprite from "../../images/sprite.svg"
import { useEffect, useState } from "react"
import { AppRootState } from "../../store/storeRedux"
import { useDispatch, useSelector } from "react-redux"
import { Avatar } from "./Avatar"
import { selectMasterAC } from "../../store/CommonReducers"
import { setSessionsAC } from "../../store/SessionsReducer"
import { tariffType } from "../../store/UserReducer"
import { getAllTeammatesForAdminTC } from "../../store/UserProPlusReducer"
import { teammateProSettingsType } from "../../apiTypes/root/userProPlus.type"

type selectType = {
    active: boolean,
    height?: number
}
type stateType = {
    masterID: string,
    avatarUrl: string,
    masterName:string
}
type textType = {
    list?:boolean
}
type customSelectType = {
   height?:number
}
const selectUser = (app: AppRootState) => app.user
const selectCommonState = (app:AppRootState) => app.commonReducerState
const selectUserProPlus = (state: AppRootState) => state.userProPlusState

export const CustomSelect = (props: customSelectType) => {
    const [active, setActive] = useState(false)
    const dispatch = useDispatch()
    const user = useSelector(selectUser)
    const commonState = useSelector(selectCommonState)
    const handler = (e:React.SyntheticEvent<HTMLButtonElement>) => {
        e.preventDefault()
       if(user.user && user.user.tariff === tariffType.SuperPRO) setActive(!active)
    }
    const userProPlusState = useSelector(selectUserProPlus)
    const {teammates} = userProPlusState
    
   const selectMaster = (masterID: string, avatarUrl: string, masterName: string) => {
    const man = {masterID, avatarUrl,masterName}
        dispatch(selectMasterAC(man))
        setActive(false)
   }
    useEffect(() => {
        if(user.user && user.user.tariff === tariffType.SuperPRO && !teammates.teammates.length){
            dispatch(getAllTeammatesForAdminTC())
        } 
        
    },[user.user?.tariff, teammates.teammates.length])
   
   useEffect(() => {
    if(user.user && !commonState.selectedMaster.masterID) dispatch(selectMasterAC({masterID: user.user.id, masterName: user.user.firstName, avatarUrl: user.user.avatarUrl}))
   },[])
    return (
        <SelectContainer>
            <Select height={props.height} disabled = {(commonState.enableSelect === false) || user.user?.tariff !== tariffType.SuperPRO} onClick={handler} active={active}>
                <ContentBox>
                    <Avatar size="selectAvatar" src={commonState.selectedMaster.avatarUrl}/>
                    <Text>{commonState.selectedMaster.masterName}</Text>
                </ContentBox>
                <svg>
                    <use xlinkHref={`${sprite}#row`}></use>
                </svg>
            </Select>
            {
                active && <OptionContainer>
                    {
                        teammates.teammates.map(el => 
                            el.user.userId !== commonState.selectedMaster.masterID &&
                            <OptionItem onClick={() => selectMaster(el.user.userId, el.user.avatarUrl,el.user.firstName)}>
                                <div style={{ marginLeft: "14px", display: "flex",alignItems: "center",justifyContent: "center"}}><Avatar src={el.user.avatarUrl} size="selectAvatar"/></div>
                                <Text list>{el.user.firstName}</Text>
                            </OptionItem>
                            )
                    }
                   
                </OptionContainer>

            }
        </SelectContainer>

    )
}

const Select = styled.button<selectType>`
background: #FFFFFF;
border: 1px solid rgba(0, 0, 0, 0.1);
border-radius: 8px;
width: 140px;
height: ${props => props.height ? props.height + "px" :  "50px"} ;
display: flex;
gap: 10px;
align-items: center;
padding-right: 15px;
svg {
    width: 6px;
    height: 3px;
    margin-left: auto;
    ${props => props.active && css<selectType>`
        transform: rotate(180deg);
    `}
};
&:hover {
    background: #444444;
    color: #fff
};
&:hover svg {
    fill: #fff
};
@media screen and (max-width: 768px) {
     width: 120px;
     height: 36px;
     gap: 5px;
 };

`
const SelectContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`
const ContentBox = styled.div`
    display: flex;
    margin-left: 6px;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
    @media screen and (max-width: 768px) {
     gap: 2px;
 };
    
`
const OptionContainer = styled.ul`
    position: absolute;
    z-index: 5;
    background: #fff;
    top: 53px;
    width: 140px;
    max-height: 144px;
    overflow: auto;
    border-radius: 8px;
    z-index: 16;
    &::-webkit-scrollbar{
        width:7px;
    };
    &::-webkit-scrollbar-thumb{
        background: #262626;
        opacity: 0.6;
        border: 3px solid #fff;
        border-radius: 6px;
    }
`
const OptionItem = styled.li`
    display: flex;
    height: 36px;
    align-items: center;
    border-bottom: 1px solid rgb(0,0,0,0.2);
    &:first-child{
        border-radius: 8px 8px 0px 0px;
    };
    &:last-child {
        border-radius: 0px 0px 8px 8px;
        border-bottom: none;
    };
    &:hover{
        border: 1px solid rgb(0,0,0,0.8);
        padding: 0;
        cursor: pointer;
    }
`
const Text = styled.div<textType>`
        font-weight: 600;
        font-size: 13px;
        font-family: "mont";
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        ${props => props.list && css<textType>`
            width: 55%;
        `}
`