import { useDispatch, useSelector } from 'react-redux';
import {   getNotesBySearchClientTC, searchStateType, setSearchTermAC, setSearchingClientsAC } from '../store/SearchReducer';
import { AppRootState, thunkDispatchRootType } from '../store/storeRedux';
import { clientsStateType, getClientsTC } from '../store/ClientsReducer';
import { useEffect, useState } from 'react';
import { notesStateType } from '../store/NotesReducer';
import { Avatar } from '../styles/components/Avatar';
import { Icon } from '../styles/components/Icon';
import { FormBox } from '../styles/components/FormBox';
import { FlexBox } from '../styles/components/FlexBox';
import { ListItem } from '../styles/components/ListItem';

import { Text } from '../styles/components/Text';
import { SuperButton } from '../styles/components/SuperButton';
import { Box } from '../styles/components/Box';
import { selectClientAC, setDateAndTimeAC, showNewEntryAC, showSearchWindowAC } from '../store/ModalWindowsReducer';

const selectClient = (state:AppRootState) => state.client
const selectNotes = (state:AppRootState) => state.notes
export const SearchWindow = () => {
    const dispatch:thunkDispatchRootType = useDispatch()
    const clients = useSelector(selectClient)
    const notes = useSelector(selectNotes)
    const search = useSelector<AppRootState, searchStateType>(state => state.search)
    const [client, setClient] = useState<clientsStateType | null>(null)
   
    const [visits, showVisits] = useState(false)
   
    const filterClients = (client: clientsStateType) => {
        setClient(client)
        dispatch(getNotesBySearchClientTC(client.id))
        showVisits(true)
    }   


    const showNewEntry = (cl: clientsStateType) => {
        dispatch(setDateAndTimeAC('', '', ''))
        dispatch(showNewEntryAC({value: true, fromQueue: false}))
        dispatch(selectClientAC(cl))
        dispatch(showSearchWindowAC(false))
       
    }
   
    useEffect(() => {
       
        return () => {
            dispatch(setSearchTermAC(""))
            dispatch(setSearchingClientsAC(null))
        }
    },[])
    
    useEffect(() => {
        if(search.term){
            const filter1 = (cl: clientsStateType) => `(${cl.name} ${cl.surname})`.toUpperCase().trim().includes(search.term.toUpperCase())
            const filter2 = (cl: clientsStateType) => `(${cl.surname} ${cl.name})`.toUpperCase().trim().includes(search.term.toUpperCase())
            const filter3 = (cl: clientsStateType) => cl.phone?.replaceAll("-","").replaceAll("+","").replaceAll("(","").replaceAll(" ","").replaceAll(")","").includes(search.term.replaceAll("-","").replaceAll("+","").replaceAll(")","").replaceAll(" ","").replaceAll("(",""))
            const searchClients = clients.filter(cl =>  filter1(cl) || filter2(cl) || filter3(cl))
          
            dispatch(setSearchingClientsAC(searchClients))
        } 
    },[search.term])

    useEffect(() => {
        if(!clients.length) {
            dispatch(getClientsTC())
        }
    },[clients.length])

    useEffect(() => {
        if(search.notes && client) {
            dispatch(getNotesBySearchClientTC(client.id))
        }
    },[notes.length])
    return (
    

            <FormBox component='search' width='420px' minHeight='520px'>
                {
                    client
                        ? <FlexBox as={"ul"} direction='column' width='100%' gap={5} align='center'>

                        

                            <FlexBox key={client.id} direction='row' gap={20} width='100%' height='60px' align='center' justify='center' >

                                <ListItem justify='space-between' padding='5px 10px' border='1px solid rgba(0, 0, 0, 0.20)' height={60} borderRadius={8}>
                                    
                                        <Avatar size='large' src={client.photoURL} />
                                            

                                    
                                    <FlexBox direction='column' height='100%' align='center' justify='center' width='100%'>
                                        <FlexBox gap={3} direction='row' align='center'>
                                            <Text font='mont' size={14} color='#444'>{client.surname}</Text>
                                            <Text font='mont' size={14} color='#444'>{client.name}</Text>
                                            <Text font='mont' size={14} color='#444'>{client.patronymic}</Text>
                                        </FlexBox>
                                        <FlexBox direction='row' align='center'>
                                            <Text font='mont' size={14} color='#444'>{client.phone}</Text>

                                        </FlexBox>



                                    </FlexBox>

                                </ListItem>
                                <SuperButton onClick={(e) => {
                                    search.clients?.length ? showNewEntry(search.clients && search.clients[0]) : e.preventDefault()
                                }} varinat='btn'>
                                    <Icon svgID='btn-note' width={27} height={27} />
                                </SuperButton>

                            </FlexBox>

                        
                        
                    </FlexBox>
                        : <FlexBox as={"ul"} direction='column' width='100%' gap={5} align='center'>

                            {search.clients?.map(cl =>

                                <FlexBox  key={cl.id} direction='row' gap={20} width='100%' height='60px' align='center' justify='center' >

                                    <ListItem onClick={() => filterClients(cl)} justify='space-between' padding='5px 10px' border='1px solid rgba(0, 0, 0, 0.20)' height={60} borderRadius={8}>
                                        
                                                 <Avatar size='large' src={cl.photoURL} />

                                        
                                        <FlexBox direction='column' height='100%' align='center' justify='center' width='100%'>
                                            <FlexBox gap={3} direction='row' align='center'>
                                                <Text font='mont' size={14} color='#444'>{cl.surname}</Text>
                                                <Text font='mont' size={14} color='#444'>{cl.name}</Text>
                                                <Text font='mont' size={14} color='#444'>{cl.patronymic}</Text>
                                            </FlexBox>
                                            <FlexBox direction='row' align='center'>
                                                <Text font='mont' size={14} color='#444'>{cl.phone}</Text>

                                            </FlexBox>



                                        </FlexBox>

                                    </ListItem>
                                    <SuperButton onClick={(e) => {
                                        search.clients?.length ? showNewEntry(search.clients && search.clients[0]) : e.preventDefault()
                                    }} varinat='btn'>
                                        <Icon svgID='btn-note' width={27} height={27} />
                                    </SuperButton>

                                </FlexBox>

                            )
                            }
                        </FlexBox>
                }

                {
                    visits && <FlexBox width='100%' padding='0 55px 0 0' marginTop='10px' direction='column' >
                        <Text size={14} color='#444' font='mont'>Записаны на ближайшее время</Text>

                        <FlexBox as={"ul"} direction='column' height='100%' width='100%' align='center' justify='start' gap={10}>
                            {

                               search.notes && search.notes?.future.map(note =>
                                    <FlexBox as={"li"} key={note.id} direction='row' align='center' padding='10px' gap={10} width='100%' height='60px' background='#fff' borderRadius='8px'>
                                        {
                                            search.clients && search.clients[0]
                                                &&
                                                     <Avatar size='large' src={search.clients[0].photoURL} />
                                               
                                        }
                                        <FlexBox direction='row' width='80%' align='start'>
                                            <Text size={14} font='mont' color='#444'>{note.surname} запись на приём на {new Date(note.date).toLocaleDateString()} в {note.time}</Text>
                                        </FlexBox>

                                    </FlexBox>
                                )
                            }
                        </FlexBox>
                        <FlexBox width='100%' direction='column' gap={5} marginTop='10px'>
                            <Text size={14} font='mont' color='#444'>Прошедшие записи</Text>
                            <Box height='240px'  gap={5} overFlowY='auto' width='100%'>
                                {

                                 search.notes &&   search.notes.last.map(note =>
                                        <FlexBox  as={"li"} key={note.id} direction='row' align='center'  padding='15px' gap={10} width='100%' height='26px' background='#686868' borderRadius='8px'>
                                            {
                                                search.clients && search.clients[0]
                                                    &&
                                                         <Avatar size='extraSmall' src={search.clients[0].photoURL} />
                                            }
                                            <FlexBox padding='0 15px' direction='row' width='80%' align='start'>
                                                <Text size={10} font='mont' color='#fff'>{note.surname} был(а) записан(а) {new Date(note.date).toLocaleDateString()} в {note.time}</Text>
                                            </FlexBox>

                                        </FlexBox>
                                    )
                                }
                            </Box>
                        </FlexBox>


                    </FlexBox>
                }



            </FormBox>
       
    )


}