import axios, { AxiosError, isAxiosError } from "axios";
import { Dispatch } from "redux";
import { setSessionsAC } from "./SessionsReducer";
import { ThunkAction } from "redux-thunk";
import {
  AppActionsType,
  AppRootState,
  store,
  thunkActionRootType,
} from "./storeRedux";
import { setIsLoadingAC, setSuccessMessageChangeStatusAC } from "./AppReducer";
import { noteInputType } from "../apiTypes/responses/notesResponsesType";
import { apiNotes } from "../apiServer/apiNote";
import { notesHelper } from "./helper/notes-helper";
import { v1 } from "uuid";
import { editNoteWindowAC } from "./ModalWindowsReducer";
import { logoutTC } from "./AuthReducer";
import { deleteItemFromCallListAC } from "./CallListReducer";
import { changeNoteStatusForWeekAC } from "./UserProPlusReducer";
export enum Status {
  CONFIRMED = "CONFIRMED",
  NOT_CONFIRMED = "NOT_CONFIRMED",
  WAIT = "WAIT",
  IN_Office = "IN_OFFICE",
  HELD = "HELD",
  PORTING = "PORTING",
  NOT_ANSWER = "NOT_ANSWER",
  NOT_COME = "NOT_COME",
  REFUSE = "REFUSE",
}
export type notesStateType = {
  id: string;
  userId: string;
  date: string;
  time: string;
  duration: string;
  name: string;
  surname: string;
  patronymic: string;
  clientAvatar: string;
  phone: string;
  comments: string;
  status: Status;
  work?: string
};
export type notesActionType =
  | addNoteTypeAT
  | getNotesType
  | deleteNotesType
  | editNoteAT
  | changeNoteStatusAT;

type addNoteTypeAT = {
  type: "ADD-NOTE";
  payload: notesStateType;
};

type getNotesType = {
  type: "GET-NOTES";
  state: notesStateType[];
};

type deleteNotesType = {
  type: "DELETE-NOTE";
  id: string;
};
type editNoteAT = {
  type: "EDIT-NOTE";
  note: notesStateType;
};
type changeNoteStatusAT = {
  type: "CHANGE-STATUS";
  status: Status;
  id: string;
};

export const noteStatuses1: {
  id: string;
  status: Status;
  title: string;
  svgId: string;
}[] = [
  {
    id: v1(),
    status: Status.CONFIRMED,
    title: "Подтвержден",
    svgId: "calendar-check",
  },
  {
    id: v1(),
    status: Status.NOT_CONFIRMED,
    title: "Не подтвержден",
    svgId: "calendar-cross",
  },
  
   ];
export const noteStatuses2: {
  id: string;
  status: Status;
  title: string;
  svgId: string;
}[] = [
 
  { id: v1(), status: Status.WAIT, title: "Ожидает", svgId: "calendar-user" },
  {
    id: v1(),
    status: Status.IN_Office,
    title: "В кабинете",
    svgId: "user-check",
  },
  {
    id: v1(),
    status: Status.HELD,
    title: "Прием состоялся",
    svgId: "calendar-plus",
  },
  
  
];
export const noteStatuses3: {
  id: string;
  status: Status;
  title: string;
  svgId: string;
}[] = [
 
 
  {
    id: v1(),
    status: Status.PORTING,
    title: "перенос",
    svgId: "calendar-edit",
  },
  
];
export const noteStatuses4: {
  id: string;
  status: Status;
  title: string;
  svgId: string;
}[] = [
  
  
  {
    id: v1(),
    status: Status.NOT_ANSWER,
    title: "Не ответили",
    svgId: "phone-cross",
  },
  {
    id: v1(),
    status: Status.NOT_COME,
    title: "Не пришли",
    svgId: "user-cross",
  },
  
  
  { id: v1(), status: Status.REFUSE, title: "Отказ", svgId: "calendar-cross" },
];

export const NotesReducer = (
  state: notesStateType[] = [],
  action: notesActionType
) => {
  switch (action.type) {
    case "ADD-NOTE":
      return [...state, action.payload];
    case "GET-NOTES":
      return [...action.state];
    case "DELETE-NOTE":
      return state.filter((el) => el.id !== action.id);
    case "EDIT-NOTE":
      return [...state, action.note];
    case "CHANGE-STATUS":
      return state.map((n) =>
        n.id === action.id ? { ...n, status: action.status } : n
      );
    default:
      return state;
  }
};

export const addNoteAC = (payload: notesStateType): addNoteTypeAT => {
  return { type: "ADD-NOTE", payload };
};

export const getNotesAC = (state: notesStateType[]): getNotesType => {
  return { type: "GET-NOTES", state };
};
const deleteNoteAC = (id: string): deleteNotesType => {
  return { type: "DELETE-NOTE", id };
};
const changeStatusAC = (status: Status, id: string): changeNoteStatusAT => {
  return { type: "CHANGE-STATUS", status, id };
};

export const addNoteTC =
  (payload: noteInputType): thunkActionRootType =>
  async (dispatch) => {
    dispatch(setIsLoadingAC(true));
    try {
      const res = await apiNotes.create(payload);
      dispatch(addNoteAC(notesHelper.addNote(res.data)));
    } catch (error:AxiosError | any) {
      if(isAxiosError(error)) {
          if(error.response?.status === 401) {
              dispatch(logoutTC())
          }
      }
  } finally {
      dispatch(setIsLoadingAC(false));
    }
  };

export const updateNoteTC =
  (payload: noteInputType, id: string): thunkActionRootType =>
  async (dispatch) => {
    dispatch(setIsLoadingAC(true));
    try {
      const res = await apiNotes.update(payload, id);
      dispatch(editNoteAC(res.data));
      dispatch(deleteNoteAC(id));
    } catch (error:AxiosError | any) {
      if(isAxiosError(error)) {
          if(error.response?.status === 401) {
              dispatch(logoutTC())
          }
      }
  } finally {
      dispatch(editNoteWindowAC({ value: false, note: null }));
      dispatch(setIsLoadingAC(false));
    }
  };
const editNoteAC = (note: notesStateType): editNoteAT => {
  return { type: "EDIT-NOTE", note };
};

export const getNotesTC =
  (userId: string, start: string, end: string): thunkActionRootType =>
  async (dispatch) => {
    dispatch(setIsLoadingAC(true));
    try {
      const res = await apiNotes.getForWeek(userId, start, end);
      dispatch(getNotesAC(res.data));
    } catch (error:AxiosError | any) {
      if(isAxiosError(error)) {
        debugger
          if(error.response?.status === 401) {
              dispatch(logoutTC())
          }
      }
  } finally {
      dispatch(setIsLoadingAC(false));
    }
  };

export const deleteNoteTC =
  (id: string): thunkActionRootType =>
  async (dispatch) => {
    dispatch(setIsLoadingAC(true));
    try {
      await apiNotes.deleteNote(id);
      dispatch(deleteNoteAC(id));
    } catch (error:AxiosError | any) {
      if(isAxiosError(error)) {
          if(error.response?.status === 401) {
              dispatch(logoutTC())
          }
      }
  } finally {
      dispatch(setIsLoadingAC(false));
    }
  };
export const changeNoteStatusTC =
  (status: Status, note: notesStateType): thunkActionRootType =>
  async (dispatch) => {
    dispatch(setIsLoadingAC(true));
    try {
      await apiNotes.changeStatus(note.id, status);
      dispatch(changeStatusAC(status, note.id));
      dispatch(changeNoteStatusForWeekAC({id:note.id,status}))
      if(status !== Status.NOT_ANSWER) dispatch(deleteItemFromCallListAC(note.id))
       dispatch(editNoteWindowAC({value: true, note: {...note, status}}))
      dispatch(setSuccessMessageChangeStatusAC("Статус успешно изменен"))
    } catch (error:AxiosError | any) {
      if(isAxiosError(error)) {
          if(error.response?.status === 401) {
              dispatch(logoutTC())
          }
      }
  } finally {
      dispatch(setIsLoadingAC(false));
    }
  };


