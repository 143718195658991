import { AxiosError, isAxiosError } from "axios"
import { apiQueue } from "../apiServer/apiQueue"
import { queueFormType } from "../apiTypes/formTypes/queueForm.type"
import { queueType } from "../apiTypes/root/queue.type"
import { setIsLoadingAC, setQueueRescheduleMessageAC } from "./AppReducer"
import { thunkActionRootType, thunkDispatchRootType } from "./storeRedux"
import { logoutTC } from "./AuthReducer"
import { calendarHelper } from "../utils/calendar/calendar-helper"
import { apiUserSuperPro } from "../apiServer/apiUserProPlus"


export type queueAT = addQueueAT | getQueueAT | getQueueForMonthAT | removeAT | getQueueForTeamAT

type addQueueAT = {
    type: "ADD-QUEUE",
    payload: queueType
}
type getQueueAT = {
    type: "GET-QUEUE",
    payload: queueType[]
}
type getQueueForMonthAT = {
  type: "QUEUE/GET-FOR-MONTH",
  payload: queueType[]
}
type getQueueForTeamAT = {
  type: "QUEUE/GET-FOR-Team",
  payload: queueType[]
}
type removeAT = {
  type: "QUEUE/REMOVE",
  id: string
}



export const QueueReducer = ( state: Array<queueType> = [], action: queueAT):queueType[] => {
    switch(action.type){
        case "ADD-QUEUE":
            return [...state,action.payload]
        case "GET-QUEUE":
            state = action.payload
            return state
          case "QUEUE/GET-FOR-Team":
            state = action.payload
            return state
        case "QUEUE/GET-FOR-MONTH":
          return action.payload
        case "QUEUE/REMOVE":
          return state.filter(q => q.id !== action.id)
        default:
            return state
    }
}

const addQueueAC = (payload: queueType): queueAT => ({type: "ADD-QUEUE",payload})
const getQueueAC = (payload: queueType[]):queueAT => ({type: "GET-QUEUE",payload})
const getQueueForMonthAC = (payload: queueType[]):getQueueForMonthAT => ({type: "QUEUE/GET-FOR-MONTH",payload})
const getQueueForTeamAC = (payload: queueType[]):getQueueForTeamAT => ({type: "QUEUE/GET-FOR-Team",payload})
const removeQueueAC = (id: string):removeAT => ({type: "QUEUE/REMOVE",id})

export const addQueuTC = (dto: queueFormType):thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiQueue.create(dto)
        dispatch(addQueueAC(res.data))
    } catch (error: AxiosError | any) {
        if (isAxiosError(error)) {
          if (error.response?.status === 401) {
            dispatch(logoutTC());
          }
        }
      } finally {
        dispatch(setIsLoadingAC(false))
    }
}
export const getQueuTC = (month: number, year: number):thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiQueue.get(month,year)
        dispatch(getQueueAC(res.data))
    } catch (error: AxiosError | any) {
        if (isAxiosError(error)) {
          if (error.response?.status === 401) {
            dispatch(logoutTC());
          }
        }
      } finally {
        dispatch(setIsLoadingAC(false))
    }
}
export const getQueuForTeamTC = (year: number,month: number ):thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiUserSuperPro.getQueue(year, month)
        dispatch(getQueueForTeamAC(res.data))
    } catch (error: AxiosError | any) {
        if (isAxiosError(error)) {
          if (error.response?.status === 401) {
            dispatch(logoutTC());
          }
        }
      } finally {
        dispatch(setIsLoadingAC(false))
    }
}

export const getQueueForMonthTC = (year: number, month: number):thunkActionRootType => async dispatch => {
  dispatch(setIsLoadingAC(true))
  try {
    const res = await apiQueue.getForMonth(year,month)
    dispatch(getQueueForMonthAC(res.data))
  } catch (error) {
    
  } finally {
    dispatch(setIsLoadingAC(false))
  }
}

export const removeQueueTC = (id: string):thunkActionRootType => async dispatch => {
  dispatch(setIsLoadingAC(true))
  try {
    const res = await apiQueue.deleteById(id)
    dispatch(removeQueueAC(id))
  } catch (error) {
    
  } finally {
    dispatch(setIsLoadingAC(false))
  }
}
export const rescheduleQueueTC = (year: number, month: number, id: string,workServiceId: string):thunkActionRootType => async dispatch => {
  dispatch(setIsLoadingAC(true))
  try {
    const res = await apiQueue.reschedule(year,month,id,workServiceId)
    const date = new Date()
    dispatch(getQueueForMonthTC(date.getFullYear(),date.getMonth() + 1))
  } catch (error) {
    
  } finally {
    dispatch(setIsLoadingAC(false))
    dispatch(setQueueRescheduleMessageAC(`Клиент переставлен в очереди на ${calendarHelper.getMonthFromNumber(month)} ${year} года!`))
  }
}

