

import { css, styled } from "styled-components";


type propsType = {
    position?: "fixed" | "static" | "relative" | "absolute",
    gap?:number,
    width?: string,
    height?: string,
    bottom?: string | number,
    background?: string,
    borderRaduis?: string,
    component?:"newEntry" | 'search' | "removeCard",
    top?:string,
    left?:string,
    right?:string,
    zIndex?: number,
    overFlowY?: string,
    margin?:string,
    direction?: "row" | "column",
    simpleScroll?:boolean,
    boxShadow?:string,
    transform?:string,
    padding?:string,
    hoverBg?: string,
    hoverColor?: string,
    modal?:boolean
}
export const Box = styled.div<propsType>`
    display: flex;
    /* overflow-x: hidden; */
    flex-direction: ${props => props.direction ? props.direction : "column"};
    width: ${props => props.width ? props.width : "100%"};
    height: ${props => props.height ? props.height : "100%"};
    background: ${props => props.background ? props.background : 0};
    border-radius: ${props => props.borderRaduis ? props.borderRaduis : 0};
    ${props => props.position && css<propsType>`
        position: ${props => props.position};
        bottom: ${props => props.bottom ? props.bottom : 0};
    `};
    ${props => props.margin && css<propsType>`
        margin: ${props => props.margin};
    `};
    ${props => props.padding && css<propsType>`
        padding: ${props => props.padding};
    `};
    ${props => props.boxShadow && css<propsType>`
       box-shadow : ${props => props.boxShadow} ;
    `};
    ${props => props.hoverBg && css<propsType>`
        &:hover {
            background: ${props => props.hoverBg};
        }
    `};
    ${props => props.hoverColor && css<propsType>`
        &:hover {
            color: ${props => props.hoverColor};
            svg {
                fill: ${props => props.hoverColor};
            }
        }
    `}
    gap: ${props => props.gap ? props.gap + "px" : 0};
    ${props=> props.overFlowY && css<propsType>`
        overflow-y: ${props => props.overFlowY};
        padding: ${props => props.padding ? props.padding : "0 5px"} ;
       
        ${props => !props.simpleScroll && css<propsType>`
             &::-webkit-scrollbar {
                 width: 6px;
            
            };
             &::-webkit-scrollbar-thumb {
                background: rgba(0, 0, 0, 0.50);
                border-left: 4px solid #fff;
                background-clip: border-box;
                scroll-padding-left: 5px;
            }
        `}
       
    `};
    ${props => props.transform && css<propsType>`
        transform: ${props => props.transform};
    `};
    ${props => props.component === "newEntry" && css<propsType>`
        z-index: 28;
        background: #fff;
        max-width: 100%;
        overflow-y: auto;
        padding-right: 2px;
        &::-webkit-scrollbar {
            width: 4px;
        };
        &::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, 0.50);
           
        }
    `};
    ${props => props.modal && css<propsType>`
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    `}
    ${props => props.component ==="removeCard" && css<propsType>`
    @media screen and (max-width: 768px) {
            top: 30%;
            left:50%;
            transform: translate(-50%,-30%);
            width: 336px;
            padding: 25px;
      };
    `};
   top: ${props => props.top && props.top };
   bottom: ${props => props.bottom && props.bottom };
   left: ${props => props.left && props.left };
   right: ${props => props.right && props.right };
   z-index: ${props => props.zIndex && props.zIndex};
   ${props => props.component === "search" && css<propsType>`
        width: 100%;
        
        max-width: 283px;
        svg {
            position: absolute;
            z-index: 5;
            left: 15px;
            top: 50%;
            transform: translateY(-50%);
        };
        input {
            text-indent: 35px !important;
        }
   `};
   .mobileClientRow {
        display: none;
        @media screen and (max-width: 768px) {
            display: flex
      };
   };
   .editCardDesktop {
    @media screen and (max-width: 768px) {
            display: none
      };
   };
   .editCardClose {
    display: none;
    @media screen and (max-width: 768px) {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto
      };
   };
   .statusList{
    &:hover {
        border: 1px solid #262626;
        background: #fffbfb;
        padding-right: 9px;
        cursor: pointer;
    }
   }
   
`

