

import React, { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppRootState, thunkDispatchRootType } from "../../store/storeRedux";
import { clientsStateType, getClientsTC } from "../../store/ClientsReducer";
import { useForm } from "react-hook-form";
import { addNoteTC, getNotesTC } from "../../store/NotesReducer";
import { stateUserType, tariffType } from "../../store/UserReducer";
import theMan from "../../assets/pictures/theMan.png";

import {
  
  showQueueAC,
} from "../../store/ModalWindowsReducer";

import { CustomSelect } from "../../styles/components/CustomSelect";
import { SuperButton } from "../../styles/components/SuperButton";
import { Text } from "../../styles/components/Text";
import { Avatar } from "../../styles/components/Avatar";
import { Select } from "../../styles/components/Select";
import { FakeInput } from "../../styles/components/FakeInput";
import { FlexBox } from "../../styles/components/FlexBox";
import { Box } from "../../styles/components/Box";
import { theme } from "../../styles/components/Theme.styled";
import { getWorkServicesTC } from "../../store/ProsettingsReducer";
import { addMonths } from "date-fns";
import { queueFormType } from "../../apiTypes/formTypes/queueForm.type";
import { addQueuTC } from "../../store/QueueReducer";

const selectModalState = (state: AppRootState) => state.ModalWindowReducer;
const selectProSettings = (state: AppRootState) => state.proSettings;
const selectUser = (state:AppRootState) => state.user
const selectCommonState = (state:AppRootState) => state.commonReducerState

type activeMonthType = {
    year: number,
    month: number,
    label: string
}
export const QueueWindow = () => {
  const dispatch: thunkDispatchRootType = useDispatch();
  const modalState = useSelector(selectModalState);
const [monthes, setMonthes] = useState<Array<activeMonthType>>([])
const [activeMonth,setActiveMonth]= useState<activeMonthType | null>(null)
  const { client } = modalState.queue;
  const commonState = useSelector(selectCommonState)
  const proSettings = useSelector(selectProSettings);
  const user = useSelector(selectUser)
  const cancel = (e: any) => {
    e.preventDefault()
    dispatch(showQueueAC({ value: false, client: null }));
  };

  
  
  
  const { watch ,handleSubmit,  setValue, setError,formState: {errors}, clearErrors } = useForm<queueFormType>({
    defaultValues: {
        clientId: client?.id,
        userId: user.user?.tariff === tariffType.SuperPRO ? commonState.selectedMaster.masterID : user.user!.id
    }
  });
  const monthHandler = (value: activeMonthType, e:any) => {
    e.preventDefault()
    setActiveMonth(value)
    setValue("month",value.month)
    setValue("year",value.year)
  }
  const onSubmit = (data: queueFormType) => {
    if(!data.clientId) {
        setError("clientId", {message: 'Выберите клиента'})
        return
    }
    if(!data.userId){
        setError("userId", {message: "Выберите мастера"})
        return
    }
    if(!data.month){
        setError("month", {message: "Выберите месяц"})
        return
    }
    if(!data.workServiceId){
        setError("workServiceId", {message: "Выберите услугу"})
    }
   
    dispatch(addQueuTC(data))
    dispatch(showQueueAC({value: false, client: null}))
  };

  useEffect(() => {
    if (!proSettings.workService.length) {
      dispatch(getWorkServicesTC());
    }
   
  }, [proSettings.workService.length]);
  useEffect(() => {
    if(user.user?.tariff === tariffType.SuperPRO){
      setValue("userId", commonState.selectedMaster.masterID)
    }
  },[commonState.selectedMaster.masterID])

  useEffect(() => {
    const sub = watch((value, {name, type}) => {
        if(value || name || type){
            clearErrors()
            return
        }

    })
    return () => sub.unsubscribe()
  },[watch])

  useEffect(() => {
    let arr:activeMonthType[] = []
    let startPeriod = new Date()
    let endPeriod = addMonths(startPeriod,6)
    while(startPeriod < endPeriod){
        arr.push( {label: startPeriod.toLocaleString("ru", {month:"long"}),year: startPeriod.getFullYear(), month: Number(startPeriod.toLocaleString("ru", {month: "numeric"}))})
        startPeriod = addMonths(startPeriod,1)
    }
    setMonthes(arr)
    return () => {
        dispatch(showQueueAC({value: false, client: null}))
    }
  },[])
  return (
    <Box
      padding="30px 40px"
      zIndex={27}
      background={theme.colors.colorForm}
      borderRaduis="8px"
      width="423px"
      height="max-content"
      position="fixed"
      transform="translate(-50%,-50%)"
      top="50%"
      left="50%"
      gap={20}
      as={"form"}
      onSubmit={handleSubmit(onSubmit)}
    >
      
      <FlexBox direction="row" width="100%" align="center" justify="center">
        <Text font="mont" size={18} color={theme.colors.fonts.main}>
          Добавить в очередь
        </Text>
      </FlexBox>
      <FakeInput active={true}>
        <FlexBox direction="row" align="center" justify="center" width="40px">
          <Avatar
            size="newEntry"
            src={client?.photoURL}
          />
        </FlexBox>
        <FlexBox direction="column" width="100%">
          <Text
            color="#fff"
            size={14}
            font="mont"
          >{`${client?.surname} ${client?.name} ${client?.patronymic}`}</Text>
          <Text color="#fff" size={14} font="mont">
            {client?.phone}
          </Text>
        </FlexBox>
      </FakeInput>
      <Text color={theme.colors.fonts.errors} font="mont" size={12}>{errors.clientId?.message}</Text>

      <FlexBox direction="column" gap={8} width="100%">
        <Text color={theme.colors.fonts.main} size={15} font="mont">
         Специалист и услуга
        </Text>
        <FlexBox direction="row" width="100%" gap={10}>
        <CustomSelect />
        <Select
          setValue={setValue}
          name="workServiceId"
          state={proSettings.workService}
          placeholder="Выберите услугу"
        />
        </FlexBox>
        <Text color={theme.colors.fonts.errors} font="mont" size={12}>{errors.workServiceId?.message}</Text>

      </FlexBox>
      <FlexBox direction="column" gap={8} width="100%">
        <Text color={theme.colors.fonts.main} size={14} font="mont">
          Месяц
        </Text>
       <FlexBox direction="row" height="50px" width="100%" background="#fff" borderRadius="8px">
        {
            monthes.map(el => <SuperButton active = {activeMonth?.label === el.label} onClick={(e) => monthHandler(el,e)} varinat="month-queue">{el.label}</SuperButton>)
        }
       </FlexBox>
       <Text color={theme.colors.fonts.errors} font="mont" size={12}>{errors.month?.message}</Text>

      </FlexBox>
      
     
      <FlexBox
        marginTop="15px"
        direction="row"
        gap={12}
        height="50px"
        width="100%"
      >
        <SuperButton onClick={cancel} varinat="outlined">
          Отменить
        </SuperButton>
        <SuperButton type="submit" varinat="contained">
          Создать
        </SuperButton>
      </FlexBox>
    </Box>
  );
};
