import React, { ChangeEvent, useEffect, useState } from "react";
import Avatars from '../assets/Avatars.svg'
import { NavLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutAC, stateUserType } from "../store/UserReducer";
import { Link } from "react-router-dom";
import { AppRootState, thunkDispatchRootType } from "../store/storeRedux";
import { NavbarContainer } from "../styles/components/Navbar"
import { v1 } from "uuid";
import { Logo } from "../styles/components/Logo";
import { ProfileInfo } from "../styles/components/ProfileInfo";
import { FlexBox } from "../styles/components/FlexBox";
import { MenuLink } from "../styles/components/MenuLink";
import { Text } from "../styles/components/Text";
import { Icon } from "../styles/components/Icon";
import { logoutTC } from "../store/AuthReducer";
import { searchStateType, setSearchTermAC } from "../store/SearchReducer";
import { clientsStateType } from "../store/ClientsReducer";
import { notesStateType } from "../store/NotesReducer";
import { TextField } from "../styles/components/TextField";
import { Box } from "../styles/components/Box";
import { SuperButton } from "../styles/components/SuperButton";
import { MobileNav } from "./MobileNav";
import { showSearchWindowAC } from "../store/ModalWindowsReducer";



type navBarPropsType = {

    id: string | null
}
type menuItemType = {
    id: string, item: string, img: string, route: string, cb?: () => void
}
const selectSearchState = (state:AppRootState) => state.search


 function NavbarComponent(props: navBarPropsType){
    const searchState = useSelector(selectSearchState)

    const user = useSelector<AppRootState, stateUserType>(state => state.user)
    const [burger, setBurger] = useState(false)

    const dispatch:thunkDispatchRootType = useDispatch()   
     const location = useLocation()
    const logout = () => {
        // socket.emit("logout", { id: user.user?._id as string })
        // socket.disconnect()
        dispatch(logoutTC())

    }

    
    // useEffect(() => {
    //     if (user.user?._id) dispatch(checkBalanceTC(user.user?._id as string))
    // }, [user.isAuth])
    // useEffect(() => {
    //     const interval = setInterval(() => {

    //         if (user.user?._id) dispatch(checkBalanceTC(user.user._id as string))
    //         return () => clearInterval(interval)
    //     }, 86400000)
    // }, [])

    const userMenuItems: menuItemType[] = [
        { id: v1(), item: "Клиенты", img: "cl", route: '/main' },
        { id: v1(), item: "Аналитика", img: "an", route: '/analytic' },
        // { id: v1(), item: "Напоминания", img: not, route: '/notations' },
        { id: v1(), item: "Расписание", img: "cal", route: '/timeTable' },
        { id: v1(), item: "Выйти", img: "out", route: '/login', cb: logout },
    ]
    const userPro: menuItemType[] = [
        { id: v1(), item: "Клиенты", img: "cl", route: '/main' },
        { id: v1(), item: "Аналитика", img: "an", route: '/analytic' },
        // { id: v1(), item: "Напоминания", img: not, route: '/notations' },
        { id: v1(), item: "Расписание", img: "cal", route: '/timeTable' },
        { id: v1(), item: "Настройки", img: "set", route: '/proSettings' },
        { id: v1(), item: "Выйти", img: "out", route: '/login', cb: logout },
    ]
    const adminMenuItems: menuItemType[] = [
        { id: v1(), item: "Клиенты", img: "cl", route: '/sa/clients' },
        // { id: v1(), item: "Аналитика", img: "an", route: '/analytic' },
        { id: v1(), item: "Оплаты", img: "pay", route: '/payments' },
        { id: v1(), item: "Списание", img: "spis", route: '/chargeoff' },
        { id: v1(), item: "Настройки", img: "set", route: '/adminSettings' },
        { id: v1(), item: "Выйти", img: "out", route: '/login', cb: logout },
    ]
    const menuRender = () => {

        const menu = user.user?.isAdmin ? adminMenuItems : (user.user?.tariff === "PRO" || user.user?.tariff === "SuperPRO") ? userPro : userMenuItems
        return menu
    }
    const setSearchWindow = (value: string) => {
        dispatch(setSearchTermAC(value))
    }
    const isAllowed = user.isAuth && user.user && user.user.completeRegisterData.firstLevel && user.user.completeRegisterData.secondLevel
    const position = user.user && user.user.isAdmin ? "Вы, администратор" : user.user?.position as string
    useEffect(() => {
        if(searchState.term && searchState.term.length > 2) {
            dispatch(showSearchWindowAC(true))
        } else {
            dispatch(showSearchWindowAC(false))
        }
    },[searchState.term])
    return (
        <NavbarContainer isAuth={user.isAuth}>
            <Logo isAuth={isAllowed ? true : false} />
            {
                isAllowed && <FlexBox align="center" className="mobileNav" direction="row" height="100%" width="100%" >
                    <FlexBox onClick={() => setBurger(prev => !prev)} direction="row">
                        <Icon width={21} height={15} svgID="burger" />
                    </FlexBox>
                    <Box className="fieldBox" width="100%" component="search" position="relative">
                        <Icon svgID="search" width={13} height={12} />
                        <TextField className="field" type="search" autoComplete="off" width="100%" minWidth="200px" maxWidth="283px" value={searchState.term || ""} onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchWindow(e.currentTarget.value)} placeholder='Найти на сайте' kind="search" />
                    </Box>
                    <FlexBox direction="row" onClick={logout}>
                        <Icon svgID="logout" width={21} height={24} />
                    </FlexBox>
                </FlexBox>
            }
            {
                burger && isAllowed && <MobileNav setBurger = {setBurger} menuRender={menuRender}/>
            }

            {isAllowed && <ProfileInfo firstName={user.user?.firstName as string} lastName={user.user?.lastName as string} avatarUrl={user.user?.avatarUrl as string} position={position} />}
            {isAllowed &&
                <FlexBox className="desktopMenu" marginTop="56px" direction="column" gap={32}>
                    {
                        menuRender().map(el =>
                            <MenuLink onClick={el.cb && el.cb} as={NavLink} to={el.route} active={location.pathname === el.route ? true : false} >
                                <Icon width={32} height={32} svgID={el.img} />
                                <Text size={16} color="#fff" font="mont">{el.item}</Text>
                            </MenuLink>
                        )
                    }
                </FlexBox>
            }
        </NavbarContainer>

    )
}

export const Navbar = React.memo(NavbarComponent,(prevProps,nextProps) => prevProps.id === nextProps.id)


