import styled, { css } from "styled-components"
import { theme } from "./Theme.styled"
import React from "react"


type propsType = {
    label: string,
    child: React.JSX.Element,
    variant?: "square"
    width?: number,
    height?: number,
    labelSize?: number

}

export const RadioInput = (props: propsType) => {
    const render = (): React.JSX.Element => {
        return props.child
    }
    return (
        <Wrapper {...props}>
            <Label  {...props}>
                <>
                    {
                        render()
                    }
                    <span></span>
                </>
            </Label>
            <span>{props.label}</span>

        </Wrapper>
    )
}


const Wrapper = styled.div<propsType>`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    span {
        font-size: ${props => props.labelSize ? props.labelSize + "px" : "14px"};
    }
`
const Label = styled.label<propsType>`
    border: 2px solid;
    border-color: ${theme.colors.main};
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    position: relative;
   
    input {
        opacity: 0;
    };
    input:checked + span::before {
        content: "";
        position: absolute;
        width: ${props => props.variant === "square" ? props.width ? (props.width - 10) + "px" : "10px" : "13px"} ;
        height: ${props => props.variant === "square" ? props.height ? (props.height - 10) + "px" : "10px" : "13px"};
        background: ${theme.colors.main};
        border-radius: ${props => props.variant === "square" ? 0 : "50%"};
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    };
    ${props => props.variant === "square" && css<propsType>`
        width: ${props => props.width ? props.width + "px" : "24px"};
        height: ${props => props.height ? props.height + "px" : "24px"};
        border-radius: 0;
    `}

`