import { NavLink, Outlet } from "react-router-dom"
import { FlexBox } from "../../styles/components/FlexBox"
import c from "./wa-sending.module.css"


export const WaSending = () => {
    return (
        <FlexBox direction="column" gap={20} width="100%" height="100%">
            <FlexBox justify="space-around" align="center"  direction="row" height="48px" width="100%" gap={10}>
                <NavLink className={(state) =>state.isActive ? c.linkActive : c.link} to={"timeTable"}>Расписание</NavLink>
                <NavLink className={(state) =>state.isActive ? c.linkActive : c.link} to={"clients"}>Клиенты</NavLink>
                <NavLink className={(state) =>state.isActive ? c.linkActive : c.link} to={"teammates"}>Сотрудники</NavLink>
            </FlexBox>
            <FlexBox direction="row" width="100%" height="100%">
                <Outlet/>
            </FlexBox>
        </FlexBox>
    )
}