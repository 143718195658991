import { useState } from "react";
import { Icon } from "../../styles/components/Icon";
import { SuperButton } from "../../styles/components/SuperButton";
import c from "./notif.module.css";
import { AppRootState, thunkDispatchRootType } from "../../store/storeRedux";
import { useDispatch, useSelector } from "react-redux";
import { Avatar } from "../../styles/components/Avatar";
import { Text } from "../../styles/components/Text";
import { theme } from "../../styles/components/Theme.styled";
import { FlexBox } from "../../styles/components/FlexBox";
import { deactivateClientReminderTC } from "../../store/NotificationReducer";
import { v1 } from "uuid";
import { ClientNotif } from "./childComponents/ClientNotif";
import { UserNotif } from "./childComponents/UserNotif";
import React from "react";

 

enum NotifComponent  {
  CLIENT = "CLIENT",
  USER = "USER"
}
type btnNotifComponentType = {
  id: string,
  title: string,
  component: NotifComponent,
  page: React.JSX.Element,
  activeCount: number
}

const selectUserPro = (state: AppRootState) => state.userProState;
const selectNotifications = (state:AppRootState) => state.notifications
export const NotifBlock = () => {
    const dispatch:thunkDispatchRootType =  useDispatch()
    const notifications = useSelector(selectNotifications)
    const btnsNotif:btnNotifComponentType[] = [
      {id: v1(), activeCount: notifications.clientReminders.length,title: "Клиенты", component: NotifComponent.CLIENT, page: <ClientNotif clientReminders={notifications.clientReminders}/>},
      {id: v1(), activeCount: notifications.userIviteNotifications.length + notifications.userTeammateNotifInfo.length,title: "Пользователи", component: NotifComponent.USER, page: <UserNotif notifications={notifications.userIviteNotifications} teammateInfoNotif={notifications.userTeammateNotifInfo}/>},
    ]
  const userPro = useSelector(selectUserPro);
  const [notifComponent, setNotifComponent] = useState<NotifComponent>(NotifComponent.CLIENT)
 
 
const returnComponent = ():React.JSX.Element => {
    const page = btnsNotif.find(b => b.component === notifComponent)
    if(page){
      return page.page
    } else {
      return <ClientNotif clientReminders={notifications.clientReminders}/>
    }
}
  const isEmpty = (notifications.clientReminders.length === 0) && (notifications.userIviteNotifications.length === 0) && (notifications.userTeammateNotifInfo.length === 0)
  return (
    <div
      style={{
        marginTop: "70px",
        zIndex: "37",
        position: "absolute",
        width: "590px",
        height: "619px",

        // left: "0",
        top: "0",
        borderRadius: "8px",
        background: "rgb(255, 255, 255)",
        boxShadow: "1px 2px 9px #c6c6c6",
        padding: "50px 35px",
      }}
    >
      
      {
        isEmpty 
        ? <Text font="mont" color={theme.colors.fonts.main} size={28}>У вас нет непрочитанных уведомлений!</Text>
        :<>
                   <FlexBox gap={10} padding="0 5px" align="center" direction="row" width="100%" marginTop="0px" height="44px" justify="space-around" background={theme.colors.colorForm} borderRadius="8px">
                    {
                      btnsNotif.map(el => {
                        return notifComponent === el.component
                        ? <SuperButton height="32px" maxWidth="120px" varinat="contained">{el.title} {el.activeCount ? `(${el.activeCount})` : ''}</SuperButton>
                        : <SuperButton onClick={() => setNotifComponent(el.component)} height="32px" maxWidth="120px" varinat="outlined">{el.title} {el.activeCount ? `(${el.activeCount})` : ''}</SuperButton>
                      })
                    }
                    </FlexBox>
                    {
                      returnComponent()
                    }
         
        </>
      }
     
    </div>
  );
};
