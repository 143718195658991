import { userInviteNotificationViewType, userTeammateNotifViewType } from "../../../apiTypes/root/notofocation.type";
import { Avatar } from "../../../styles/components/Avatar";
import { FlexBox } from "../../../styles/components/FlexBox";
import { Icon } from "../../../styles/components/Icon";
import { Text } from "../../../styles/components/Text";
import { SuperButton } from "../../../styles/components/SuperButton";
import c from "../notif.module.css";
import { theme } from "../../../styles/components/Theme.styled";
import { useDispatch } from "react-redux";
import { thunkDispatchRootType } from "../../../store/storeRedux";
import {
  acceptTeammateTC,
  declineTeammateTC,
} from "../../../store/UserReducer";
import { deleteTeammateInfoNotifTC } from "../../../store/NotificationReducer";

type propsType = {
  notifications: Array<userInviteNotificationViewType>;
  teammateInfoNotif: userTeammateNotifViewType[]
};
export const UserNotif = (props: propsType) => {
  const dispatch: thunkDispatchRootType = useDispatch();
  return (
    <FlexBox direction="column">
      {props.notifications.map((r) => (
        <div
          className={c.notifBox}
          style={{
            width: "100%",
            height: "97px",
            borderRadius: "4px",
            background: "#f8f8f8",
            padding: "7px",
            display: "flex",
            marginTop: "10px",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "15%",
              height: "auto",
              boxSizing: "border-box",
              border: "4px solid rgb(177, 81, 129)",
              borderRadius: "93px",
              background: "url()",
            }}
          >
            <Avatar src={r.user.avatarUrl} size="adaptive" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              width: "60%",
            }}
          >
            <Text color={theme.colors.fonts.main} size={14} font="mont">
              {`${r.user.lastName} ${r.user.firstName} ${r.user.thirdName}`}
            </Text>
            <Text color={theme.colors.fonts.main} size={14} font="mont">
              Хочет пригласить вас в команду
            </Text>
            <FlexBox
              gap={10}
              maxWidth="220px"
              height="36px"
              style={{
                wordBreak: "break-all",
                marginLeft: "auto",
                marginTop: "10px",
              }}
              width="100%"
              direction="row"
            >
              <SuperButton
                onClick={() => dispatch(declineTeammateTC(r.user.userId, r.id))}
                varinat="outlined"
              >
                Отклонить
              </SuperButton>
              <SuperButton
              hover
              hoverColor="#262626"
                onClick={() => dispatch(acceptTeammateTC(r.user.userId, r.id))}
                varinat="contained"
              >
                Принять
              </SuperButton>
            </FlexBox>
          </div>
        </div>
      ))}
       {props.teammateInfoNotif.map((r) => (
        <div
          className={c.notifBox}
          style={{
            width: "100%",
            height: "97px",
            borderRadius: "4px",
            background: "#f8f8f8",
            padding: "7px",
            display: "flex",
            marginTop: "10px",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "15%",
              height: "auto",
              boxSizing: "border-box",
              border: "4px solid rgb(177, 81, 129)",
              borderRadius: "93px",
              background: "url()",
            }}
          >
            <Avatar src={r.teammate.avatarUrl} size="adaptive" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              width: "60%",
            }}
          >
            <Text color={theme.colors.fonts.main} size={14} font="mont">
              {`${r.teammate.lastName} ${r.teammate.firstName} ${r.teammate.thirdName}`}
            </Text>
            <Text color={theme.colors.fonts.main} size={14} font="mont">
              {r.comment}
            </Text>
            <FlexBox
              gap={10}
              maxWidth="220px"
              height="36px"
              style={{
                wordBreak: "break-all",
                marginLeft: "auto",
                marginTop: "10px",
              }}
              width="100%"
              direction="row"
            >
              
              <SuperButton
                onClick={() => dispatch(deleteTeammateInfoNotifTC(r.id))}
                varinat="contained"
              >
                Закрыть
              </SuperButton>
            </FlexBox>
          </div>
        </div>
      ))}
    </FlexBox>
  );
};
