import axios, { AxiosError } from "axios"
import { apiAuth } from "../apiServer/apiAuth"
import { tokenService } from "../apiServer/tokenService"
import { setIsLoadingAC, setSuccesMessagePassRecoveryAC, setSuccesRestorePassMessageAC } from "./AppReducer"
import { logoutAC, userResetAC } from "./UserReducer"
import { thunkActionRootType } from "./storeRedux"
import { setPassRecoveryAC, setPasswordCodeErrorAC, setRegisterEmailErrorAC, setRegisterLoginErrorAC, setRegisterPhoneErrorAC, setRestorePassErrorAC } from "./ErrorMessagesReducer"
import { deviceSessionResponseType } from "../apiTypes/responses/deviceSessionResponseType"
import { apiDeviceSessions } from "../apiServer/apiDeviceSessions"
import { TcompleteRegister } from "../apiTypes/formTypes/completeRegisterType"
import { Dispatch } from "redux"

type registerDataType = {
    firstLevel: boolean,
        secondLevel: boolean,
        completeUserInfo: boolean;

}
export type stateRegisterType = {
    isRegister: boolean,
    userId: string,
    deviceSessions:Array<deviceSessionResponseType>,
    registerData: registerDataType
}

export type userInfoType = {
    kind: string;
    userCRMcount: number;
    teamMatesCount: number;
    companyName?: string | undefined;
    role?: string | undefined
}
export type authAT = actionRegisterType | setUserRegister | getLastSessionsAT | setRegisterDataAT | setDataUserType
    | setUserAT | setUserInfoCompleteAT

type actionRegisterType = {
    type: 'REGISTER-TYPE',
    isRegister: boolean,
    }
type setUserRegister = {
    type: 'SET-REGISTER-USER',
    userId: string
}
type getLastSessionsAT = {
    type: "AUTH/GET-LAST-SESSIONS",
    sessions: deviceSessionResponseType[]
}
type setRegisterDataAT = {
    type: "AUTH/SET-REGISTER-DATA",
    payload: registerDataType,
    isRegister: boolean
}
type setDataUserType = {
    type: "SET-DATA-USER",
    value: boolean
}
type setUserAT  = {
    type: "AUTH/SET-USER",
    userId: string
}

type setUserInfoCompleteAT = {
    type: "AUTH/SET-USER-INFO-COMPLETE"
}




const initState:stateRegisterType = {
    isRegister: false,
    userId: '',
    deviceSessions: [],
    registerData: {
        firstLevel: false,
        secondLevel: false,
        completeUserInfo: false
    }
}

export const AuthReducer = ( state: stateRegisterType = initState, action: authAT):stateRegisterType => {
        
        switch(action.type) {
            case 'REGISTER-TYPE':
                return {...state,isRegister: action.isRegister}
            case 'SET-REGISTER-USER':
                return {...state, userId: action.userId}
            case "AUTH/GET-LAST-SESSIONS":
                return {...state, deviceSessions: action.sessions}
            case "AUTH/SET-REGISTER-DATA":
                return {...state, registerData: action.payload,isRegister: action.isRegister}
            case "AUTH/SET-USER":
                return {...state, userId: action.userId}
            case "AUTH/SET-USER-INFO-COMPLETE":
                return {...state, registerData: {...state.registerData, completeUserInfo: true}}
            default:
                return state
        }
}

export const registerAC = (isRegister: boolean):actionRegisterType => {
    return  {type: 'REGISTER-TYPE', isRegister}
}

export const setRegisterUserAC = (userId: string):setUserRegister => { 
    return { type: 'SET-REGISTER-USER', userId}
}

const getLastSessionsAC = (sessions: deviceSessionResponseType[]):getLastSessionsAT => {
    return {type :"AUTH/GET-LAST-SESSIONS", sessions} 
}
const setUserAC = (userId: string):setUserAT => {
    return {type: "AUTH/SET-USER",userId}
}
const setUserInfoCompleteAC = ():setUserInfoCompleteAT => {
    return {type: "AUTH/SET-USER-INFO-COMPLETE"}
}
export const setRegisterDataAC = (payload: registerDataType, isRegister: boolean):setRegisterDataAT => {
    return {type: "AUTH/SET-REGISTER-DATA",payload,isRegister}
}
const setDataUserAC = (value: boolean):setDataUserType => {
    return { type: 'SET-DATA-USER', value }
}
export const registerUserTC = (payload: { email: string, password: string }): thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiAuth.register(payload)
        dispatch(setUserAC(res.data.userId))
        dispatch(registerAC(true))
    } catch (error: any) {
        if (error.response.data.message === 'this user already exist') {
            dispatch(setRegisterEmailErrorAC('Пользователь с таким именем уже существует'))
        } 
    } finally {
        dispatch(setIsLoadingAC(false))
    }
}
export const completeRegisterTC = (payload: TcompleteRegister, id: string):thunkActionRootType => async dispatch =>{
    try {
        const res = await apiAuth.completeRegister(payload,id)
        dispatch(setRegisterDataAC({firstLevel: true, secondLevel: false, completeUserInfo: false},true))
    } catch (error:AxiosError | any) {
        if(Array.isArray(error.response.data.errorMessages)) {
            error.response.data.errorMessages.map( (el:any) => {
                if(el.field === "phoneNumber") {
                    dispatch(setRegisterPhoneErrorAC(el.message))
                } 
                if(el.field === "login") {
                    dispatch(setRegisterLoginErrorAC(el.message))
                } 
    
            } )
          }  
    }
}
export const setDataUserTC = (userId: string, userInfo: userInfoType) => {

    return (dispatch: Dispatch) => {
        axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/users/userInfo`, { data: userInfo,userId })
            .then((res) => {
                dispatch(setDataUserAC(true))
                dispatch(setRegisterDataAC({firstLevel: true,secondLevel: true, completeUserInfo: false},true))
            })
            .catch((e:any) => {
                dispatch(setDataUserAC(false))
            })
    }
}
export const logoutTC = ():thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        await apiAuth.logout()
        tokenService.removeToken()
        dispatch(logoutAC())
    } catch (error) {
        tokenService.removeToken()
        dispatch(userResetAC())
    } finally{
        dispatch(setIsLoadingAC(false))
    }
}
export const passwordRecoveryTC = (email: string):thunkActionRootType => async dispatch => {
    try {
        const res = await apiAuth.passwordRecovery(email)
        dispatch(setSuccesMessagePassRecoveryAC("На вашу почту вам отправлена инструкция по восстановлению пароля"))
    } catch (error:AxiosError | any) {
        if(axios.isAxiosError(error)){
            if(error.response?.status === 404){
                dispatch(setPassRecoveryAC(error.response.data.message))
            }
        }
    } finally {

    }
}

export const restorePasswordTC = (code: string, userId: string, password: string):thunkActionRootType => async dispatch => {
    try {
        const res = await apiAuth.checkRecoveryCode(code, userId, password)
        dispatch(setSuccesRestorePassMessageAC("Пароль успешно изменен"))
    } catch (error: AxiosError | any) {
            if(axios.isAxiosError(error)){
                if(error.response?.status === 400){
                    dispatch( setRestorePassErrorAC("Пароль должен быть не менее 8 символов"))
                }
                if(error.response?.status === 403){
                    dispatch(setPasswordCodeErrorAC("Срок действия ссылки истек"))
                }
            }
    } finally {

    }
}

export const getLastDeviceSessionsTC = ():thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiDeviceSessions.getLastSessions()
        dispatch(getLastSessionsAC(res.data))
    } catch (error) {
        
    } finally {
        dispatch(setIsLoadingAC(false))
    }
} 

export const setUserInfoCompleteTC = (userId: string):thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiAuth.completeUserInfo(userId)
        dispatch(setUserInfoCompleteAC())
    } catch (error) {
        
    } finally {
        dispatch(setIsLoadingAC(false))

    }
}