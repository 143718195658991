import axios, { Axios, AxiosError, responseEncoding } from "axios"
import { Dispatch } from "redux"
import { v1 } from "uuid"
import { currentMonthDataType, stateSettingsType } from "./SettingReducer"
import { setIsLoadingAC, setSuccesMessageSettingAC } from "./AppReducer"
import { thunkActionRootType } from "./storeRedux"
import { apiWorkTimes } from "../apiServer/apiWorkTimes"
import { datesType, workDayDbType, workDayUpdateType } from "../apiTypes/responses/workTimeResponseType"
import { calendatStateHelper } from "./helper/calendar-helper"
import { weekPeriodType } from "../utils/timeTable/timeTable-helper"
import { calendarHelper } from "../utils/calendar/calendar-helper"
import { showCalendarEditionDateAC, showCalendarWorkDaysAC, showModalForUpdateDayAC } from "./ModalWindowsReducer"
import { setUpdateWorkTimeErrorAC, setWorkTimeErrorAC } from "./ErrorMessagesReducer"

export type calendarStateType = {
    date: string | null,
    updatedDate: string,
    modalForChange: boolean,
    workWithStartTimeWindow: boolean,
    workWithEndTimeWindow: boolean,
    startTime: string,
    currentPeriod: string,
    endTime: string,
    workTimeState: workDayDbType,
    renderTimes: string[],
    renderTimesForDate: string[],
    weekPeriod: weekPeriodType[],
    workTimesForWeek: datesType[],
    workTimesForDay: datesType,
    newEntryPeriodData: newEntryPeriodDataType | null
    month: {
        monthName: string,
        indexMonth: number
    }
}

export type workTimeStateType = {
    _id: string,
    date: string,
    startWorkTime: string,
    endWorkTime: string,
    isEdited: boolean

}
type newEntryPeriodDataType = {
    date: string | null,
    start: string | null,
    end: string | null,
}
type selectDay = {
    type: 'SELECT-DAY',
    date: string
}
type setUpdatedDate = {
    type: "UPDATE-DATE",
    updatedDate: string
}
type showModalType = {
    type: 'SHOW-MODAL',
    modalForChange: true
}

type hideModalType = {
    type: 'HIDE-MODAL',
    modalForChange: false
}

type setTimeStartType = {
    type: 'SET-START-TIME',
    workWithStartTimeWindow: boolean
}

type setTimeEndType = {
    type: 'SET-END-TIME',
    workWithEndTimeWindow: boolean
}

type setStartWorkType = {
    type: 'SET-START-WORK',
    startTime: string
}
type setEndWorkType = {
    type: 'SET-END-WORK',
    endTime: string
}

type deleteDay = {
    type: 'DELETE-DAY',
    periodId: string,
    date: string
}
type setMonthType = {
    type: 'SET-MONTH',
    month: string,
    indexMonth: number
}

type addNewWorkTimeAC = {
    type: 'ADD-NEW-WORKTIME',
    data: workDayDbType

}

type setNewEntryPeriodDataAT = {
   type:  "CALENDAR-STATE/SET-NEW-ENTRY-PERIOD-DATA",
    payload: newEntryPeriodDataType | null
}

export type addSettingsDomainType = {
    period: string,
    date: string,
    startWorkTime: string,
    endWorkTime: string
}
type getWorkTimeType = {
    type: 'GET-WORK-TIME',
    data: workDayDbType
}

type setCurrentPeriodAT = {
    type: "CALENDAR/CURRENT-PERIOD",
    period: string
}
type setRenderTimesAT = {
    type: "CALENDAR/RENDER-TIMES",
    items: datesType[]
}
type setWeekPeriodAT = {
    type: "CALENDAR/SET-WEEK-PERIOD",
    items: weekPeriodType[]
}
type setWorkTimeForWeekAT = {
    type: "CALENDAR/SET-WORK-TIME-WEEK",
    items: datesType[]
}
type setWorkTimeForDayAT = {
    type: "CALENDAR/SET-WORK-TIME-DAY",
    items: datesType
}
type setRenderTimesForDateAT = {
    type: "CALENDAR/SET-TIME-FOR-DATE",
    items: string[]
}

type sliceWeekPeriodAT = {
    type: "CALENDAR/SLICE-WEEK-PERIOD",
    count: number
}


export type calendarActionType = selectDay | showModalType | hideModalType | setTimeStartType | setTimeEndType | setStartWorkType | setEndWorkType | deleteDay | setMonthType | addNewWorkTimeAC | getWorkTimeType
    | setUpdatedDate  | setCurrentPeriodAT | setRenderTimesAT | setWeekPeriodAT | setWorkTimeForWeekAT | setRenderTimesForDateAT | sliceWeekPeriodAT | setWorkTimeForDayAT | setNewEntryPeriodDataAT
const initState: calendarStateType = {
    date: null,
    modalForChange: false,
    workWithStartTimeWindow: false,
    workWithEndTimeWindow: false,
    startTime: '',
    endTime: '',
    updatedDate: "",
    currentPeriod: '',
    weekPeriod: [],
    workTimesForWeek: [],
    workTimesForDay: {date: "",isEdited: false, workTimes: []},
    renderTimesForDate: [],
    workTimeState: {
        id: "",
        userId: "",
        period: "",
        datesArray: []
    },
    renderTimes: [],
    month: {
        monthName: '',
        indexMonth: 0
    },
    newEntryPeriodData: null

}



export const CalendarReducer = (state: calendarStateType = initState, action: calendarActionType): calendarStateType => {
    switch (action.type) {
        case 'SELECT-DAY':
            return { ...state, date: action.date }

        case 'SHOW-MODAL':
            return { ...state, modalForChange: action.modalForChange = true }

        case 'HIDE-MODAL': {
            return { ...state, modalForChange: action.modalForChange = false }
        }
        case 'SET-START-TIME': {

            return { ...state, workWithStartTimeWindow: action.workWithStartTimeWindow }
        }
        case 'SET-END-TIME': {
            return { ...state, workWithEndTimeWindow: action.workWithEndTimeWindow }
        }
        case 'SET-START-WORK': {
            return { ...state, startTime: action.startTime }
        }
        case 'SET-END-WORK': {
            return { ...state, endTime: action.endTime }
        }
        case 'DELETE-DAY': {
            return { ...state, workTimeState: { ...state.workTimeState, datesArray: state.workTimeState.datesArray.filter(el => el.date !== action.date) } }
        }
        case 'SET-MONTH': {
            return { ...state, month: { monthName: action.month, indexMonth: action.indexMonth } }
        }
        case "UPDATE-DATE": {
            return { ...state, updatedDate: action.updatedDate }
        }
        case 'ADD-NEW-WORKTIME': {
            if(action.data.period === state.currentPeriod){
                return { ...state, workTimeState: action.data}
            } else {
                return state
            }
             
        }
        case 'GET-WORK-TIME': {
            return { ...state, workTimeState: action.data }
        }
       
        case "CALENDAR/CURRENT-PERIOD":
            return {...state,currentPeriod: action.period}
        case "CALENDAR/RENDER-TIMES":
            let newItems = calendatStateHelper.setRenderTimes(action.items, state.weekPeriod)
            return {...state, renderTimes: newItems ? newItems : []}
        case "CALENDAR/SET-WEEK-PERIOD":
            return {...state,weekPeriod: action.items}
        case "CALENDAR/SLICE-WEEK-PERIOD":
            const arr:weekPeriodType[] = state.weekPeriod.slice(0,action.count)
            return {...state, weekPeriod: arr}
        case "CALENDAR/SET-WORK-TIME-WEEK":
            return {...state, workTimesForWeek: action.items}
        case "CALENDAR/SET-TIME-FOR-DATE":
            return {...state, renderTimesForDate: action.items}
        case "CALENDAR/SET-WORK-TIME-DAY":
            return {...state, workTimesForDay: action.items}
        case "CALENDAR-STATE/SET-NEW-ENTRY-PERIOD-DATA":
            return {...state, newEntryPeriodData: action.payload}
        default:
            return state
    }

}
export const setNewEntryPeriodDataAC = (payload: newEntryPeriodDataType | null):setNewEntryPeriodDataAT => {
    return {type: "CALENDAR-STATE/SET-NEW-ENTRY-PERIOD-DATA",payload}
}
export const setCurrentPeriodAC = (period: string):setCurrentPeriodAT =>  {
    return {type: "CALENDAR/CURRENT-PERIOD", period}
}
export const selectDayAC = (date: string):selectDay => {
     return { type: 'SELECT-DAY', date }
}

export const showModalAC = () => {
    return { type: 'SHOW-MODAL' }
}

export const hideModalAC = () => {
    return { type: 'HIDE-MODAL' }
}

export const workWithStartTimeWindowAC = (beginWindow: boolean) => {
    return { type: 'SET-START-TIME', workWithStartTimeWindow: beginWindow }
}

export const workWithEndTimeWindowAC = (endWindow: boolean) => {
    return { type: 'SET-END-TIME', workWithEndTimeWindow: endWindow }
}

export const setStartWorkOfDayAC = (startTime: string) => {
    return { type: 'SET-START-WORK', startTime: startTime }
}

export const setEndWorkOfDayAC = (endTime: string) => {
    return { type: 'SET-END-WORK', endTime }
}

export const deleteDayAC = (periodId: string, date: string): deleteDay => {
    return { type: 'DELETE-DAY', periodId, date }
}
export const updateDate = (updatedDate: string): setUpdatedDate => {
    return { type: "UPDATE-DATE", updatedDate }
}

export const setMonthAC = (month: string, index: number) => {
    return { type: 'SET-MONTH', monthName: month, indexMonth: index }
}

export const setRenderTimesAC = (items: datesType[]):setRenderTimesAT => {
    return {type: "CALENDAR/RENDER-TIMES",items}
}


export const setWeekPeriodAC = (items: weekPeriodType[]):setWeekPeriodAT => {
    return {type: "CALENDAR/SET-WEEK-PERIOD",items}
}
export const sliceWeekPeriodAC = (count: number):sliceWeekPeriodAT => {
    return {type: "CALENDAR/SLICE-WEEK-PERIOD",count}
}
const addNewWorkTimeTC = (data: workDayDbType): addNewWorkTimeAC => {
    return { type: "ADD-NEW-WORKTIME", data }
}
const getWorkTimeAC = (data: workDayDbType): getWorkTimeType => {
    return { type: 'GET-WORK-TIME', data }
}

const setWorkTimeForWeekAC = (items: datesType[]):setWorkTimeForWeekAT => {
    return {type: "CALENDAR/SET-WORK-TIME-WEEK", items}
}
const getWorkTimesForDayAC = (items: datesType):setWorkTimeForDayAT => {
    return {type: "CALENDAR/SET-WORK-TIME-DAY",items}
}
export const setRenderTimesForDateAC = (items: datesType[], date: string):setRenderTimesForDateAT => {
    return {type: "CALENDAR/SET-TIME-FOR-DATE", items: calendatStateHelper.setRenderTimesForDate(items,date) }
}
export const addNewWorkTimeAC = (payload: any, timeStart: string, endTime: string, key: string, arrayMonth: currentMonthDataType[], userId: string): thunkActionRootType => async dispatch => {
    const arrayForFetch: Array<addSettingsDomainType> = []
        arrayMonth.forEach(el => {
            payload.forEach((day: any) => {
                if (el.dayOrder === parseInt(day.period) && day.isWork === true) {
                    arrayForFetch.push({ period: key, date: calendarHelper.localeStringToDate(el.dateCurrentDay).toISOString(), startWorkTime: timeStart, endWorkTime: endTime })
                }
            })

        })
    
    try {
        const res = await apiWorkTimes.createPeriod(key, arrayForFetch, userId)
        dispatch(addNewWorkTimeTC(res.data))
    } catch (error) {
        if(axios.isAxiosError(error)){
            dispatch(setWorkTimeErrorAC(error.response?.data.message))
        }
    } finally {

    }
}


export const getWorkDaysTC = (userId: string, period: string): thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiWorkTimes.getPeriod(period, userId)
        dispatch(getWorkTimeAC(res.data))
        // dispatch(setRenderTimesAC(res.data.datesArray))
    } catch (error: AxiosError | any) {
        if (axios.isAxiosError(error)) {
            if (error.response?.status === 404) {
                dispatch(getWorkTimeAC(initState.workTimeState))
                dispatch(setRenderTimesAC([]))
            }
        }

    } finally {
        dispatch(setIsLoadingAC(false))
    }
}
export const getWorkTimesForWeek = (userId: string, start: string, end: string):thunkActionRootType => async dispatch => {
    try {
        const res = await apiWorkTimes.getWorkTimesForWeek(userId, start, end)
        dispatch(setWorkTimeForWeekAC(res.data))
        dispatch(setRenderTimesAC(res.data))
    } catch (error) {
        
    } finally {

    }
}
export const getWorkTimesForDayTC = (date: string):thunkActionRootType => async dispatch => {
    try {
        const res = await apiWorkTimes.getWorkTimesForDate( date)
        dispatch(getWorkTimesForDayAC(res.data))
    } catch (error) {
        if(axios.isAxiosError(error)){
            dispatch(getWorkTimesForDayAC({date: "",isEdited: false, workTimes: []}))
        }
    } finally {

    }
}


export const deleteDayTC = (periodId: string, date: string): thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiWorkTimes.deleteDate(periodId, date)
        dispatch(deleteDayAC(res.data.periodId, res.data.date))
    } catch (error) {

    }
    finally {
        dispatch(setIsLoadingAC(false))
    }
}


export const updateDaysTC = (userId: string, data: workDayUpdateType, period: string): thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiWorkTimes.updateDate(userId, data)
        if(period === res.data.period){
            dispatch(getWorkDaysTC(userId,period))
        }
        dispatch(showModalForUpdateDayAC(false))
        dispatch(showCalendarEditionDateAC(false))
    } catch (error) {
        if(axios.isAxiosError(error)){
            dispatch(setUpdateWorkTimeErrorAC(error.response?.data.message))
        }
    } finally {
        dispatch(setIsLoadingAC(false))
    }
}


