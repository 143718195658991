

import * as React from 'react';
import { Link } from 'react-router-dom';
import { AppRootState, thunkDispatchRootType } from '../store/storeRedux';
import { useDispatch, useSelector } from 'react-redux';
import orange from "../assets/orange.png"
import { FlexBox } from '../styles/components/FlexBox';
import { SuperButton } from '../styles/components/SuperButton';
import { Text } from '../styles/components/Text';
import { setUserInfoCompleteTC } from '../store/AuthReducer';

const selectUser = (state:AppRootState) => state.user
const selectAuth = (state:AppRootState) => state.authState
export default function Greeting() {
  const user = useSelector(selectUser)
  const authState = useSelector(selectAuth)
  const dispatch:thunkDispatchRootType = useDispatch()
  const link = user.isAuth ? "/main" : "/login"
  const setUserInfo = () => {
    if(!user.user){
      const userId = authState.userId
      if(userId){
        dispatch(setUserInfoCompleteTC(userId))
      } else {
        return
      } 
    } else {
      dispatch(setUserInfoCompleteTC(user.user.id))
    }
  }
  return (
    <FlexBox style={{background: `url(${orange})`}} direction='row' width='100%' height='100vh' align='center' justify='center' >
      <FlexBox gap={10} direction='column' width='325px' height='188px' align='center' justify='space-between'>
        <FlexBox direction='column' gap={10}>
          <Text color={"#121212"} font='mont' size={28} weight={600} >Добро пожаловать!</Text>
          <Text color='#3A3A3A' font='mont' size={14} weight={600}>Активируйте свой аккаунт на почте которую вы указали.</Text>
          <Text color='#3A3A3A' font='mont' size={14} weight={600}>Спасибо, что выбрали нас, желаем Вам приятного использования сервисом Ipluse</Text>
        </FlexBox>

        {/* <Link  to={link}> */}
        <SuperButton onClick={setUserInfo}  varinat="contained" hover hoverColor='black'>Начать работу</SuperButton>
        {/* </Link> */}
      </FlexBox>

    </FlexBox>
  )
}
