import { clientsStateType } from "../../store/ClientsReducer";
import { notesStateType, Status } from "../../store/NotesReducer";
import { rootUserType } from "../../store/UserReducer";
import { freeTimeForTeamType, workTimeForTeamType } from "../responses/workTimeResponseType";
import { workTimeRenderType } from "../state/workTime-type";

export enum RightsType {
  A = "A",
  B = "B",
  C = "C",
}

export type teamMateViewType = {
  // user: {
  //   userId: string;
  //   firstName: string;
  //   lastName: string;
  //   thirdName: string;
  //   email: string;
  //   login: string;
  //   phoneNumber: string;
  //   color: string;
  //   avatarUrl: string;
  //   registerDate: string;
  // };
  // rights: {
  //   clientRights: RightsType;
  //   settingsRights: RightsType;
  //   timeTableRights: RightsType;
  // };
  // isAdmin: boolean
  teamId: string;
  teammates: Array<teammateProSettingsType>;
};

export type teammateProSettingsType = {
  id: string;
  isAccepted: boolean;
  clientRights: RightsType;
  timeTableRights: RightsType;
  settingsRights: RightsType;
  isAdmin: boolean;
  isOwner: boolean;
  user: {
    userId: string;
    firstName: string;
    lastName: string;
    thirdName: string;
    email: string;
    login: string;
    phoneNumber: string;
    color: string;
    avatarUrl: string;
    registerDate: string;
  };
};
export type userProPlusStateType = {
  teammates: teamMateViewType;
  teammateWorkTimes: workTimeForTeamType,
  teammateFreeTimes: freeTimeForTeamType,
  renderTimes: Array<string>,
  notesForTeam: Array<notesStateType>,
  activeDate: string;
};

export type updateTeammateRightsInputType = {
  rights: {
    clientRights: RightsType;
    settingsRights: RightsType;
    timeTableRights: RightsType;
  };
};








