import { styled } from "styled-components";
import { NoteMedia } from "./NoteMedia";
import { NoteInfo } from "./NoteInfo";

export const NoteContent = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;
  height: 100%;
  margin-left: 5px;
  position: relative;
  /* overflow: hidden; */

  .descript {
    display: none;
    left: 8px;
    margin-top: 5px;
  }
  &:hover {
    cursor: pointer;
    ${NoteMedia} {
      justify-content: start;
      margin-top: 10px;
    }
    ${NoteInfo} {
      justify-content: start;
      padding-top: 5px;
    }
    .noteContentStatus {
      display: none;
    }
    .descript {
      display: flex;
      gap: 2px;
    }
    .remove {
      display: block;
      right: 5px;
      top: 1px;
    }
    .name {
      font-size: 11px;
      margin-top: 4px;
      display: block;
    }
    .name2 {
      display: none;
      margin-left: 28px;

    }
    .phone {
      font-size: 11px;
      margin-top: 5px;
      display: block;
    }
    .ava {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    .sign {
      display: none;
    }
    .noteMedia {
      display: flex;
    }
  }
`;
