import styled from "styled-components";



export const ModalWrapper = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -58%);
    z-index: 26;
`