import { useRef } from "react"
import { clientsStateType } from "../store/ClientsReducer"
import { useStyles } from "./clientStyles"
import { DropDownListContainer } from "../styles/components/DropDownListContainer"
import { ListItem } from "../styles/components/ListItem"

type propsType = {
    sort: boolean,
    setSort: (sort: boolean) => void,
    clients: Array<clientsStateType>,
    setClients: (clients: clientsStateType[]) => void
}

export const SortForProPlus = (props: propsType) => {
    const sortRef = useRef(null)

    window.addEventListener('mousedown', (event) => {
        //@ts-ignore
        if (sortRef.current && !sortRef?.current?.contains(event.target)) {


            props.setSort(false)
        }
    })

    const sortAscending = () => {
        let sortClient = [...props.clients]
        sortClient.sort((a, b) => a.surname > b.surname ? 1 : -1)
        props.setClients(sortClient)
        props.setSort(false)
    }
    const sortDescending = () => {
        let sortClient = [...props.clients]
        props.setClients(sortClient.sort((a, b) => a.surname < b.surname ? 1 : -1))
        props.setSort(false)
    }

    return (
       
        <DropDownListContainer sort>
            <ListItem >Должники</ListItem>
            <ListItem >С балансом</ListItem>
            <ListItem onClick={() => { sortAscending() }}>От А - Я</ListItem>
            <ListItem onClick={() => { sortDescending() }}>От Я - А</ListItem>
        </DropDownListContainer>
    )
}