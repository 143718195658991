import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import avatarCat from '../assets/avatarCat.png'
import { clientCard } from "../modalWindows/child/styles/clientCard"
import { rootUserType, setProfileInfoTC } from "../store/UserReducer"
import { returnCapitalizeStr } from "../utils/utils"
import { Icon } from "../styles/components/Icon"
import { Box } from "../styles/components/Box"
import { theme } from "../styles/components/Theme.styled"
import { Avatar } from "../styles/components/Avatar"
import { SuperButton } from "../styles/components/SuperButton"
import { FormInputContainer } from "../styles/components/FormInputContainer"
import { TextField } from "../styles/components/TextField"
import { FlexBox } from "../styles/components/FlexBox"
import { ModalLayer } from "../styles/components/ModalLayer"
import { profileFormType } from "../apiTypes/formTypes/profileFormType"

type PropsType = {
    setUserForm: (uF: boolean) => void
    profile: rootUserType | null
    id: string | null
}

export const UserForm = (props: PropsType) => {
    const { register, handleSubmit,watch } = useForm<profileFormType>()

    const fileReader = new FileReader()
    const [imageUrl, setImageUrl] = useState<string>("")
    const [editPhoto, setEditPhoto] = useState(false)
    const dispatch = useDispatch()
    fileReader.onloadend = () => {
        if (fileReader.result && typeof fileReader.result === "string") setImageUrl(fileReader.result);
        setEditPhoto(false)
    }
    const onsubmit = (data: profileFormType) => {
        const surname = data.surname ? returnCapitalizeStr(data.surname) : props.profile?.lastName as string
        const name = data.name ? returnCapitalizeStr(data.name) : props.profile?.firstName as string
        const patronymic = data.patronymic ? returnCapitalizeStr(data.patronymic) : props.profile?.thirdName as string
        const profileImage = data.profileImage ? data.profileImage[0] : null
        dispatch(setProfileInfoTC(props.id, profileImage, surname, name, patronymic))
         props.setUserForm(false)
    }

    const getImageUrl = ():string => {
        if(imageUrl) return imageUrl
        if(props.profile?.avatarUrl) return props.profile.avatarUrl
        return avatarCat
    }
    useEffect(() => {
        const sub = watch((value, {name,type}) => {
            if(value.profileImage && name === "profileImage" && type === "change"){
                fileReader.readAsDataURL(value.profileImage[0])
            }
        })
        return () => sub.unsubscribe()
    },[watch])

    return (
        <>
            <ModalLayer onClick={() => props.setUserForm(false)} />
            <Box position="fixed" background={theme.colors.colorForm} borderRaduis="8px" height="350px" width="280px" padding="15px" zIndex={35} top="50%" left="50%" transform="translateX(-50%) translateY(-50%)" direction="column" gap={15} as={"form"} onSubmit={handleSubmit(onsubmit)}>
                {
                    editPhoto
                        ? <FlexBox direction="row" width="100%" align="center" justify="center">
                            <SuperButton as={"label"} varinat="photo">
                                <Icon svgID="camera" width={24} height={24} />
                                <input  {...register("profileImage")} type="file" />
                            </SuperButton>
                        </FlexBox>
                        : <FlexBox onClick={() => setEditPhoto(true)} direction="row" width="100%" align="center" justify="center">
                            <Avatar size="large" src={getImageUrl()} />
                        </FlexBox>
                }
                <FormInputContainer>
                    <TextField justify="start"  {...register('surname')} placeholder={props.profile?.lastName} type="text" />
                </FormInputContainer>
                <FormInputContainer>
                    <TextField  {...register('name')} placeholder={props.profile?.firstName} type="text" />
                </FormInputContainer>
                <FormInputContainer>
                    <TextField  {...register('patronymic')} placeholder={props.profile?.thirdName} type="text" />
                </FormInputContainer>
                <SuperButton height="48px" varinat="contained" type="submit">Сохранить изменения</SuperButton>
            </Box>
        </>
    )
}