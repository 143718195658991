import axios from "axios"
import { tokenService } from "./tokenService"
import { apiAuth } from "./apiAuth"

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_ENDPOINT}/api/wa`,
})

instance.interceptors.request.use(
    (config) => {
        const token = tokenService.getToken()
        if(token) config!.headers!.Authorization = token
        return config
    },
    (error) => Promise.reject(error)
)

instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { config } = error;
      if (error.response.status === 401 && !config._retry) {
        config._retry = true;
        try {
          const res = await apiAuth.refreshToken();
          tokenService.saveToken(res.data.accessToken);
          instance.defaults.headers.common.Authorization =
            tokenService.getToken();
          return instance(config);
        } catch (error) {
          tokenService.removeToken();
        }
      } else {
        // tokenService.removeToken();
      }
      return Promise.reject(error);
    }
  );

  export const apiWA = {
    sendToClient(clientId: string, message: string){
        return instance.post(`/send/client`, {clientId, message})
    }
  }