import { AxiosError, isAxiosError } from "axios";
import { apiUserPay } from "../apiServer/apiUserPay";
import { apiUserPro } from "../apiServer/apiUserPro";
import {  tarifHistoryType } from "../apiTypes/root/tarifHistory.type";
import { userPayHistoryType, userProType } from "../apiTypes/root/userPro.type";
import { setIsLoadingAC } from "./AppReducer";
import { logoutTC } from "./AuthReducer";
import { thunkActionRootType } from "./storeRedux";

export type userProAT = getTarifHistoryAT | getPayHistoryAT 

type getTarifHistoryAT = {
  type: "USER-PRO/GET-TARIF-HISTORY";
  payload: tarifHistoryType;
};
type getPayHistoryAT = {
  type: "USER-PRO/GET-PAY-HISTORY";
  payload: userPayHistoryType[];
};


const iniState: userProType = {
    tarifHistory: {
        items: []
    },
    payHistory: [],
    
    
}
export const UserProReducer = (
  state: userProType = iniState,
  action: userProAT
): userProType => {
  switch (action.type) {
    case "USER-PRO/GET-TARIF-HISTORY":
      return { ...state, tarifHistory: action.payload };
    case "USER-PRO/GET-PAY-HISTORY":
        return {...state, payHistory: action.payload}
      
    default:
      return state;
  }
};

const getTarifHistoryAC = (payload: tarifHistoryType):getTarifHistoryAT => ({type: "USER-PRO/GET-TARIF-HISTORY",payload})
const getPayHistoryAC = (payload: userPayHistoryType[]):getPayHistoryAT => ({type: "USER-PRO/GET-PAY-HISTORY",payload})


export const getTarifHistoryTC = ():thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiUserPro.getTarifHistory()
        dispatch(getTarifHistoryAC({items: res.data}))
    } catch (error: AxiosError | any) {
      if (isAxiosError(error)) {
        if (error.response?.status === 401) {
          dispatch(logoutTC());
        }
      }
    } finally {
        dispatch(setIsLoadingAC(false))
    }
}

export const getPayHistoryTC = ():thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiUserPro.getPayHistory()
        dispatch(getPayHistoryAC(res.data))
    } catch (error: AxiosError | any) {
      if (isAxiosError(error)) {
        if (error.response?.status === 401) {
          dispatch(logoutTC());
        }
      }
    } finally {
        dispatch(setIsLoadingAC(false))

    }
}




