import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { AppRootState } from '../store/storeRedux';
import { clientsStateType, getClientsTC, setClientsAC } from '../store/ClientsReducer';
import { Sort } from './Sort';
import { searchStateType } from "../store/SearchReducer";
import { getNotesTC, notesStateType } from "../store/NotesReducer";
import { stateUserType, tariffType } from "../store/UserReducer";
import { EditCard } from "./EditCard";
import { ModalWindowStateType, editCardAC, selectClientAC, setDateAndTimeAC, showNewClientWindowAC, showNewEntryAC, showRemoveClientWindowAC } from "../store/ModalWindowsReducer";
import { sessionsType } from "../store/SessionsReducer";
import { NavLink } from "react-router-dom";
import { Icon } from "../styles/components/Icon";
import { Avatar } from "../styles/components/Avatar";
import { FlexBox } from "../styles/components/FlexBox";
import { Text } from "../styles/components/Text";
import { theme } from "../styles/components/Theme.styled";
import { SuperButton } from "../styles/components/SuperButton";
import { TextField } from "../styles/components/TextField";
import { Box } from "../styles/components/Box";
import { TableHeader } from "../styles/components/TableHeader";
import { Column } from "../styles/components/Column";
import { TableRow } from "../styles/components/TableRow";
import { subDays } from "date-fns";
import { DeviceView } from "../store/AppReducer";
type dataType = {
    userId: string | null,
    
}

const selectAppState = (state: AppRootState) => state.appState
const selectModal = (state: AppRootState) => state.ModalWindowReducer

const MainPageComponent = (props: dataType) => {
    const dispatch = useDispatch()
    const appState = useSelector(selectAppState)
    const formatter = new Intl.NumberFormat("ru", {
        style: "currency",
        currency: "rur",
      });
    const [sort, setSort] = useState<boolean>(false)
    const [value, setValue] = useState<string>('')
    const [newClientCount, setNewClientCount] = useState<number>(0)
    const modalState = useSelector(selectModal)
    const client = useSelector<AppRootState, clientsStateType[]>(state => state.client)
    const search = useSelector<AppRootState, searchStateType | null>(state => state.search)
    const notes = useSelector<AppRootState, notesStateType[]>(state => state.notes)
    const user = useSelector<AppRootState, stateUserType>(state => state.user)
    const [clients, setClients] = useState<Array<clientsStateType>>(client)
    const [editedClient, setEditedClient] = useState<clientsStateType | undefined>()
    const removeClient = (clientsId: string) => {
        dispatch(showRemoveClientWindowAC({ value: true, clientID: clientsId }))
    }

    const showNewClientWindow = () => dispatch(showNewClientWindowAC({ value: true, clients, userID: props.userId as string }))


   

    const searchClient = (e: ChangeEvent<HTMLInputElement>) => {
        const val = e.currentTarget.value
        const filter1 = (cl: clientsStateType) => `(${cl.name} ${cl.surname})`.toUpperCase().trim().includes(val.toUpperCase())
        const filter2 = (cl: clientsStateType) => `(${cl.surname} ${cl.name})`.toUpperCase().trim().includes(val.toUpperCase())
        const filter3 = (cl: clientsStateType) => cl.phone?.replaceAll("-","").replaceAll("+","").replaceAll("(","").replaceAll(" ","").replaceAll(")","").includes(val.replaceAll("-","").replaceAll("+","").replaceAll(")","").replaceAll(" ","").replaceAll("(",""))
        const searchClients = client.filter(cl =>  filter1(cl) || filter2(cl) || filter3(cl))
        setClients(searchClients)
    }



    const showNewEntry = (cl: clientsStateType) => {
        dispatch(setDateAndTimeAC('', '', ''))
        dispatch(showNewEntryAC({value: true, fromQueue: false}))
        dispatch(selectClientAC(cl))
    }

    

    const editHandler = (cl: clientsStateType) => {
        dispatch(editCardAC(true))
        setEditedClient(cl)
    }

    useEffect(() => {
        dispatch(getClientsTC())
    }, [])
    useEffect(() => {
        setClients(client)
        const dateCompare = subDays(new Date(), 14)
        let counter = 0
        client.forEach(cl => {
            if (cl.addedDate && new Date(cl.addedDate) > dateCompare) {
                counter++
            }
        })
        setNewClientCount(counter)
    }, [JSON.stringify(client), client.length])
    console.log("app",appState.deviceView)
    return (
        <FlexBox direction="column" height="100%" width="100%">

            <div style={{ position: "relative" }}>
                {sort && <Sort clients={clients} setClients={setClients} sort={sort} setSort={setSort} />}

            </div>

            <FlexBox direction="column" height="100%" width="100%">
                <Text className="mainPageTitle" size={18} color={theme.colors.fonts.main} font="mont">Клиенты</Text>
                <FlexBox direction="row" background={theme.colors.colorForm} width="100%" marginTop="12px" padding="12px" justify="space-between">
                    <FlexBox className="clientStatsContainer" direction="row" height="100%" align="center" gap={8}>
                        <FlexBox className="clientStats" width="120px" height="100%" direction="row" background="#fff" borderRadius="8px" align="center" justify="center">Всего: {client.length}</FlexBox>
                        <FlexBox className="clientStats" width="120px" height="100%" direction="row" background="#fff" borderRadius="8px" align="center" justify="center">Новых: {newClientCount}</FlexBox>
                        <SuperButton className="sort" active={sort ? true : false} varinat="sort" onClick={(e) => setSort(prev => !prev)}>
                            <Icon svgID="sort" width={16} height={16} />
                        </SuperButton>
                        <Box className="clientSearch" width="max-content" component="search" position="relative">
                            <Icon svgID="search" width={13} height={12} />
                            <TextField width="283px" height="44px" kind="searchClient" onChange={searchClient} placeholder='Найти клиентов' type="text" />
                        </Box>
                    </FlexBox>
                    <SuperButton className="newClientBtn" minWidth="200px" maxWidth="160px" onClick={() => showNewClientWindow()} varinat="add">
                        <Icon width={20} height={13} svgID="blMan" />
                        <span>Новый клиент</span>
                    </SuperButton>
                </FlexBox>
                <FlexBox direction="column" width="100%" height="100%" gap={16}>
                    <TableHeader className="clientTableHeader" width="100%" type="dynamic" offset="medium">
                        <Column maxWidth="100%" axis="column" component="TimeTable">
                            <Text size={13} color="#fff" font="mont">ФИО</Text>
                        </Column>
                        <Column maxWidth="100%" axis="column" component="TimeTable">
                            <Text size={13} color="#fff" font="mont">Телефон</Text>
                        </Column>
                        <Column maxWidth="100%" axis="column" component="TimeTable">
                            <Text size={13} color="#fff" font="mont">Визитов</Text>
                        </Column>
                        <Column maxWidth="100%" axis="column" component="TimeTable">
                            <Text size={13} color="#fff" font="mont">Добавлен</Text>
                        </Column>
                        <Column className="payment" maxWidth="100%" axis="column" component="TimeTable">
                            <Text size={13} color="#fff" font="mont">Оплачено</Text>
                        </Column>
                        <Column maxWidth="100%" axis="column" component="TimeTable">
                            <Text size={13} color="#fff" font="mont">Баланс</Text>
                        </Column>
                        <Column maxWidth="100%" axis="column" component="TimeTable">
                            <Text size={13} color="#fff" font="mont">Действие</Text>
                        </Column>
                        <Column maxWidth="100%" axis="column" component="TimeTable">
                            <Text size={13} color="#fff" font="mont"></Text>
                        </Column>
                    </TableHeader>
                    <Box height="100%" width="100%" position="relative">
                        {modalState.editCard && <EditCard setClients={setClients} editedClient={editedClient} />}
                        <Box gap={12} overFlowY="auto" position="absolute" width="100%" height="100%">
                            {
                                clients.map(cl =>
                                    <>
                                    
                                        <TableRow minHeight="64px" key={cl.id} component="Clients">
                                            <FlexBox gap={5} direction="row" align="center" justify="center" basis="100%">
                                                <FlexBox direction="row" basis="50%" align="center" justify="center">
                                                    {
                                                        ((user.user && user.user.tariff === "PRO") || (user.user && user.user.tariff === "SuperPRO"))
                                                            //&& user.user.balance && user.user.balance > 0
                                                            ? <NavLink  to={`/client/${cl.id}`}>
                                                                 <Avatar size="newEntry" src={cl.photoURL} />  
                                                            </NavLink>
                                                            : <>
                                                                 <Avatar size="newEntry" src={cl.photoURL} /> 
                                                            </>
                                                    }
                                                </FlexBox>
                                                <FlexBox align="start" width="100%" direction="column">
                                                    <Text size={12} font="mont" color={theme.colors.fonts.main}>{cl.surname}</Text>
                                                    <Text size={12} font="mont" color={theme.colors.fonts.main}>{cl.name}</Text>
                                                    <Text size={12} font="mont" color={theme.colors.fonts.main}>{cl.patronymic}</Text>
                                                </FlexBox>
                                            </FlexBox>
                                            <FlexBox direction="row" align="center" justify="center" basis="100%">
                                                <Text size={12} font="mont" color={theme.colors.fonts.main}>{cl.phone}</Text>
                                            </FlexBox>
                                            <FlexBox direction="row" align="center" justify="center" basis="100%">
                                                <Text size={12} font="mont" color={theme.colors.fonts.main}>{cl.visitCount}</Text>
                                            </FlexBox>
                                            <FlexBox direction="row" align="center" justify="center" basis="100%">
                                                <Text size={12} font="mont" color={theme.colors.fonts.main}>{(new Date(cl.addedDate)).toLocaleDateString()}</Text>
                                            </FlexBox>
                                            <FlexBox className="payment" direction="row" align="center" justify="center" basis="100%">
                                                <Text size={12} font="mont" color={theme.colors.fonts.main}>{formatter.format(cl.spentMoney)}</Text>
                                            </FlexBox>
                                            <FlexBox direction="row" align="center" justify="center" basis="100%">
                                                <Text size={12} font="mont" color={theme.colors.fonts.main}>{formatter.format(cl.balance)}</Text>
                                            </FlexBox>
                                            <FlexBox direction="row" align="center" justify="center" basis="100%">
                                                <SuperButton onClick={() => showNewEntry(cl)} varinat="secondary">Записать</SuperButton>
                                            </FlexBox>
                                            <FlexBox direction="row" align="center" justify="center" basis="100%" gap={8}>
                                                <SuperButton varinat="sort" onClick={(e) => editHandler(cl)}>
                                                    <Icon svgID="edit" width={18} height={16} />
                                                </SuperButton>
                                                <SuperButton varinat="sort" onClick={(e) => removeClient(cl.id)}>
                                                    <Icon svgID="delete" width={18} height={16} />
                                                </SuperButton>
                                            </FlexBox>
                                        </TableRow>
                                        {
                                            appState.deviceView === DeviceView.MOBILE &&
                                            <FlexBox  height="64px" padding="5px" gap={10} background={theme.colors.colorForm} marginTop="5px" direction="row" className="mobileClientRow">
                                                {
                                                    ((user.user && user.user.tariff === "PRO") || (user.user && user.user.tariff === "SuperPRO"))
                                                    ? <NavLink to={`/client/${cl.id}`}>
                                                        <Avatar size="large" src={cl.photoURL} />
                                                    </NavLink>
                                                    : <Avatar size="large" src={cl.photoURL} />
                                                }
                                                
                                                <FlexBox direction="column" gap={3}>
                                                    <Text font="mont" color={theme.colors.fonts.main} size={12}>{`${cl.surname} ${cl.name} ${cl.patronymic}`}</Text>
                                                    <Text font="mont" color={theme.colors.fonts.main} size={12}>{cl.phone}</Text>
                                                    <Text font="mont" color={theme.colors.fonts.main} size={12}>Баланс: ****</Text>
                                                </FlexBox>
                                                <FlexBox style={{ marginLeft: "auto" }} direction="row" align="center" justify="center" gap={5}>
                                                    <SuperButton varinat="sort" onClick={(e) => editHandler(cl)}>
                                                        <Icon svgID="edit" width={18} height={16} />
                                                    </SuperButton>
                                                    <SuperButton varinat="sort" onClick={(e) => removeClient(cl.id)}>
                                                        <Icon svgID="delete" width={18} height={16} />
                                                    </SuperButton>
                                                </FlexBox>
                                            </FlexBox>
                                        }
                                    </>

                                )
                            }
                        </Box>
                    </Box>

                </FlexBox>
            </FlexBox>
        </FlexBox>
    )
}

export const MainPage = React.memo(MainPageComponent)