import React, { useEffect } from "react";



import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FlexBox } from "../styles/components/FlexBox";
import { theme } from "../styles/components/Theme.styled";
import { Text } from "../styles/components/Text";
import { TextField } from "../styles/components/TextField";
import { SuperButton } from "../styles/components/SuperButton";
import { useForm } from "react-hook-form";
import { registerFormType } from "../apiTypes/formTypes/registerFormType";
import { AppRootState, thunkDispatchRootType } from "../store/storeRedux";
import { setRegisterEmailErrorAC } from "../store/ErrorMessagesReducer";
import { registerUserTC } from "../store/AuthReducer";

const selectError = (state: AppRootState) => state.errorState


export const Registration = () => {
    const errorState = useSelector(selectError)
    
    const dispatch: thunkDispatchRootType = useDispatch()

    const { register, handleSubmit, watch, formState: { errors } } = useForm<registerFormType>()
    function registerUser(data: registerFormType) {
        delete data.seconPassword
       
        const payload = { ...data, registerDate: new Date() }
        dispatch(registerUserTC(payload))

    }
    useEffect(()=> {
        const sub = watch((value, {name, type}) => {
            if(name ==="email" && type === "change") {
                dispatch(setRegisterEmailErrorAC(""))
            }
        })

        return () => {
            sub.unsubscribe()
        }
        
    }, [watch])
   
    return (
        <>
            <FlexBox direction="row" align="center" justify="center" height="100%" width="100%" >
                <FlexBox as="form" onSubmit={handleSubmit(registerUser)} direction="column" width="325px">
                    <FlexBox direction="column" width="100%" align="start" gap={5}>
                        <Text font="mont" color={theme.colors.fonts.main} size={28}>Регистрация</Text>
                        <Text size={14} color={theme.colors.fonts.main} font="mont">Для создания аккаунта</Text>
                        <Text size={14} color={theme.colors.fonts.main} font="mont">введите email и пароль.</Text>
                    </FlexBox>
                    <FlexBox direction="column" gap={30} marginTop="30px" align="center" justify="center">
                        <FlexBox direction="column" align="start" width="100%">
                            <Text size={14} font="mont" color={theme.colors.fonts.main}>Email</Text>
                            <TextField {...register("email",
                                { required: true, maxLength: 40, 
                                    pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: 'Неверный email' } 
                                })}
                              type="text"  width="100%"  placeholder="" />
                            {errorState.registerEmailError && <Text color={theme.colors.fonts.errors} size={14} font="mont">{errorState.registerEmailError}</Text>}
                            {errors.email?.message && <Text color={theme.colors.fonts.errors} size={14} font="mont">{errors.email.message}</Text>}
                        </FlexBox>

                        <FlexBox direction="column" align="start" width="100%">
                            <Text size={14} font="mont" color={theme.colors.fonts.main}>Пароль</Text>
                            <TextField {...register("password", {
                                required: true, minLength: { value: 8, message: "Пароль должен быть не менее 8 символов" }
                            })} width="100%" type="password" />
                            {
                                errors.password?.message && <Text color={theme.colors.fonts.errors} size={14} font="mont">{errors.password.message}</Text>
                            }
                        </FlexBox>

                        <FlexBox direction="column" width="100%" align="start">
                            <Text size={14} font="mont" color={theme.colors.fonts.main}>Подтвердите пароль</Text>
                            <TextField {...register("seconPassword", {
                                validate:  (v) => v === watch("password") || "Пароли не совпадают"
                                   
                            })}
                                width="100%" type="password" />
                            {errors.seconPassword?.message && <Text font="mont" size={14} color={theme.colors.fonts.errors}>{errors.seconPassword.message}</Text>}
                        </FlexBox>
                        <FlexBox width="100%" direction="column">
                            <SuperButton  type="submit" height="48px" varinat="contained" hover hoverColor={theme.colors.fonts.main} >Зарегистрироваться</SuperButton>
                            <FlexBox justify="center" width="100%" direction="row" align="center" gap={3} marginTop="20px">
                                <Text font="mont" color={theme.colors.fonts.main} size={14}>Есть аккаунт?</Text>
                                <Link to={'/login'}>
                                    <Text font="mont" size={14} color={theme.colors.main}>Войдите</Text>
                                </Link>
                            </FlexBox>
                        </FlexBox>
                    </FlexBox>
                </FlexBox>
            </FlexBox>
        </>
    )
}