import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppRootState, thunkDispatchRootType } from "../store/storeRedux";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { stateUserType, tariffType } from "../store/UserReducer";
import { FlexBox } from "../styles/components/FlexBox";
import { TextField } from "../styles/components/TextField";
import { SuperButton } from "../styles/components/SuperButton";
import { Icon } from "../styles/components/Icon";
import { Text } from "../styles/components/Text";
import { returnTariff } from "../utils/utils";
import { Box } from "../styles/components/Box";
import React from "react";
import { HeaderButton } from "../styles/components/HeaderButton";
import { TariffInfoBlock } from "./TarrifInfoBlock";
import { v1 } from "uuid";
import { NotifBlock } from "./notifications/Notif";
import { setSearchTermAC } from "../store/SearchReducer";
import { showSearchWindowAC } from "../store/ModalWindowsReducer";
import { getClientRemindersTC } from "../store/NotificationReducer";

const selectAppState = (state: AppRootState) => state.appState;
const selectSearchState = (state: AppRootState) => state.search;
const selectRemidersState = (state: AppRootState) => state.remindersState;
const selectUserPro = (state: AppRootState) => state.userProState;
const selectNotifications = (state:AppRootState) => state.notifications

function HeaderComponent() {
  const appState = useSelector(selectAppState);
  const navigate = useNavigate();
  const notifications = useSelector(selectNotifications)
  const [isFocusBtn, setFocusBtn] = useState(false);
  const searchState = useSelector(selectSearchState);
  const remindersState = useSelector(selectRemidersState);
  const dispatch: thunkDispatchRootType = useDispatch();
  const location = useLocation();
    
  const user = useSelector<AppRootState, stateUserType>((state) => state.user);
  const userProState = useSelector(selectUserPro);

  const activeNotifLengthCount = notifications.clientReminders.length + notifications.userIviteNotifications.length + notifications.userTeammateNotifInfo.length
  const hoverButtonHandler = (val: boolean) => {
    if (location.pathname === "/tariffs") setFocusBtn(val);
  };
  const setSearchWindow = (val: string) => {
    dispatch(setSearchTermAC(val));
  };

  const returnTariffBtnJsx = (): React.JSX.Element => {
    if (user.user?.tariff === tariffType.PRO) {
      return isFocusBtn ? (
        <TariffInfoBlock title="PRO" price="99" setFocusBtn={setFocusBtn} />
      ) : (
        <HeaderButton
          onMouseEnter={() => hoverButtonHandler(true)}
          onMouseLeave={() => hoverButtonHandler(false)}
          varinat="header"
          onClick={() => navigate("/tariffs")}
        >
          <FlexBox direction="row" align="center" justify="center" gap={2}>
            <Icon width={20} height={19} svgID="diamond" />
            <Text weight={700} size={12} font="mont" color="#fff">
              {"PRO"}
            </Text>
          </FlexBox>

          <div>
            <Text weight={400} size={14} font="mont" color="#fff">
              {`${user.user.balance ? user.user.balance : 0} рублей`}{" "}
            </Text>
          </div>
        </HeaderButton>
      );
    } else if (user.user?.tariff === tariffType.SuperPRO) {
      return isFocusBtn ? (
        <TariffInfoBlock
          title="PRO"
          price="125"
          subtitle="plus"
          setFocusBtn={setFocusBtn}
        />
      ) : (
        <HeaderButton
          onMouseEnter={() => hoverButtonHandler(true)}
          onMouseLeave={() => hoverButtonHandler(false)}
          varinat="header"
          onClick={() => navigate("/tariffs")}
        >
          <FlexBox direction="row" align="center" justify="center" gap={2}>
            <Icon width={20} height={19} svgID="diamond" />
            <FlexBox direction="column">
              <Text
                style={{ lineHeight: "11px" }}
                weight={700}
                size={12}
                font="mont"
                color="#fff"
              >
                {"PRO"}
              </Text>
              <Text
                style={{ lineHeight: "9px" }}
                weight={500}
                size={10}
                font="mont"
                color="#fff"
              >
                {"plus"}
              </Text>
            </FlexBox>
          </FlexBox>

          <div>
            <Text weight={400} size={14} font="mont" color="#fff">
              {`${user.user.balance ? user.user.balance : 0} рублей`}{" "}
            </Text>
          </div>
        </HeaderButton>
      );
    }
    return (
      <HeaderButton varinat="header" onClick={() => navigate("/tariffs")}>
        <Icon width={17} height={19} svgID="proIcon" />
        <div>
          <Text weight={400} size={12} font="mont" color="#fff">
            {returnTariff(user.user?.tariff as tariffType)
              .split(" ")
              .splice(0, 2)
              .join(" ")}{" "}
          </Text>
          <Text weight={700} size={14} font="mont" color="#fff">
            {returnTariff(user.user?.tariff as tariffType)
              .split(" ")
              .splice(2, 1)
              .join(" ")}
          </Text>
        </div>
      </HeaderButton>
    );
  };

  useEffect(() => {
    // socket.on("notification", (data) => {
    //     if (data.type === "team-request") {
    //         dispatch(setIsNotificationAC(true))
    //         dispatch(getTeamMateRequestTC(user.user?._id as string))
    //         dispatch(setIsNotificationAC(false))
    //     }
    // })

    dispatch(getClientRemindersTC());
  }, [remindersState.clientReminders.length]);

  useEffect(() => {
    if (searchState.term && searchState.term.length > 2) {
      dispatch(showSearchWindowAC(true));
    } else {
      dispatch(showSearchWindowAC(false));
    }
  }, [searchState.term]);

  const isSettingIcon = location.pathname === "/timeTable"
    // location.pathname === "/main" ||
    // location.pathname === "/tariffs" ||
    // location.pathname === "/analytic" ||
    // location.pathname === "/proSettings" ||
    // location.pathname === "/userProfile";
  const isAllowedNotification = (): boolean => {
    // if (user.user?.tariff === tariffType.PRO) return true;
    // if (user.user?.tariff === tariffType.SuperPRO) return true;
    // return false;
    return true
  };

  const [showNewDiv, setShowNewDiv] = useState(false);

  const handleButtonClick = () => {
    setShowNewDiv((prevShowNewDiv) => !prevShowNewDiv);
  };

  return (
    <>
      <FlexBox
        className="header"
        marginBottom="25px"
        height="36px"
        align="center"
        direction="row"
        width="100%"
        justify="space-between"
      >
        <Box width="max-content" component="search" position="relative">
          <Icon svgID="search" width={13} height={12} />
          <TextField
            type="search"
            autoComplete="off"
            width="100%"
            minWidth="200px"
            maxWidth="283px"
            value={searchState.term || ""}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setSearchWindow(e.currentTarget.value)
            }
            placeholder="Найти на сайте"
            kind="search"
          />
        </Box>

        <FlexBox
          direction="row"
          width="36px"
          height="36px"
          borderRadius="8px"
          align="center"
          justify="center"
          background="#f8f8f8"
          style={{position: "relative"}}
          
        >
           {activeNotifLengthCount > 0 && (
                   <Box
              top="0px"
              right="-10px"
              position="absolute"
              width="17px"
              height="15px"
              borderRaduis="40%"
              background="red"
            >
              <Text color="white" size={11} font="mont">
                {activeNotifLengthCount}
              </Text>
            </Box>
            
           
          )}
          {/*                     <NavLink to={"/notifications"}> */}
          <SuperButton
            disabled={!isAllowedNotification()}
            varinat="notification"
            onClick={handleButtonClick}
          >
            <Icon width={16} height={20} svgID="ring" />
            <div className="tooltip">Для тарифов PRO и PRO+</div>
          </SuperButton>
          {showNewDiv && <NotifBlock></NotifBlock>}
          {/*                     </NavLink> */}
         
        </FlexBox>

        <FlexBox
          gap={25}
          align="center"
          height="36px"
          direction="row"
          justify="center"
        >
          {returnTariffBtnJsx()}
          {isSettingIcon 
            && (
            <Link to={`${location.pathname}/settings`}>
              <Icon svgID="setting" width={22} height={22} />
            </Link>
          )}
        </FlexBox>
      </FlexBox>
    </>
  );
}

export const Header = React.memo(HeaderComponent);
