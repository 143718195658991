import { css } from "styled-components";
import { styled } from "styled-components";
import { theme } from "./Theme.styled";



type propsType = {
    direction: "column" | "row",
    gap?: number,
    marginTop?: string,
    justify?: "center" | "end" | "start" | "space-between" | "space-around",
    align?: "center" | "end" | "start",
    width?: string,
    padding?: string,
    background?: string
    height?: string,
    borderRadius?: string,
    marginBottom?: string,
    marginLeft?: string,
    fontSize?: number,
    maxWidth?: string,
    minWidth?: string,
    overFlow?: string,
    basis?: string,
    border?: string,
    boxShadow?: string,
    slideCount?: number,
    activeDate?: boolean,
    minHeight?:string
}

export const FlexBox = styled.div<propsType>`
    display: flex;
    flex-direction: ${props => props.direction};
    gap: ${props => props.gap ? props.gap + "px" : 0};
    border-radius: ${props => props.borderRadius ? props.borderRadius : 0};
    margin-top: ${props => props.marginTop ? props.marginTop : 0};
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : 0};
    margin-left: ${props => props.marginLeft ? props.marginLeft : 0};
    @media (max-width: 900px) {
        gap:2px;
    }
    ${props => props.background && css<propsType>`
        background: ${props => props.background};
    `};
    ${props => props.maxWidth && css<propsType>`
    max-width: ${props => props.maxWidth};
`};
${props => props.minWidth && css<propsType>`
    min-width: ${props => props.minWidth};
`};
${props => props.minHeight && css<propsType>`
    min-height: ${props => props.minHeight};
`};
${props => props.basis && css<propsType>`
    flex-basis: ${props => props.basis};
`};
${props => props.border && css<propsType>`
    border: ${props => props.border};
`};
${props => props.boxShadow && css<propsType>`
    box-shadow: ${props => props.boxShadow};
`};
${props => props.overFlow && css<propsType>`
    overflow: ${props => props.overFlow};
`};
${props => props.activeDate && css<propsType>`
    background: ${theme.colors.main};
    span {
        color: #fff;
    }
    &:hover {
        span {
            color: #262626;
        }
    }
    
`};
${props => props.slideCount && css<propsType>`
    .timesBtns {
        transform: translateY(${props => props.slideCount + "px"});
        transition: 1s;
    }
`};

   ${props => props.fontSize && css<propsType>`
    font-size: ${props => props.fontSize + "px"};
        @media screen and (max-width: 1490px) {
        font-size: ${props => props.fontSize && props?.fontSize - 1 + "px"};
      };
        @media screen and (max-width: 1400px) {
        font-size: ${props => props.fontSize && props?.fontSize - 1.3 + "px"};
      };
        @media screen and (max-width: 1350px) {
        font-size: ${props => props.fontSize && props?.fontSize - 1.8 + "px"};
      };
        @media screen and (max-width: 1180px) {
        font-size: ${props => props.fontSize && props?.fontSize - 2.8 + "px"};
      };
        @media screen and (max-width: 1080px) {
        font-size: ${props => props.fontSize && props?.fontSize - 3 + "px"};
      };
        @media screen and (max-width: 980px) {
        font-size: ${props => props.fontSize && props?.fontSize - 4.1 + "px"};
      };
   `}
    ${props => props.width && css<propsType>`
        width: ${props => props.width};
    `};
    ${props => props.height && css<propsType>`
        height: ${props => props.height};
    `};
    ${props => props.padding && css<propsType>`
        padding: ${props => props.padding};
    `};
    ${props => props.justify && css<propsType>`
        justify-content: ${props => props.justify};
    `};
    ${props => props.align && css<propsType>`
        align-items: ${props => props.align};
    `};
   
    .proTitle {
        @media screen and (max-width: 1400px) {
            font-size: 40px;
        };
        @media screen and (max-width: 1200px) {
            font-size: 35px;
        };
        @media screen and (max-width: 1100px) {
            font-size: 30px;
        };
    };
    .timeTableTitle {
        @media screen and (max-width: 768px) {
               display: none;
       };
    }
    .header {
        @media screen and (max-width: 768px) {
           display: none
        };
    }
    .mainPageTitle {
        @media screen and (max-width: 768px) {
           display: none
        };
    }
    .clientSearch {
        @media screen and (max-width: 768px) {
           display: none
        };
    }
    .newClientBtn {
        @media screen and (max-width: 768px) {
           width: 36px;
           height: 36px;
           min-width:36px;
           max-width: 36px;
           margin-left: 10px;
           span {
             display: none 
           };
           svg {
            width: 12px
           }
        };
    };
    
    .clientTableHeader {
        @media screen and (max-width: 768px) {
           display: none
        };
    }
    .sort {
        @media screen and (max-width: 768px) {
           height: 36px;
           width: 36px;
           min-width: 36px;
        };
    };
    .clientStats {
        @media screen and (max-width: 768px) {
           width: 100%;
        };
    };
    .clientStatsContainer {
        @media screen and (max-width: 768px) {
           width: 100%;
        };
    };
    .editCard {
        @media screen and (max-width: 768px) {
           height: 425px;
           flex-direction: column
        };
    };
    .selectPeriodContainer {
        @media screen and (max-width: 768px) {
           display: none;
        };
    };
    .newEntryDateAndTime {
        @media screen and (max-width: 768px) {
           height: 36px
        };
    }
    .mobileTimeTablePeriod {
        display:none;
        @media screen and (max-width: 768px) {
           display: flex;
        };
    };
    .timeTableHeader {
        @media screen and (max-width: 768px) {
           justify-content:flex-end;
           align-items: end;
           .timeTableHeaderNav {
            gap: 10px
           }
        };
    };
    .newEntryBtnContainer {
        @media screen and (max-width: 768px) {
           display: none;
        };
    };
    
    .timeTableHead {
        @media screen and (max-width: 768px) {
           display: none;
        };
    };
    .settingTitleEmployee{
        @media screen and (max-width: 768px) {
           display: none;
        };
    };
   
    .addNewClientBorder {
        @media screen and (max-width: 768px) {
           display: none;
        };
    };
    .settingHeadTitle {
        @media screen and (max-width: 768px) {
           display: none;
        };
    };
    .Avatars {
        @media screen and (max-width: 900px) {
           display: none;
        };
    };
    .settingCalendarFreeTime {
        height: 65%;
        @media screen and (max-width: 768px) {
           display: none;
        };
    };
    .windowForTime {
        @media screen and (max-width: 768px) {
           height: 90%;
          
        };
    };
    .windowForTimeContainer {
        @media screen and (max-width: 768px) {
            height: 50%
          
        };
        
    }
    .settingContentContainer {
        @media screen and (max-width: 768px) {
            flex-direction:column;
            padding:10px 5px;
            gap: 15px;
        };
       
    };
    .settingTableHeader {
        @media screen and (max-width: 768px) {
            background: #262626 !important;
            padding:0
        };
        
    };
    .settingHeadHeader {
        @media screen and (max-width: 768px) {
            padding:0;
            gap:10px;
        };
    };
    .settingHeaderCalendarBtn {
        @media screen and (max-width: 768px) {
            width: 100%;
            gap:10px;
        };
    };
    .settingHeaderCalendarSwitch {
        @media screen and (max-width: 768px) {
            width: 100%
        };
    };
    .btnsPeriodBtns {
        @media screen and (max-width: 768px) {
            height: 40px;
            button {
                height: 40px;
            }
        };
    };
    .settingsTableDate {
        @media screen and (max-width: 768px) {
            margin-bottom:35px;
            height: 100%;
            overflow: hidden;
        };
    };
    .settingDateBox {
        @media screen and (max-width: 768px) {
            height: 85%
        };
    };
    .settingTableHeaderItem{
        width:100%;
    };
    .mobileTimeTableSeetingLink {
        display: none;
        @media screen and (max-width: 768px) {
            display: flex;
        };
    }
    .ActionClient{
        display: none;
        @media screen and (max-width: 520px) {
            display: block; 
        }
    }
    .NonActionClient{
        @media screen and (max-width: 520px) {
            display: none; 
        }
    }
    
`