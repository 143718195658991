import axios from "axios"
import { tokenService } from "./tokenService"
import { searchingTeamMateUserType } from "../store/ProsettingsReducer"
import { TCreateTeammateForm } from "../apiTypes/formTypes/fillModeFormTypes"
import { teamMateViewType,  updateTeammateRightsInputType } from "../apiTypes/root/userProPlus.type"
import { freeTimeForTeamType, workTimeForTeamType } from "../apiTypes/responses/workTimeResponseType"
import { createNoteForNewUserType, createNoteViewType, noteInputType } from "../apiTypes/responses/notesResponsesType"
import { apiAuth } from "./apiAuth"
import { notesStateType } from "../store/NotesReducer"
import { queueType } from "../apiTypes/root/queue.type"
import { clientUpdateRequestType } from "../apiTypes/responses/clientResponsesType"
import { addWaSendingTimeTableFT } from "../apiTypes/formTypes/wa.types"

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_ENDPOINT}/api/super-pro/user`
})

instance.interceptors.request.use(
  (config) => {
      const token = tokenService.getToken()
      if(token) config!.headers!.Authorization = token
      return config
  },
  (error) => Promise.reject(error)
)
instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { config } = error;
      if (error.response.status === 401 && !config._retry) {
        config._retry = true;
        try {
          const res = await apiAuth.refreshToken();
          tokenService.saveToken(res.data.accessToken);
          instance.defaults.headers.common.Authorization =
            tokenService.getToken();
          return instance(config);
        } catch (error) {
          tokenService.removeToken();
        }
      } else {
        // tokenService.removeToken();
      }
      return Promise.reject(error);
    }
  );



export const apiUserSuperPro = {
    getByWorkId(workID: string){
        return instance.get<searchingTeamMateUserType>(`/get/teamMate/${workID}`)
    },
    createTeammate(data:  TCreateTeammateForm) {
        const formData = new FormData()
        formData.append("userAvatar", data.image[0])
        formData.append("firstName", data.firstName)
        formData.append("lastName", data.lastName)
        formData.append("thirdName", data.thirdName)
        formData.append("email", data.email)
        formData.append("phoneNumber", data.phoneNumber)
        formData.append("password", data.password)
        formData.append("color", data.color)
        return instance.post("/create/user", formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
    },
    getAllTeammatesForAdmin(){
        return instance.get<teamMateViewType>("/teammates")
    },
     sendTeammate(userId: string){
        return instance.put(`/send-teammate-request/${userId}`)
    },
    changeTeamMateRights(data:updateTeammateRightsInputType, userId: string){
        return instance.put(`teammates/${userId}/rights`, {rights: data})
    },
    getTeamNotes(date: string){
        return instance.get<notesStateType[]>(`/notes/${date}`)
    },
    getWorkTimes(date: string){
        return instance.get<workTimeForTeamType>(`/workTimes/${date}`)
    },
    getFreeTimes(date: string){
        return instance.get<freeTimeForTeamType>(`/freeTimes/${date}`)
    },
    getRenderTime(date: string){
      return instance.get<string[]>(`render-time/for-day/${date}`)
    },
    createNote(data: noteInputType, userId: string){
        return instance.post<createNoteViewType>(`/notes/create`,{data, userId})
    },
    getNotesForWeek(masterId: string, start: string, end: string){
      return instance.get<notesStateType[]>(`/notes/week/${masterId}`,{
          params: {  
              start, 
              end
          }
      })
  },
  getQueue(year: number, month: number){
    return instance.get<queueType[]>("/queue",{
      params: {
        year, month
      }
    })
  },
  makeAdmin(teammateId: string){
    return instance.put(`/make/admin/${teammateId}`)
  },
  removeAdmin(teammateId: string){
    return instance.put(`/remove/admin/${teammateId}`)
  },
  getClients(){
    return instance.get("/clients")
  },
  updateClients(id:string, data: clientUpdateRequestType){
    return instance.put(`/clients/update/${id}`, data)

  },
  removeClient(id: string){
    return instance.delete(`/clients/delete/${id}`)

  },
  removeNoteForWeek(id: string){
    return instance.delete(`/notes/${id}`)
  },
editNote( data: noteInputType,id: string, masterId: string){
  return instance.put<notesStateType>(`/notes/${id}/${masterId}`,data)
},
creteClientAndNewEntry (client: { photo: any, name: string, surname: string, patronymic: string, phone: string | null, gender: string, birthday: Date | null, agreement: boolean, waAgreement: boolean}, note: createNoteForNewUserType & {serviceId: string}){
  const {phone,photo,name,surname,patronymic,gender,birthday,agreement} = client
  const formData = new FormData()
  formData.append('clientAvatar', photo)
  formData.append('name', name)
  formData.append('surname', surname)
  formData.append('patronymic', patronymic)
  if (phone) formData.append('phone', phone)
  formData.append('gender', gender)
  if (birthday) formData.append('birthday', birthday?.toISOString())
  formData.append('agreement', JSON.stringify(agreement))
  // formData.append("date", note.date)
  // formData.append("time", note.time)
  // formData.append("duration", note.duration)
  // formData.append("userId", note.userId)
  // formData.append("comments")
  formData.append("note", JSON.stringify(note))
  formData.append("waAgreement", JSON.stringify(client.waAgreement))
  return instance.post(`/clients/new-entry`, formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  })
},
 createWaPatternTimeTable(data: addWaSendingTimeTableFT){
      return instance.post("/WA/pattern",data)
    },
    deleteWaPatternTimeTable(id: string){
      return instance.delete(`/WA/pattern/${id}`)
    },
    editWaPatternTimeTable(id: string, data: addWaSendingTimeTableFT){
      return instance.put(`/WA/pattern/${id}`, data)
    },
    getWaPatternTimeTable(){
      return instance.get(`/WA/pattern/`)
    },
   
}