import { clientsStateType } from "./ClientsReducer"
import { workCategoryType, workServiceType } from "../apiTypes/root/proSettings.type"
import { notesStateType } from "./NotesReducer"
import { clientCardType } from "../apiTypes/root/clientPro.type"

type newClientType = {
    value: boolean,
    userID: string,
    clients: clientsStateType[]
}
type removeClientType = {
    value: boolean,
    clientID: string,
    
}
type removeWorkServiceType = {
    value: boolean,
    id: string
}
type editworkServiceType = {
    value: boolean,
    workService: workServiceType | null
}
type clientWorkWindowStateType = {
    value: boolean,
    client: clientsStateType | null
}

type editNoteStateType = {
    value: boolean,
    note: notesStateType | null
   
}
type queueStateType = {
    value: boolean,
    client: clientsStateType | null
}

type clientBalanceWindowStateType = {
    value: boolean,
    client: clientsStateType | null
}
type remindWindowStateType = {
    value: boolean,
    client: clientsStateType | null
}
type editClientWindowStateType = {
    value: boolean, client: clientsStateType | clientCardType | null
}
type deleteWorkCategoryStateType = {
    value: boolean, categoryId: string
}
type editWorkCategoryStateType = {
    value: boolean, category: workCategoryType
}
type queueRescheduleStateType = {
    value: boolean,
    month: number,
    year: number,
    workServiceId: string,
    clientId: string,
    id: string
}
type newEntryShowType = {
    value: boolean,
    fromQueue: boolean
}
type newEntryFromQueueStateType = {
    clientId: string,
    workServiceId: string,
    queueId: string
}
type adminPayWindowStateType = {
    value: boolean,
    userId: string
}
export type ModalWindowStateType = {
    newEntryShow: newEntryShowType,
    modalForUpdateDay: boolean,
    calendarWorkDays: boolean,
    addNewClientCard: boolean,
    client: clientsStateType | null,
    times: {
        start: number,
        end: number
    }[],
    timesWindow: boolean,
    errorWindow: boolean,
    dateForNote?: string,
    timeForNote?: string,
    durationForNote?: string,
    removeNoteWindow: boolean,
    noteID: string,
    clientPay: boolean,
    createWorkWindow: boolean,
    createCategory: boolean,
    queue: queueStateType,
    calendarReminders: boolean,
    editClient: editClientWindowStateType,
    editSessionWindow: boolean,
    additionalWindowOptions: boolean,
    callList: boolean,
    queueMonth: boolean,
    additionalOptionsArray: string[],
    modeModalTeamMate: boolean,
    creatingTeammateWindow: boolean,
    newClientWindow: newClientType,
    removeClientWindow: removeClientType,
    timePickerWindow: boolean,
    calendarEditionDates:boolean,
    editCard: boolean,
    payWindow:boolean,
    qrCodeWindow: boolean,
    removeWorkState: removeWorkServiceType,
    editWorkServiceState: editworkServiceType,
    editNoteState: editNoteStateType,
    clientBalanceWindow: clientBalanceWindowStateType,
    remindWindowState: remindWindowStateType,
    clientWorkWindowState: clientWorkWindowStateType,
    searchWindow: boolean,
    deleteWorkCategoryState: deleteWorkCategoryStateType,
    editWorkCategoryState: editWorkCategoryStateType,
    queueRescheduleState: queueRescheduleStateType,
    newEntryFromQueueState:newEntryFromQueueStateType,
    adminPayWindow: adminPayWindowStateType,
    searchTeammateWindow: boolean,
    waWindow: {
        value: boolean, client: null | clientsStateType,
        note: notesStateType | null
    }
}

export type actionsModalType = showNewEntryType | showModalForUpdateDayType | showCalendarWorkDaysAT | addNewClientCardAT | selectClientAT | editSelectTimeAT | showTimesWindowAT |
    showErrorWindowAT | setDateAndTimeAT | showRmNoteWindowAT  | createWork | createCategory | createQueueAT | showCalendarRemindersAT
    | EditClientAT  | showEditSessionAT | showAdditionalWindowOptionsAT | showCallListAT | showQueueMonthAT | setAdditionalOptionsArrayAT
    | deleteAdditionalOptionAT | addTeamMateAT | showCreateTeammateAT | resetModalWindowsAT | showNewClientAT | showRemoveClientAT | showTimePickerAT | showCalendarEditionDateAT
    | editClCardType | showPayWindowAT | showQrCodeWindowAT | removeWorkServiceWindowAT | editWorkServiceWindowAT | editNoteWindowAT | clientBalanceWindowAT | remindWindowAT
    | clientWorkWindowAT | showSearchWindowAT | deleteWorkCategoryAT | editWorkCategoryAT | queueRescheduleAT | setNewEntryFromQueueStateAT 
    |  showadminPayWindowAT | setSearchTeammateWindowAT | showWaWindowAT
type showNewEntryType = {
    type: "SHOW-NEWENTRY",
    newEntryShow: newEntryShowType
}
type showModalForUpdateDayType = {
    type: "SHOW-CHANGE-WINDOW",
    modalForUpdateDay: boolean
}
type showCalendarWorkDaysAT = {
    type: "SHOW-CALENDAR-WORK-DAYS",
    calendarWorkDays: boolean
}
type addNewClientCardAT = {
    type: "ADD-NEW-CLIENT-CARD",
    addNewClientCard: boolean
}
type selectClientAT = {
    type: "SELECT-CLIENT",
    client: clientsStateType | null
}
type editSelectTimeAT = {
    type: "EDIT-SELECT-TIME",
    times: {
        start: number,
        end: number
    }[]
}
type showTimesWindowAT = {
    type: "SHOW-TIMES-WINDOW",
    timesWindow: boolean
}
type showErrorWindowAT = {
    type: "SHOW-ERROR-WINDOW",
    errorWindow: boolean
}
type setDateAndTimeAT = {
    type: "SET-DATE-TIME",
    dateForNote?: string,
    timeForNote?: string,
    durationForNote?: string
}
type showRmNoteWindowAT = {
    type: "SHOW-REMOVE-WINDOW",
    removeNoteWindow: boolean,
    _id: string

}

type createWork = {
    type: "CREATE-WORK",
    createWork: boolean
}
type createCategory = {
    type: "CREATE-CATEGORY",
    createCategory: boolean
}
type createQueueAT = {
    type: "CREATE-QUEUE",
    payload: queueStateType
}

type showCalendarRemindersAT = {
    type: "SHOW-CALENDAR-REMINDERS",
    calendarReminders: boolean

}
type EditClientAT = {
    type: "EDIT-CLIENT-WINDOW",
    editClient: editClientWindowStateType
}


type showEditSessionAT = {
    type: "SET-EDIT-SESSION-WINDOW",
    editSessionWindow: boolean

}
type showAdditionalWindowOptionsAT = {
    type: "SHOW-ADDITIONAL-WINDOW",
    additionalWindowOptions: boolean
}
type showCallListAT = {
    type: "SHOW-CALL-LIST",
    callList: boolean
}
type showQueueMonthAT = {
    type: "SHOW-QUEUE-MONTH",
    queueMonth: boolean
}
type setAdditionalOptionsArrayAT = {
    type: "SET-ADDITIONAL-OPTIONS-ARRAY",
    el: string
}
type deleteAdditionalOptionAT = {
    type: "DELETE-ADDITIONAL-OPTION",
    el: string
}
type addTeamMateAT = {
    type: "MODALS/SELECT-MODAL-WINDOW-ADD-TEAM-MATE",
    modeModalTeamMate: boolean
}


type showCreateTeammateAT = {
    type: "MODAL/SHOW-CREATE-TEAMMATE",
    creatingTeammateWindow: boolean
}

type resetModalWindowsAT = {
    type: "MODAL/RESET-STATE"
}
type showNewClientAT = {
    type: "MODAL/NEW-CLIENT",
    payload: newClientType
}
type showRemoveClientAT = {
    type: "MODAL/REMOVE-CLIENT",
    payload: removeClientType
}
type showTimePickerAT = {
    type: "MODAL/TIME-PICKER",
    value: boolean
}
type showCalendarEditionDateAT = {
    type: "MODAL/CALENDAR-EDITION",
    value: boolean
}
type editClCardType = {
    type: 'EDIT-CARD',
    editCard: boolean
}
type showPayWindowAT = {
    type: "MODAL/PAY-WINDOW",
    value: boolean
}
type showQrCodeWindowAT = {
    type: "MODAL/QR-CODE-WINDOW",
    value: boolean
}
type removeWorkServiceWindowAT = {
    type: "MODAL/REMOVE-WORK-WINDOW",
    payload: removeWorkServiceType
}
type editWorkServiceWindowAT = {
    type: "MODAL/EDIT-WORK-SERVICE-WINDOW",
    payload: editworkServiceType
}
type editNoteWindowAT = {
    type: "MODAL/EDIT-NOTE",
    payload: editNoteStateType
}
type clientBalanceWindowAT = {
    type: "MODAL/CLIENT-BALANCE",
    payload: clientBalanceWindowStateType
}

type remindWindowAT = {
    type: "MODAL/REMIND-WINDOW",
    payload: remindWindowStateType
}
type clientWorkWindowAT = {
    type: "MODAL/CLIENT-WORK-WINDOW",
    payload: clientWorkWindowStateType
}
type showSearchWindowAT = {
    type: "MODAL/SEARCH-WINDOW",
    value: boolean
}
type deleteWorkCategoryAT = {
    type: "MODAL/DELETE-WORK-CATEGORY",
    payload: deleteWorkCategoryStateType
}
type editWorkCategoryAT = {
    type: "MODAL/EDIT-WORK-CATEGORY",
    payload: editWorkCategoryStateType
}
type queueRescheduleAT = {
    type: "MODAL/QUEUE-RESCHEDULE",
    payload: queueRescheduleStateType
}

type setNewEntryFromQueueStateAT = {
    type: "MODAL/SET-STATE-NEW-ENTRY-FROM-QUEUE",
    payload: newEntryFromQueueStateType
}
type showadminPayWindowAT = {
    type: "MODAL/SHOW-ADMIN-PAY",
    payload: adminPayWindowStateType
}
type setSearchTeammateWindowAT = {
    type: "MODAL/SET-SEARCH-WINDOW",
    value: boolean
}
type showWaWindowAT = {
    type: "MODAL/WA-WINDOW",
    payload: {
        value: boolean,
        client: null | clientsStateType,
        note: null | notesStateType
    }
}
const initState: ModalWindowStateType = {
    newEntryShow: {value: false, fromQueue: false},
    modalForUpdateDay: false,
    calendarWorkDays: false,
    addNewClientCard: false,
    client: null,
    times: [],
    timesWindow: false,
    errorWindow: false,
    removeNoteWindow: false,
    noteID: "",
    clientPay: false,
    createWorkWindow: false,
    createCategory: false,
    queue: {
        value: false,
        client: null
    },
    calendarReminders: false,
    editClient: {value: false, client: null},
    editSessionWindow: false,
    additionalWindowOptions: false,
    callList: false,
    queueMonth: false,
    additionalOptionsArray: [],
    modeModalTeamMate: false,
    creatingTeammateWindow: false,
    newClientWindow: {
        value: false,
        userID: "",
        clients: []
    },
    removeClientWindow: {
        value: false,
        clientID: ""
    },
    timePickerWindow: false,
    calendarEditionDates: false,
    editCard: false,
    payWindow: false,
    qrCodeWindow: false,
    removeWorkState: {
        value: false,
        id: ""
    },
    editWorkServiceState: {
        value: false,
        workService: null
    },
    editNoteState: {
        value: false,
        note: null,
        
    },
    clientBalanceWindow: {
        value: false,
        client: null
    },
    remindWindowState: {
        value: false,
        client: null
    },
    clientWorkWindowState: {
        value: false,
        client: null
    },
    searchWindow: false,
    deleteWorkCategoryState: {
        value: false, categoryId: ""
    },
    editWorkCategoryState: {
        value: false, category: {id: "", category: ""}
    },
    queueRescheduleState: {
        value: false,
        month: 0,
        year: 0,
        workServiceId: "",
        clientId: "",
        id: ""
    },
    newEntryFromQueueState: {
        clientId: "",
        workServiceId: "",
        queueId: ""
    },
    adminPayWindow: {
        value: false,
        userId: ""
    },
    searchTeammateWindow: false,
    waWindow: {
        value: false,
        client: null,
        note: null
    }
}

export const ModalWindowReducer = (state: ModalWindowStateType = initState, action: actionsModalType): ModalWindowStateType => {
    switch (action.type) {
        case "SHOW-NEWENTRY":
            return { ...state, newEntryShow: action.newEntryShow }
        case "SHOW-CHANGE-WINDOW":
            return { ...state, modalForUpdateDay: action.modalForUpdateDay }
        case "SHOW-CALENDAR-WORK-DAYS":
            return { ...state, calendarWorkDays: action.calendarWorkDays }
        case "ADD-NEW-CLIENT-CARD":
            return { ...state, addNewClientCard: action.addNewClientCard }
        case "SELECT-CLIENT":
            return { ...state, client: action.client as clientsStateType }
        case "EDIT-SELECT-TIME":
            return { ...state, times: action.times }
        case "SHOW-TIMES-WINDOW":
            return { ...state, timesWindow: action.timesWindow }
        case "SHOW-ERROR-WINDOW":
            return { ...state, errorWindow: action.errorWindow }
        case "SET-DATE-TIME": {
            return { ...state, dateForNote: action.dateForNote, timeForNote: action.timeForNote, durationForNote: action.durationForNote }
        }
        case "SHOW-REMOVE-WINDOW": {
            return { ...state, removeNoteWindow: action.removeNoteWindow, noteID: action._id }
        }
        
        case "CREATE-WORK": {
            return { ...state, createWorkWindow: action.createWork }
        }
        case "CREATE-CATEGORY": {
            return { ...state, createCategory: action.createCategory }
        }
        case "CREATE-QUEUE":
            return { ...state, queue: action.payload }
        
        case "SHOW-CALENDAR-REMINDERS":
            return { ...state, calendarReminders: action.calendarReminders }
        case "EDIT-CLIENT-WINDOW":
            return { ...state, editClient: action.editClient }
        
        case "SET-EDIT-SESSION-WINDOW":
            return { ...state, editSessionWindow: action.editSessionWindow }
        case "SHOW-ADDITIONAL-WINDOW":
            return { ...state, additionalWindowOptions: action.additionalWindowOptions }
        case "SHOW-CALL-LIST":
            return { ...state, callList: action.callList }
        case "SHOW-QUEUE-MONTH":
            return { ...state, queueMonth: action.queueMonth }
        case "SET-ADDITIONAL-OPTIONS-ARRAY":
            return { ...state, additionalOptionsArray: [...state.additionalOptionsArray, action.el] }
        case "DELETE-ADDITIONAL-OPTION":
            return { ...state, additionalOptionsArray: state.additionalOptionsArray.filter(el => el !== action.el) }
        case "MODALS/SELECT-MODAL-WINDOW-ADD-TEAM-MATE":
            return { ...state, modeModalTeamMate: action.modeModalTeamMate }
        case "MODAL/SHOW-CREATE-TEAMMATE":
            return { ...state, creatingTeammateWindow: action.creatingTeammateWindow }
        case "MODAL/NEW-CLIENT":
            return { ...state, newClientWindow: action.payload }
        case "MODAL/REMOVE-CLIENT":
            return { ...state, removeClientWindow: action.payload }
        case "MODAL/TIME-PICKER":
            return {...state, timePickerWindow: action.value}
        case "MODAL/CALENDAR-EDITION":
            return {...state, calendarEditionDates: action.value}
        case "EDIT-CARD":
            return {...state, editCard: action.editCard}
        case "MODAL/PAY-WINDOW":
            return {...state, payWindow: action.value}
        case "MODAL/QR-CODE-WINDOW":
            return {...state, qrCodeWindow: action.value}
        case "MODAL/REMOVE-WORK-WINDOW":
            return {...state, removeWorkState: action.payload}
        case "MODAL/EDIT-WORK-SERVICE-WINDOW":
            return {...state, editWorkServiceState: action.payload}
        case "MODAL/EDIT-NOTE":
            return {...state, editNoteState: action.payload}
        case "MODAL/CLIENT-BALANCE":
            return {...state, clientBalanceWindow: action.payload}
        case "MODAL/REMIND-WINDOW":
            return {...state, remindWindowState: action.payload}
        case "MODAL/CLIENT-WORK-WINDOW":
            return {...state, clientWorkWindowState: action.payload}
        case "MODAL/SEARCH-WINDOW":
            return {...state, searchWindow: action.value}
        case "MODAL/DELETE-WORK-CATEGORY":
            return {...state, deleteWorkCategoryState: action.payload}
        case "MODAL/EDIT-WORK-CATEGORY":
            return {...state, editWorkCategoryState: action.payload}
        case "MODAL/QUEUE-RESCHEDULE":
            return {...state, queueRescheduleState: action.payload}
        case "MODAL/SET-STATE-NEW-ENTRY-FROM-QUEUE":
            return {...state, newEntryFromQueueState: action.payload}
        case "MODAL/SHOW-ADMIN-PAY":
            return {...state, adminPayWindow: action.payload}
        case "MODAL/SET-SEARCH-WINDOW":
            return {...state, searchTeammateWindow: action.value}
        case "MODAL/WA-WINDOW":
            return {...state, waWindow: action.payload}
        case "MODAL/RESET-STATE":
            state = initState
            return state
        default:
            return state
    }
}

export const showNewEntryAC = (newEntryShow: newEntryShowType): showNewEntryType => {
    return { type: "SHOW-NEWENTRY", newEntryShow }
}
export const showModalForUpdateDayAC = (modalForUpdateDay: boolean): showModalForUpdateDayType => {
    return { type: "SHOW-CHANGE-WINDOW", modalForUpdateDay }
}
export const showCalendarWorkDaysAC = (calendarWorkDays: boolean): showCalendarWorkDaysAT => {
    return { type: "SHOW-CALENDAR-WORK-DAYS", calendarWorkDays }
}
export const addNewClientCardAC = (addNewClientCard: boolean): addNewClientCardAT => {
    return { type: "ADD-NEW-CLIENT-CARD", addNewClientCard }
}
export const selectClientAC = (client: clientsStateType | null): selectClientAT => {
    return { type: "SELECT-CLIENT", client }
}
export const editSelectTimeAC = (times: { start: number, end: number }[]): editSelectTimeAT => {
    return { type: "EDIT-SELECT-TIME", times }
}
export const showTimesWindowAC = (timesWindow: boolean): showTimesWindowAT => {
    return { type: "SHOW-TIMES-WINDOW", timesWindow }
}
export const showErrorWindowAC = (errorWindow: boolean): showErrorWindowAT => {
    return { type: "SHOW-ERROR-WINDOW", errorWindow }
}
export const setDateAndTimeAC = (dateForNote: string, timeForNote: string, durationForNote?: string): setDateAndTimeAT => {
    return { type: "SET-DATE-TIME", dateForNote, timeForNote, durationForNote }
}
export const showRmNoteWindowAC = (removeNoteWindow: boolean, _id: string): showRmNoteWindowAT => {
    return { type: "SHOW-REMOVE-WINDOW", removeNoteWindow, _id }
}

export const createWorkWindowAC = (createWork: boolean): createWork => {
    return { type: "CREATE-WORK", createWork }
}
export const createCategoryAC = (createCategory: boolean): createCategory => {
    return { type: "CREATE-CATEGORY", createCategory }
}
export const showQueueAC = (payload: queueStateType): createQueueAT => {
    return { type: "CREATE-QUEUE", payload }
}

export const showCalendarRemindersAC = (calendarReminders: boolean): showCalendarRemindersAT => {
    return { type: "SHOW-CALENDAR-REMINDERS", calendarReminders }
}
export const editClientWindowAC = (editClient: editClientWindowStateType): EditClientAT => {
    return { type: "EDIT-CLIENT-WINDOW", editClient }
}

export const showEditSessionWindowAC = (editSessonWindow: boolean): showEditSessionAT => {
    return { type: "SET-EDIT-SESSION-WINDOW", editSessionWindow: editSessonWindow }
}
export const showAdditionalWindowOptionsAC = (show: boolean): showAdditionalWindowOptionsAT => {
    return { type: "SHOW-ADDITIONAL-WINDOW", additionalWindowOptions: show }
}
export const showCallListAC = (callList: boolean): showCallListAT => {
    return { type: "SHOW-CALL-LIST", callList }
}
export const showQueueMonthAC = (queue: boolean): showQueueMonthAT => {
    return { type: "SHOW-QUEUE-MONTH", queueMonth: queue }
}
export const setAdditionalOptionsArrayAC = (el: string): setAdditionalOptionsArrayAT => {
    return { type: "SET-ADDITIONAL-OPTIONS-ARRAY", el }
}
export const deleteAdditionalOptionsArrayAC = (el: string): deleteAdditionalOptionAT => {
    return { type: "DELETE-ADDITIONAL-OPTION", el }
}
export const addTeamMateAC = (mode: boolean): addTeamMateAT => {
    return { type: "MODALS/SELECT-MODAL-WINDOW-ADD-TEAM-MATE", modeModalTeamMate: mode }

}

export const showCreateTeammateAC = (creatingTeammateWindow: boolean): showCreateTeammateAT => {
    return { type: "MODAL/SHOW-CREATE-TEAMMATE", creatingTeammateWindow }
}
export const showNewClientWindowAC = (payload: newClientType): showNewClientAT => {
    return { type: "MODAL/NEW-CLIENT", payload }
}
export const showRemoveClientWindowAC = (payload: removeClientType): showRemoveClientAT => {
    return { type: "MODAL/REMOVE-CLIENT", payload }
}
export const showTimePickerAC = (value: boolean):showTimePickerAT => {
    return {type: "MODAL/TIME-PICKER", value}
}
export const showCalendarEditionDateAC = (value:boolean):showCalendarEditionDateAT => {
    return {type: "MODAL/CALENDAR-EDITION", value}
}

export const resetModalStateAC = (): resetModalWindowsAT => {
    return { type: "MODAL/RESET-STATE" }
}
export const editCardAC = (editCard: boolean):editClCardType => {
    return {type: 'EDIT-CARD', editCard}
}

export const showPayWindowAC = (value:boolean):showPayWindowAT => {
    return {type: "MODAL/PAY-WINDOW", value}
}

export const showQrCodeWindowAC = (value: boolean):showQrCodeWindowAT => {
    return {type: "MODAL/QR-CODE-WINDOW", value}
}

export const removeWorkServiceWindowAC = (payload: removeWorkServiceType):removeWorkServiceWindowAT => {
    return {type: "MODAL/REMOVE-WORK-WINDOW", payload}
}
export const editWorkServiceWindowAC = (payload: editworkServiceType):editWorkServiceWindowAT => {
    return {type: "MODAL/EDIT-WORK-SERVICE-WINDOW",payload}
}

export const editNoteWindowAC = (payload: editNoteStateType):editNoteWindowAT => {
    return {type: "MODAL/EDIT-NOTE",payload}
}

export const clientBalanceWindowAC = (payload: clientBalanceWindowStateType): clientBalanceWindowAT => {
    return {type: "MODAL/CLIENT-BALANCE", payload}
}

export const remindWindowAC = (payload: remindWindowStateType): remindWindowAT => {
    return {type: "MODAL/REMIND-WINDOW",payload}
}

export const clientWorkWindowAC = (payload: clientWorkWindowStateType): clientWorkWindowAT => {
    return {type: "MODAL/CLIENT-WORK-WINDOW", payload}
}

export const showSearchWindowAC = (value: boolean):showSearchWindowAT => {
 return {type:"MODAL/SEARCH-WINDOW",value}
}

export const deleteWorkCategoryAC = (payload: deleteWorkCategoryStateType):deleteWorkCategoryAT => {
    return {type: "MODAL/DELETE-WORK-CATEGORY",payload}
}

export const editWorkCategoryWindowAC = (payload: editWorkCategoryStateType):editWorkCategoryAT => {
    return {type: "MODAL/EDIT-WORK-CATEGORY",payload}
}
export const queueRescheduleWindowAC = (payload: queueRescheduleStateType):queueRescheduleAT => {
    return {type :"MODAL/QUEUE-RESCHEDULE",payload}
}

export const setNewEntryFromQueueStateAC = (payload: newEntryFromQueueStateType):setNewEntryFromQueueStateAT => {
    return {type: "MODAL/SET-STATE-NEW-ENTRY-FROM-QUEUE",payload}
}

export const showadminPayWindowAC = (payload: adminPayWindowStateType):showadminPayWindowAT => {
    return {type: "MODAL/SHOW-ADMIN-PAY",payload}
}

export const setSearchTeammateWindowAC = (value: boolean):setSearchTeammateWindowAT => {
    return {type: "MODAL/SET-SEARCH-WINDOW",value}
}

export const showWaWindowAC = (payload: {value: boolean, client: null | clientsStateType, note: null | notesStateType}):showWaWindowAT => {
    return {type: "MODAL/WA-WINDOW",payload}
}

