
import { SuperButton } from "../../../styles/components/SuperButton"
import c from "../notif.module.css"

type btnsArrayType = {
    title: string;
    cb: () => void;
  };
type propsType = {
    data: btnsArrayType[]
}
export const NotifSettings = (props: propsType) => {
    return (
        <div className={c.settingWindow}>
            {
                props.data.map(el => 

                    <SuperButton className={c.settingWindowBtn} onClick={el.cb} varinat="fake">{el.title}</SuperButton>
                )
            }
        </div>
    )
}