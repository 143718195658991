import axios from "axios";
import { tokenService } from "./tokenService";
import { createNoteViewType, noteInputType } from "../apiTypes/responses/notesResponsesType";
import { Status,  notesStateType } from "../store/NotesReducer";
import { apiAuth } from "./apiAuth";
import { callListType } from "../apiTypes/root/callList.type";



const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_ENDPOINT}/api/notes`
})

instance.interceptors.request.use(
    (config) => {
        const token = tokenService.getToken()
        if(token) config!.headers!.Authorization = token
        return config
    },
    (error) => Promise.reject(error)
)

instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { config } = error;
      if (error.response.status === 401 && !config._retry) {
        config._retry = true;
        try {
          const res = await apiAuth.refreshToken();
          tokenService.saveToken(res.data.accessToken);
          instance.defaults.headers.common.Authorization =
            tokenService.getToken();
          return instance(config);
        } catch (error) {
          tokenService.removeToken();
        }
      } 
      return Promise.reject(error);
    }
  );

export const apiNotes = {
    create(data: noteInputType){
        return instance.post<createNoteViewType>("/create",data)
    },
    update(data: noteInputType,id: string){
        return instance.put<createNoteViewType>(`/update/${id}`,data)
    },
    getForWeek(userId: string, start: string, end: string){
        return instance.get<notesStateType[]>("/",{
            params: {
                userId,
                start, 
                end
            }
        })
    },
    getCallListForPeriod(userId: string, start: string,end: string){
      return instance.get<callListType[]>("/call-list",{
        params: {
          userId,
          start,
          end
        }
      })
    },
    getCallListForTeam(teamId: string,start:string, end: string){
      return instance.get<callListType[]>(`/call-list/team/${teamId}`, {
        params: {
          start,
          end
        }
      })
    },
    deleteNote(id: string){
        return instance.delete<string>(`/delete/${id}`)
    },
    getBySearchClientId(clientId: string){
        return instance.get< {last: notesStateType[], future: notesStateType[]}>(`/search-client/${clientId}`)
    },
    changeStatus(noteId: string, status: Status){
        return instance.put(`/status/${noteId}`,{status})
    },
    getForUserAndDate(date: string){
      return instance.get<Array<notesStateType>>(`/${date}`)
    }
   
}