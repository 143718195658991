import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppRootState, thunkDispatchRootType } from '../store/storeRedux'
import { useState } from 'react'
import avatarCat from '../assets/avatarCat.png'
import { SelectColor } from './SelectColor'
import { UserForm } from './UserForm'
import PhoneInput from 'react-phone-input-2'
import { FormInputContainer } from '../styles/components/FormInputContainer'
import { Title } from '../styles/components/Title'
import { TextField } from '../styles/components/TextField'
import { SuperButton } from '../styles/components/SuperButton'
import { generateWorkIdTC, stateUserType, updateUserDataTC } from '../store/UserReducer'
import { Avatar } from '../styles/components/Avatar'
import { Text } from '../styles/components/Text'
import { theme } from '../styles/components/Theme.styled'
import { setProfileReqErrorAC } from '../store/ErrorMessagesReducer'
import { FlexBox } from '../styles/components/FlexBox'
import { getLastDeviceSessionsTC } from '../store/AuthReducer'
import { Box } from '../styles/components/Box'

const selectError = (state: AppRootState) => state.errorState
const selectApp = (state: AppRootState) => state.appState
const selectUser = (state: AppRootState) => state.user
const selectAuthState = (state: AppRootState) => state.authState

export const UserProfile = () => {

    const userState = useSelector<AppRootState, stateUserType>(state => state.user)
    const appState = useSelector(selectApp)
    const user = useSelector(selectUser)
    const errorState = useSelector(selectError)
    const authState = useSelector(selectAuthState)
    const [color, setColor] = useState(userState.user?.color as string)
    const [userForm, setUserForm] = useState(false)
    const [colorWindow, setColorWindow] = useState(false)

    const [editPhone, setEditPhone] = useState<boolean>(false)
    const [editModePass, setEditModePass] = useState<boolean>(false)
    const [phoneNumber, setPhoneNumber] = useState<string>(userState.user?.phoneNumber as string)
    const [oldPassword, setOldPassword] = useState<string>('')
    const [newPassword, setNewPassword] = useState<string>('')
    const dispatch:thunkDispatchRootType = useDispatch()

    const changeData = () => {
        if (!newPassword && !oldPassword) {
            dispatch(updateUserDataTC("", "", color, phoneNumber))
        } else {
            dispatch(updateUserDataTC(oldPassword, newPassword, color, phoneNumber))

        }


    }
    const generateWorkID = (e: any) => {
        e.preventDefault()
        dispatch(generateWorkIdTC(userState.user?.id as string as string))
    }
    const cancel = () => {
        setEditModePass(false)
    }
    const editMode = (e: any) => {
        e.stopPropagation()
        if (!editModePass) setEditModePass(true)
    }

    useEffect(() => {
        const oldP = errorState.profileReqError.oldPassword
        const newP = errorState.profileReqError.newPassword
        const res = errorState.profileReqError.successMessagePass
        if (newP) {
            const timeout = setTimeout(() => {
                dispatch(setProfileReqErrorAC("", "", ""))
            }, 1500)
            return () => clearTimeout(timeout)
        }
        if (oldP) {
            const timeout = setTimeout(() => {
                dispatch(setProfileReqErrorAC("", "", ""))
            }, 1500)
            return () => clearTimeout(timeout)
        }
        if (res) {
            setEditModePass(false)
            const timeout = setTimeout(() => {
                dispatch(setProfileReqErrorAC("", "", ""))
            }, 1500)
            return () => clearTimeout(timeout)
        }
    }, [errorState.profileReqError.newPassword, errorState.profileReqError.oldPassword, errorState.profileReqError.successMessagePass])
    useEffect(() => {
        dispatch(getLastDeviceSessionsTC())
    }, [])
    return (
        <Box overFlowY='auto' direction='row' width='100%' zIndex={30} position='relative' style={{ flexWrap: "wrap" }} >
            {
                userForm && <UserForm profile={userState.user} setUserForm={setUserForm} id={userState.user?.id as string} />
            }
            {
                colorWindow && <SelectColor setColorWindow={setColorWindow} setColor={setColor} />
            }
            <FlexBox className='profileContent' direction='row' justify='center' align='start' padding='15px' gap={15} width='100%'>
                <FlexBox className='profileBox' border='1px solid #AAAAAA' borderRadius='8px' style={{ minHeight: "530px" }} direction='column' align='center' padding='10px' width='100%' height='530px' gap={10} >
                    <FlexBox className='profileAvatarBox' direction='row' align='center' padding='0 16px 0px 10px' gap={10} width='100%' height='60px' minHeight='60px' background='#8E8E8E' borderRadius='8px' onClick={() => setUserForm(true)}>
                        <Avatar size='large' src={user.user?.avatarUrl ? user.user.avatarUrl : avatarCat} alt="" />
                        <FlexBox direction='row' align='center'>
                            {user.user
                                ? <Text color='#FFFAFA' font='mont' size={18} weight={700}>{user.user.lastName} {user.user.firstName} {user.user.thirdName}</Text>
                                : <Text color='#FFFAFA' font='mont' size={18} weight={700}>Сергеев Стас Николаевич</Text>
                            }

                        </FlexBox>

                    </FlexBox>

                    <FlexBox direction='row' width='100%' gap={10}>
                        <FlexBox direction='row' align='center' padding='16px 20px' gap={10} width='100%' height='50px' border='1px solid rgba(228, 228, 228, 0.9)' borderRadius='8px'>Цвет приема в расписании</FlexBox>
                        <div onClick={(e) => {
                            e.preventDefault()
                            setColorWindow(true)
                        }} style={{ width: '50px', height: '50px', background: color, borderRadius: '8px' }}></div>
                    </FlexBox>
                    {
                        editPhone
                            ? <PhoneInput
                                inputStyle={{ height: '50px' }}
                                onlyCountries={['by', 'ru']}
                                country={'ru'}
                                value={phoneNumber}
                                onBlur={() => setEditPhone(false)}
                                onChange={(val, format, e) => { if (e.target.value) setPhoneNumber(e.target.value) }}
                            />


                            : <TextField justify='start' width='100%' as={"div"} onClick={() => setEditPhone(true)}>{userState.user?.phoneNumber as string}</TextField>

                    }

                    <TextField justify='start' width='100%' as="div">
                        <Title size={16}>{userState.user?.email as string}</Title>
                    </TextField>


                    {
                        !editModePass
                            ? <>
                                <TextField justify='start' as={"div"} width='100%' onClick={editMode}>Старый пароль</TextField>
                                <TextField justify='start' as={"div"} width='100%' onClick={editMode}>Новый пароль</TextField>
                            </>
                            : <>
                                <TextField type='password' autoComplete="new-password" justify='start' width='100%' placeholder='Старый пароль' onChange={(e) => setOldPassword(e.currentTarget.value)}></TextField>
                                <FlexBox width='100%' direction='row' align='center' justify='start'>
                                    <Text color={theme.colors.fonts.errors} font='mont' size={13}>{errorState.profileReqError.oldPassword}</Text>

                                </FlexBox>
                                <TextField autoComplete='new-password' type='password' justify='start' width='100%' placeholder='Новый пароль' onChange={(e) => setNewPassword(e.currentTarget.value)}></TextField>
                                <Text color={theme.colors.fonts.errors} font='mont' size={13}>{errorState.profileReqError.newPassword}</Text>
                            </>


                    }
                    {
                        errorState.profileReqError.successMessagePass &&
                        <Text color={"green"} font='mont' size={13}>{errorState.profileReqError.successMessagePass}</Text>
                    }

                    <FormInputContainer style={{textAlign:"start"}} justify='start'>
                        <Title size={16}>Ваш ID</Title>
                        <div >
                            <TextField justify="start" width='100%' as="div">
                                <Title size={16}>{user?.user?.workID as string}</Title>
                            </TextField>
                            <SuperButton onClick={generateWorkID} varinat="contained">Сгенерировать новый</SuperButton>
                        </div>
                    </FormInputContainer>
                    <FlexBox direction='row' gap={10} height='48px' minHeight='48px' width='100%'>
                        <SuperButton varinat="outlined" onClick={cancel}>Отменить</SuperButton>
                        <SuperButton varinat="contained" onClick={changeData}>Сохранить</SuperButton>
                    </FlexBox>
                </FlexBox>
                <FlexBox className='profileBox' border='1px solid #AAAAAA' borderRadius='8px' style={{ minHeight: "530px" }} direction='column' align='center' padding='10px' width='100%' height='530px' gap={10} >
                    <FlexBox direction='row' align='center' padding='0 16px 0px 10px' gap={10} width='100%' height='60px' minHeight='60px' background='#8E8E8E' borderRadius='8px'>
                        <FlexBox direction='row' align='center'>
                            <Text color='#FFFAFA' font='mont' size={18} weight={700}> Активные сессии</Text>
                        </FlexBox>

                    </FlexBox>
                    <FlexBox direction='column' width='100%' >
                        {
                            authState.deviceSessions.map(el => {
                                return <FlexBox direction='row' gap={3} width='100%'>
                                    <Text color={theme.colors.fonts.main} size={14} font='mont'>{new Date(el.date).toLocaleDateString()} </Text>
                                    <Text color={theme.colors.fonts.main} size={14} font='mont'>{'-'}</Text>
                                    <Text color={theme.colors.fonts.main} size={14} font='mont'>{' ip '}</Text>
                                    <Text color={theme.colors.fonts.main} size={14} font='mont'>{el.ip}</Text>
                                    <Text color={theme.colors.fonts.main} size={14} font='mont'>{'-'}</Text>
                                    <Text color={theme.colors.fonts.main} size={14} font='mont'>{el.device}</Text>
                                </FlexBox>

                            }
                            )

                        }
                    </FlexBox>
                    <FlexBox style={{ textAlign: "start" }} direction='row' width='100%' align='center'>
                        <Text color='rgba(68, 68, 68, 0.8)' size={12} weight={600} font='mont'></Text>  тут отображается информация касающаяся последних входов в систему, устройств входа.
                    </FlexBox>

                </FlexBox>
            </FlexBox>
        </Box>
    )
}