import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AppRootState } from "../store/storeRedux"
import { CalendarFreeTime } from "./CalendarFreeTime"
import { ButtonsPeriod } from "./ButtonsPeriod"
import { addNewWorkTimeAC, calendarStateType, deleteDayTC, getWorkDaysTC, selectDayAC, setCurrentPeriodAC, setEndWorkOfDayAC, setStartWorkOfDayAC, updateDate } from "../store/CalendarReducer"
import { useForm } from "react-hook-form"
import { stateSettingsType } from "../store/SettingReducer"
import { stateUserType, tariffType } from "../store/UserReducer"
import { ModalWindowStateType, showCalendarEditionDateAC, showCalendarWorkDaysAC, showModalForUpdateDayAC } from "../store/ModalWindowsReducer"
import { notesStateType } from "../store/NotesReducer"
import { clientsStateType } from "../store/ClientsReducer"
import { CustomSelect } from "../styles/components/CustomSelect"
import { NextPrevButton } from "../styles/components/NextButton"
import { FlexBox } from "../styles/components/FlexBox"
import { Text } from "../styles/components/Text"
import { theme } from "../styles/components/Theme.styled"
import { SuperButton } from "../styles/components/SuperButton"
import { Icon } from "../styles/components/Icon"
import { CheckBox } from "../styles/components/CheckBox"
import { dayFormT } from "../apiTypes/formTypes/dayFormT"
import { setSuccesMessageSettingAC } from "../store/AppReducer"
import { TableHeader } from "../styles/components/TableHeader"
import { Box } from "../styles/components/Box"
import { TableRow } from "../styles/components/TableRow"
import { getMonthLong } from "../utils/utils"
import { calendarHelper } from "../utils/calendar/calendar-helper"
import { ChangeWindow } from "./changeWindow/ChangeWindow"
import { setWorkTimeErrorAC } from "../store/ErrorMessagesReducer"

type propsType = {
    disabledActions?: boolean
}

const selectAppState = (state: AppRootState) => state.appState
const selectCommonState = (state: AppRootState) => state.commonReducerState
const selectUserProPlus = (state: AppRootState) => state.userProPlusState
const selectError = (state: AppRootState) => state.errorState

const Settings = (props: propsType) => {
    const dispatch = useDispatch()

    const appState = useSelector(selectAppState)
    const commonState = useSelector(selectCommonState)
    const errorState = useSelector(selectError)
    const userProPlusState = useSelector(selectUserProPlus)
    const userState = useSelector<AppRootState, stateUserType>(state => state.user)
    const calendarState = useSelector<AppRootState, calendarStateType>(state => state.calendarState)
    const settingState = useSelector<AppRootState, stateSettingsType>(state => state.settingState)
    const modalState = useSelector<AppRootState, ModalWindowStateType | null>(state => state.ModalWindowReducer)
    const notes = useSelector<AppRootState, notesStateType[]>(state => state.notes)
    const clients = useSelector<AppRootState, clientsStateType[]>(state => state.client)
    const [error, setError] = useState<string>('')
    const { register, handleSubmit, reset, watch } = useForm<dayFormT>()
    const [startPeriod, setStartPeriod] = useState<Date>(calendarHelper.getBeginMonth(new Date()))

    const onSubmit = (data: dayFormT) => {
        const arrayAreWork: { period: string, isWork: boolean }[] = []
        for (let key in data) {
            arrayAreWork.push({ period: key, isWork: data[key as keyof dayFormT] })
        }

        const userId =  userState.user?.id

        const trueWeek = arrayAreWork.find((el) => el.isWork)
        if (trueWeek && userId) {
            if (parseInt(calendarState.startTime) > parseInt(calendarState.endTime)) {
                setError("Время начала не может быть больше время окончания")
                return
            } else {
                const masterId = userState.user?.tariff === tariffType.SuperPRO ? commonState.selectedMaster.masterID : userState.user?.id as string
                if (window.innerWidth < 768) {
                    dispatch(addNewWorkTimeAC(arrayAreWork, calendarState.startTime, calendarState.endTime, calendarHelper.getPeriodString(startPeriod), calendarHelper.getDatesArray(startPeriod), masterId))
                } else {
                    dispatch(addNewWorkTimeAC(arrayAreWork, calendarState.startTime, calendarState.endTime, settingState.needPeriod, settingState.needMonth, masterId))
                }
            }
        }

        else {
            dispatch(setSuccesMessageSettingAC(""))
            setError('Выберите дни недели ')
            return
        }


    }






    useEffect(() => {
        return () => {
            dispatch(showCalendarWorkDaysAC(false))
        }
    }, [])



    const removeDay = (periodId: string, date: string) => {
        dispatch(deleteDayTC(periodId, date))

    }
    const nextMonth = () => setStartPeriod(calendarHelper.addMonth(startPeriod))
    const prevMonth = () => setStartPeriod(calendarHelper.subMonth(startPeriod))


    const updateDay = (date: string) => {
        dispatch(showModalForUpdateDayAC(true))
        dispatch(updateDate(date))
        dispatch(selectDayAC(date))

    }
    useEffect(() => {
        if (error) {
            setTimeout(() => { setError('') }, 3000)
        }
        if (errorState.createWorkTimeError) {
            setTimeout(() => dispatch(setWorkTimeErrorAC("")), 3000)
        }
        if (appState.successMessageSetting) {
            const timeOut = setTimeout(() => {
                dispatch(setSuccesMessageSettingAC(""))
            }, 3000)

            return () => clearTimeout(timeOut)
        }

    }, [error, appState.successMessageSetting, errorState.createWorkTimeError])
    useEffect(() => {
        dispatch(setCurrentPeriodAC(calendarHelper.getPeriodString(startPeriod)))
      if(userState.user?.tariff === tariffType.SuperPRO){
        dispatch(getWorkDaysTC(commonState.selectedMaster.masterID, calendarHelper.getPeriodString(startPeriod)))
      }  else {
        dispatch(getWorkDaysTC(userState.user?.id as string, calendarHelper.getPeriodString(startPeriod)))
      }
    }, [startPeriod, commonState.selectedMaster.masterID])
    return (
        <FlexBox direction="column" overFlow="hidden" width="100%" height="100%">
            {
                modalState?.modalForUpdateDay && !modalState.timePickerWindow &&
                <Box className="btnForChangeContainer" width="100%" position="fixed" height="100%" zIndex={28} >
                    <ChangeWindow disabledActions = {props.disabledActions}/>
                </Box>
            }
            <FlexBox className="settingHeadTitle" padding="0 0 0 35px" direction="row" width="100%">
                <Text weight={500} font="Inter" size={20} color={theme.colors.fonts.main}>График работы</Text>
            </FlexBox>

            <FlexBox className="settingHeadHeader" padding="0 40px" height="64px" align="center" direction="row" border="1px solid rgba(0, 0, 0, 0.10)" background={theme.colors.colorForm} width="100%" marginTop="17px" borderRadius="8px" justify="space-between">
                <Text className="settingTitleEmployee" color={theme.colors.fonts.secondary} font="mont" size={15}>Сотрудник</Text>
                <CustomSelect height={40} />

                <FlexBox className="settingHeaderCalendarBtn" direction="row" align="center" height="100%" width="50%" justify="space-between">

                    <SuperButton minWidth="36px" onClick={() => dispatch(showCalendarEditionDateAC(true))} varinat='calendar'>
                        <Icon width={16} height={16} svgID="calendar2" />
                    </SuperButton>

                    <FlexBox className="settingHeaderCalendarSwitch" width="220px" direction="row" background={theme.colors.fonts.secondary} height="36px" borderRadius="8px" align="center" padding="0 8px 0 15px">
                        <FlexBox justify="center" direction="row" width="70%" height="100%" align="center">
                            <Text color="#fff" font="mont" size={12}>{calendarHelper.getMonth(startPeriod)}</Text>
                        </FlexBox>
                        <FlexBox direction="row" gap={8} align="center">
                            <NextPrevButton onClick={prevMonth} type="prev" />
                            <NextPrevButton onClick={nextMonth} type="next" />
                        </FlexBox>


                    </FlexBox>
                </FlexBox>
            </FlexBox>



            <FlexBox  className="settingContentContainer" direction="row" gap={20} width="100%" height="100%" overFlow="hidden" padding="15px 0 30px 34px">
                <FlexBox  className="windowForTimeContainer" direction="column" justify="space-between" align="start" height="100%" >
                    <div className="settingCalendarFreeTime">
                        <CalendarFreeTime disabledActions = {props.disabledActions}/>
                    </div>
                    <FlexBox className="windowForTime" width="100%" as={"form"} borderRadius="16px" direction="column" justify="center" align="center" padding="10px" boxShadow={'0px 4px 8px rgba(0, 0, 0, 0.25)'} background="#fff" marginTop="16px" height="35%" onSubmit={handleSubmit(onSubmit)} >
                        <Text color={theme.colors.fonts.main} font="mont" size={15}>Массовые действия по дням недели</Text>
                        <FlexBox align="center" direction="row" padding="4px" width="100%" justify="space-between" marginTop="5px">
                            <FlexBox align="center" gap={4} direction="row">
                                <CheckBox variant="primary" render={() => <input {...register("1")} type="checkbox" />} value={watch("1")} />
                                <Text font="mont" color={theme.colors.fonts.main} size={13}>{'Пн'}</Text>
                            </FlexBox>
                            <FlexBox align="center" gap={4} direction="row">
                                <CheckBox variant="primary" render={() => <input {...register("2")} type="checkbox" />} value={watch("2")} />
                                <Text font="mont" color={theme.colors.fonts.main} size={13}>{'Вт'}</Text>
                            </FlexBox>
                            <FlexBox align="center" gap={4} direction="row">
                                <CheckBox variant="primary" render={() => <input {...register("3")} type="checkbox" />} value={watch("3")} />
                                <Text font="mont" color={theme.colors.fonts.main} size={13}>{'Ср'}</Text>
                            </FlexBox>
                            <FlexBox align="center" gap={4} direction="row">
                                <CheckBox variant="primary" render={() => <input {...register("4")} type="checkbox" />} value={watch("4")} />
                                <Text font="mont" color={theme.colors.fonts.main} size={13}>{'Чт'}</Text>
                            </FlexBox>
                            <FlexBox align="center" gap={4} direction="row">
                                <CheckBox variant="primary" render={() => <input {...register("5")} type="checkbox" />} value={watch("5")} />
                                <Text font="mont" color={theme.colors.fonts.main} size={13}>{'Пт'}</Text>
                            </FlexBox>
                            <FlexBox align="center" gap={4} direction="row">
                                <CheckBox variant="primary" render={() => <input {...register("6")} type="checkbox" />} value={watch("6")} />
                                <Text font="mont" color={theme.colors.fonts.main} size={13}>{'Сб'}</Text>
                            </FlexBox>
                            <FlexBox align="center" gap={4} direction="row">
                                <CheckBox variant="primary" render={() => <input {...register("7")} type="checkbox" />} value={watch("7")} />
                                <Text font="mont" color={theme.colors.fonts.main} size={13}>{'Вс'}</Text>
                            </FlexBox>

                        </FlexBox>

                        <ButtonsPeriod disabledActions = {props.disabledActions}/>
                        <FlexBox className="btnsPeriodBtns" direction="row" height="44px" width="100%" justify="end" marginTop="5px" align="center" >
                            <SuperButton varinat="contained" maxWidth="164px" height="36px" style={{ opacity: `${calendarState.startTime && calendarState.endTime ? 1 : 0.6}` }} disabled={(calendarState.startTime === '') || (calendarState.endTime === '')} type="submit" >Сохранить</SuperButton>
                        </FlexBox>
                        {error && <Text font="mont" color={theme.colors.fonts.errors} size={14}>{error} <span>&#10060;</span></Text>}
                        {errorState.createWorkTimeError &&
                            <FlexBox direction="row" width="285px">
                                <Text font="mont" color={theme.colors.fonts.errors} size={14}>{errorState.createWorkTimeError} <span>&#10060;</span></Text>
                            </FlexBox>}
                        {appState.successMessageSetting && <Text color="green" size={14} font="mont">{appState.successMessageSetting}<span>&#10003;</span></Text>}
                    </FlexBox>



                </FlexBox>
                <FlexBox className="settingsTableDate" direction="column" width="100%" height="100%" gap={8}>

                    <TableHeader className="settingTableHeader" justify="space-between" width="100%" type="dynamic"  >
                        <FlexBox width="100%" direction="row" padding="0 14px 0 0">
                            <FlexBox className="settingTableHeaderItem" direction="row" align="center" justify="center" width="25%">
                                <Text size={15} font="mont" color="#fff" >Дата</Text>
                            </FlexBox>
                            <FlexBox className="settingTableHeaderItem" direction="row" align="center" justify="center" width="55%">
                                <Text size={15} font="mont" color="#fff">Интервал</Text>
                            </FlexBox>
                            <FlexBox className="settingTableHeaderItem" direction="row" align="center" justify="center" width="20%">
                                <Text size={15} font="mont" color="#fff">Действие</Text>
                            </FlexBox>
                        </FlexBox>
                    </TableHeader>



                    {/* <Box height="100%" width="100%" position="relative"> */}
                    <Box className="settingDateBox" margin="0 0 0 -5px" gap={7} overFlowY="auto" width="100%" >
                        {
                            calendarState.workTimeState.datesArray.length && calendarState.workTimeState.period
                                ? calendarState.workTimeState.datesArray.map(calend =>

                                    <TableRow className="settingTableRow" minHeight="50px" key={calend.date} component="settings">
                                        <FlexBox className="tableRowItem" direction="row" width="25%" align="center" justify="center">
                                            <Text size={13} font="mont" color={theme.colors.fonts.secondary}>{getMonthLong(new Date(calend.date))}</Text>
                                        </FlexBox>
                                        <FlexBox className="tableRowItem" direction="row" width="55%" align="center" justify="center">
                                            <Text size={13} font="mont" color={theme.colors.fonts.secondary}>{calend.workTimes[0]?.start} - {calend.workTimes.at(-1)?.end}</Text>
                                        </FlexBox>
                                        <FlexBox className="tableRowItem" direction="row" width="20%" align="center" justify="center" gap={12}>
                                            <SuperButton disabled = {userState.user?.tariff === tariffType.SuperPRO && props.disabledActions } minWidth="36px" style={{ width: "36px", height: "36px" }} varinat="sort" onClick={() => updateDay(calend.date)}>
                                                <Icon svgID="edit" width={18} height={16} />
                                            </SuperButton>
                                            <SuperButton disabled = {userState.user?.tariff === tariffType.SuperPRO && props.disabledActions } minWidth="36px" varinat="sort" style={{ width: "36px", height: "36px" }} onClick={() => removeDay(calendarState.workTimeState.id, calend.date)}>
                                                <Icon svgID="delete" width={18} height={16} />
                                            </SuperButton>
                                        </FlexBox>
                                    </TableRow>

                                )
                                : <></>
                        }
                    </Box>
                    {/* </Box> */}



                </FlexBox>
            </FlexBox>



        </FlexBox>
    )
}

export default Settings