import styled from "styled-components"
import { Icon } from "./Icon"
import { useEffect, useState } from "react"
import { Box } from "./Box"
import { FlexBox } from "./FlexBox"
import { Text } from "./Text"
import { theme } from "./Theme.styled"
import { Border } from "./Border"
import { ModalWrapper } from "./ModalWrapper"
import { useDispatch, useSelector } from "react-redux"
import { showTimePickerAC } from "../../store/ModalWindowsReducer"
import { AppRootState } from "../../store/storeRedux"
import { setEndWorkOfDayAC, setStartWorkOfDayAC } from "../../store/CalendarReducer"


const calendarState = (state: AppRootState) => state.calendarState

export const TimePickers = () => {
    const dispatch = useDispatch()
    const state = useSelector(calendarState)
    const [minutesArray, setMinutesArray] = useState<Array<string>>([])
    const [hoursArray, setHoursArray] = useState<Array<string>>([])
    const [isOpen, setIsOpen] = useState(false)
    const [minutes, setMinutes] = useState("")
    const [hours, setHours] = useState("")
    const fillArrays = () => {
        if (!hoursArray.length) {
            for (let i = 0; i <= 23; i++) {
                setHoursArray(prev => ([...prev, i.toString().padStart(2, "0")]))
            }
        }
        if (!minutesArray.length) {
            for (let i = 0; i <= 59; i++) {
                setMinutesArray(prev => ([...prev, i.toString().padStart(2, "0")]))
            }
        }

    }
    const minutesHandler = (el: string) => {
        setMinutes(el)
        if(!hours){
            setHours(new Date().getHours().toString())
        }
    }
    const hoursHandler = (el: string) => {
        setHours(el)
        if(!minutes){
            setMinutes(new Date().getMinutes().toString())
        }
    }
    const reset = () => {
        setHours("")
        setMinutes("")
    }
   
    const time = hours && minutes ? `${hours}:${minutes}` : ""
    const timeHandler = () => {
        if(time){
            if(state.workWithStartTimeWindow){
                dispatch(setStartWorkOfDayAC(time))
                dispatch(showTimePickerAC(false))
            }
            if(state.workWithEndTimeWindow){
                dispatch(setEndWorkOfDayAC(time))
                dispatch(showTimePickerAC(false))

            }
        }
    }
    useEffect(() => {
        fillArrays()
    }, [])
    return <ModalWrapper>
         <Wrapper  >
        <label htmlFor="inp"></label>
        <Input onClick={() => setIsOpen(true)} placeholder="Выберите время" id="inp" type="text" value={time} />
        <IconContainer  >
            {
                time 
                ?  <Close onClick={reset} className="close">
                    <Icon svgID="close" width={20} height={20}/>
                </Close>
                : <Icon svgID="clock" width={25} height={25} /> 

            }
           
       
        </IconContainer>
        
        {
            isOpen &&
            <FlexBox direction="column">
                 <Box overFlowY="hidden" className="box" direction="column" height="237px" background="#fff" width="100%" position="relative">
                <FlexBox direction="row">
                    <Box className="box1" simpleScroll overFlowY="auto" position="absolute" width="50%" height="100%">
                        {
                            hoursArray.map(el =>
                                <FlexBox onClick={() => hoursHandler(el)} className="item" width="40px" direction="row" align="center" justify="end" height="28px" padding="5px" key={new Date().toISOString() + el}>
                                    <Text font="Inter" size={15} color={theme.colors.fonts.main}>{el}</Text>
                                </FlexBox>
                            )
                        }
                    </Box>
                    <Box className="box2" simpleScroll right="0px" overFlowY="auto" position="absolute"  width="50%" height="100%">
                        {
                            minutesArray.map(el =>
                                <FlexBox onClick={() => minutesHandler(el)} className="item" width="40px" direction="row" align="center" justify="end" height="28px" padding="5px" key={new Date().toISOString() + el}>
                                    <Text font="Inter" size={15} color={theme.colors.fonts.main}>{el}</Text>
                                </FlexBox>
                            )
                        }
                    </Box>
                </FlexBox>
                
            </Box>
            <FlexBox  className="btns" direction="row" height="40px" width="100%">
                    <ButtonBox>
                        <CancelButton onClick={() => dispatch(showTimePickerAC(false))}>Отменить</CancelButton>
                        <Button onClick={timeHandler} disabled = {!minutes && !hours}>ОК</Button>
                        
                    </ButtonBox>
                </FlexBox>
            </FlexBox>
           
        }
    </Wrapper>
    </ModalWrapper>
   
}


const Input = styled.input`
    height: 48px;
    width: 166px;
    border-radius: 8px;
    border: 1.5px solid;
    border-color: #444444;
    outline: none;
    position: relative;
    background: #fff;
    z-index: 48;
    padding-left:  20px;
    font-size: 13px;
    &::placeholder{
        text-indent: -25px;
       
    }
    
    &:hover {
        border: 1px solid;
        border-color: blue;
    };
    &:focus-visible {
        border: 1px solid;
        border-color: blue;
    }
`

const Close = styled.button`
    border-radius: 50%;
    background: #a59797cc;
    font-size: 10px;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* z-index: 56; */
    border: none;
    color: #fff;
    &:hover {
        background: #444;
        opacity: 0.8;
        cursor: pointer;
    }
    svg {
        transform: scale(0.5);
    
    position: absolute;
    }
`
const Wrapper = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    .btns {
    position: absolute;
    top: 280px;
    z-index: 56;
    background: #fff;
    };
    .box {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        top: 1px;
    }
    
    svg {
        
        transform: scale(0.7);
    };
    
    .box1{
        padding: 5px 5px 0 5px;
        overflow-y: hidden;
        overflow-x: hidden;
        border-right: 1px solid rgba(5,5,5,.06);
        &:hover {
            overflow-y: auto;
        }
       
    };
    .box2{
        padding: 5px 5px 0 5px;
        overflow-y: hidden;
        overflow-x: hidden;
        &:hover {
            overflow-y: auto;
        }
    };
    .item {
       width: 70px;
       align-items: center;
       justify-content: center;
       border-radius: 4px;
        &:hover {
            background: rgba(5,5,5,.06);
            cursor: pointer;
        }
    }
`
const ButtonBox = styled.div`
display: flex;
    align-items: center;
    padding: 0;
    justify-content: center;
    background: #fff;
    width: 100%;
    height: 100%;
    margin-top: 5px;
    border-top: 1px solid rgba(5,5,5,.06);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    gap: 14px;
;
`
const Button = styled.button`
    background: #1677ff;
    color: #fff;
   padding: 0 7px;
   height: 24px;
   border: none;
   border-radius: 4px;
   width: 44px;
   &:hover {
    background: #4096ff;
   }
`

const IconContainer = styled.div`
     display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 134px;
    top: 12px;
    z-index: 55;
    width: 56;
    height: 25px;
`
const CancelButton = styled.button`
    border: none;
    background: 0;
    height: 24px;
    width: 70px;
    &:hover {
       background:#a59797cc;
        color: #fff;
        border-radius: 4px;
    }
`

