import { addNewClientCardAC, editCardAC, editClientWindowAC, showNewClientWindowAC, showNewEntryAC } from "./ModalWindowsReducer";
import { thunkActionRootType, thunkDispatchRootType } from "./storeRedux";
import { apiClients } from "../apiServer/apiClients";
import { setIsLoadingAC } from "./AppReducer";
import { clientUpdateRequestType } from "../apiTypes/responses/clientResponsesType";
import { createNoteForNewUserType, noteInputType } from "../apiTypes/responses/notesResponsesType";
import { addNoteTC } from "./NotesReducer";
import { AxiosError, isAxiosError } from "axios";
import { logoutTC } from "./AuthReducer";
import { apiUserSuperPro } from "../apiServer/apiUserProPlus";

export type commentClientType = {
    comment: string, author: string, createdAt: string
}
export type clientsStateType = {
    userId: string;
    id: string,
    owner: string,
    photoURL: string,
    name: string,
    surname: string,
    patronymic: string,
    phone: string | null,
    gender: string,
    birthday: string | null,
    agreement: boolean,
    waAgreement: boolean,
    addedDate: string,
    comments: commentClientType[],
    balance: number,
    spentMoney: number,
    visitCount: number
}

export type clientActionsType = addClientType | removeClientType | setClientType | sortAscendingType | sortDescendingType | updateClientType | editClientAT

type addClientType = {
    userId: string,
    type: 'ADD-CLIENT',
    owner: string,
    id: string,
    photoURL: string,
    name: string,
    surname: string,
    patronymic: string,
    phone: string | null,
    gender: string,
    birthday: string | null,
    agreement: boolean,
    waAgreement: boolean,
    addedDate: string,
    comments: commentClientType[],
    spentMoney: number,
    visitCount: number
}
type setClientType = {
    type: 'SET-CLIENTS',
    state: clientsStateType[],

}

type removeClientType = {
    type: 'REMOVE-CLIENT',
    id: string
}
type updateClientType = {
    type: 'UPDATE-CLIENT',
    surname: string,
    name: string,
    patronymic: string,
    phone: string | null,
    id: string | undefined
}
type editClientAT = {
    type: "EDIT-CLIENT",
    payload: clientsStateType
}


type sortAscendingType = {
    type: 'SORT-ASCENDING'
}
type sortDescendingType = {
    type: 'SORT-DESCENDING'
}

const initState: clientsStateType[] = [

]
export const ClientReducer = (state: clientsStateType[] = initState, action: clientActionsType): clientsStateType[] => {


    switch (action.type) {
        case 'ADD-CLIENT':
            return [...state, { owner: action.owner, id: action.id, photoURL: action.photoURL, name: action.name, surname: action.surname, patronymic: action.patronymic, phone: action.phone, gender: action.gender, birthday: action.birthday, agreement: action.agreement, addedDate: action.addedDate, comments: action.comments, balance: 0,userId: action.userId, waAgreement: action.waAgreement, spentMoney: action.spentMoney, visitCount: action.visitCount }]
        case 'SET-CLIENTS':
            return action.state
        case 'REMOVE-CLIENT':
            return state.filter(st => st.id !== action.id)
        case 'UPDATE-CLIENT':
            state = state.map(cl => cl.id === action.id
                ? { ...cl, surname: action.surname, name: action.name, patronymic: action.patronymic, phone: action.phone }
                : { ...cl })
            return state
        case "EDIT-CLIENT":
            return state.map(cl => cl.id === action.payload.id ? { ...action.payload } : { ...cl })
        default:
            return state
    }
}


const addClientAC = (owner: string, id: string, photoURL: string, name: string, surname: string, patronymic: string, phone: string | null, gender: string, birthday: string | null, agreement: boolean, addedDate: string, comments: commentClientType[],userId: string, waAgreement: boolean, spentMoney: number, visitCount: number): addClientType => {
    return { type: 'ADD-CLIENT', owner, id, photoURL, name, surname, patronymic, phone, gender, birthday, agreement, addedDate, comments,userId, waAgreement,spentMoney,visitCount }
}

const removeClientAC = (id: string): removeClientType => {
    return { type: 'REMOVE-CLIENT', id }
}
export const setClientsAC = (state: clientsStateType[]): setClientType => {
    return { type: 'SET-CLIENTS', state }
}
export const updateClientAC = (id: string | undefined, surname: string, name: string, patronymic: string, phone: string | null): updateClientType => {
    return { type: 'UPDATE-CLIENT', id, surname, name, patronymic, phone }
}
export const editClientAC = (payload: clientsStateType): editClientAT => {
    return { type: "EDIT-CLIENT", payload }
}

export const addClientTC = (photo: any, name: string, surname: string, patronymic: string, phone: string | null, gender: string, birthday: Date | null, agreement: boolean, waAgreement: boolean): thunkActionRootType => async dispatch => {
    try {
        const res = await apiClients.createClient(photo, name, surname, patronymic, phone, gender, birthday, agreement, waAgreement)
        dispatch(addClientAC(res.data.owner, res.data.id, res.data.photoURL, res.data.name, res.data.surname, res.data.patronymic, res.data.phone, res.data.gender, res.data.birthday, res.data.agreement, res.data.addedDate, res.data.comments,res.data.userId, res.data.waAgreement, res.data.spentMoney, res.data.visitCount))
    } catch (error:AxiosError | any) {
        if(isAxiosError(error)) {
            if(error.response?.status === 401) {
                dispatch(logoutTC())

            }
        }
    } finally {
        dispatch(showNewClientWindowAC({ value: false, userID: "", clients: [] }))
    }
}

export const addClientAndSignTC = (photo: any, name: string, surname: string, patronymic: string, phone: string | null, gender: string, birthday: Date | null, agreement: boolean, waAgreement: boolean, noteData: createNoteForNewUserType): thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiClients.createClient(photo, name, surname, patronymic, phone, gender, birthday, agreement, waAgreement)
        const note: noteInputType = {
            clientId: res.data.id,
            clientAvatar: res.data.photoURL,
            date: noteData.date,
            time: noteData.time,
            duration: noteData.duration,
            comments: noteData.comments,
            name: res.data.name,
            surname: res.data.surname,
            phone: res.data.phone as string,
            userId: noteData.userId
        }
        dispatch(addNoteTC(note))
        dispatch(addClientAC(res.data.owner, res.data.id, res.data.photoURL, res.data.name, res.data.surname, res.data.patronymic, res.data.phone, res.data.gender, res.data.birthday, res.data.agreement, res.data.addedDate, res.data.comments,res.data.userId, res.data.waAgreement, res.data.spentMoney, res.data.visitCount))
    } catch (error:AxiosError | any) {
        if(isAxiosError(error)) {
            if(error.response?.status === 401) {
                dispatch(logoutTC())

            }
        }
    } finally {
        dispatch( addNewClientCardAC(false))
        dispatch(showNewEntryAC({value: false, fromQueue: false}))
        dispatch(setIsLoadingAC(false))
    }
}

export const getClientsTC = (): thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiClients.getClients()
        dispatch(setClientsAC(res.data))
    } catch (error:AxiosError | any) {
        if(isAxiosError(error)) {
            if(error.response?.status === 401) {
                dispatch(logoutTC())
            }
        }
    } finally {
        dispatch(setIsLoadingAC(false))
    }
}
export const removeClientTC = (id: string): thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiClients.removeClient(id)
        dispatch(removeClientAC(res.data))
    } catch (error:AxiosError | any) {
        if(isAxiosError(error)) {
            if(error.response?.status === 401) {
                dispatch(logoutTC())

            }
        }
    } finally {
        dispatch(setIsLoadingAC(false))
    }
}

export const updateClientsTC = (id: string | undefined, data: clientUpdateRequestType): thunkActionRootType => async dispatch => {
    try {
        const res = await apiClients.updateClient(id as string, data)
        dispatch(updateClientAC(res.data.clientId, res.data.surname, res.data.name, res.data.patronymic, res.data.phone))
    } catch (error:AxiosError | any) {
        if(isAxiosError(error)) {
            if(error.response?.status === 401) {
                dispatch(logoutTC())

            }
        }
    } finally {
        dispatch(editCardAC(false))
    }
}

export const getClientsForProPlus = ():thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiUserSuperPro.getClients()
        dispatch(setClientsAC(res.data))
    } catch (error) {
        
    } finally {
        dispatch(setIsLoadingAC(false))
    }
}
export const removeClientTeammateTC = (id: string):thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiUserSuperPro.removeClient(id)
        dispatch(removeClientAC(id))
    } catch (error) {
        
    } finally {
        dispatch(setIsLoadingAC(false))

    }
    
}

export const updateClientTeammateTC = (id: string, data: clientUpdateRequestType):thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiUserSuperPro.updateClients(id, data)
        dispatch(updateClientAC(id, data.surname, data.name, data.patronymic, data.phone))
        
    } catch (error) {
        
    } finally {
        dispatch(setIsLoadingAC(false))

    }
    
}



