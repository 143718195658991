import c from "./works.module.css"
import plus from "../../../assets/actions/plus.svg"
import { useDispatch, useSelector } from "react-redux"
import { JSXElementConstructor, ReactElement, ReactNode, ReactPortal, useEffect } from "react"
import { getWorkServicesTC } from "../../../store/ProsettingsReducer"
import { clientWorkWindowAC } from "../../../store/ModalWindowsReducer"
import { clientsStateType } from "../../../store/ClientsReducer"
import { getClientWorksTC } from "../../../store/ClientCardReducer"
import { AppRootState } from "../../../store/storeRedux"
import { convertNumberToTimeString } from "../../../utils/utils"
import { clientCardType } from "../../../apiTypes/root/clientPro.type"
type propsType = {
    client: clientCardType | null
}

const selectClientCard = (state:AppRootState) => state.clientCard
export const Works = (props: propsType) => {
    const dispatch = useDispatch()
    const showAddWindow = () => dispatch(clientWorkWindowAC({value: true, client: props.client}))
    const clientCard = useSelector(selectClientCard)
    useEffect(() => {
       if(props.client && props.client.id) {
        dispatch(getWorkServicesTC())
        dispatch(getClientWorksTC(props.client.id))
       } 
    }, [props.client?.id])
    return(
        <div>
            <div className={c.header}>
                <span className={c.title}>Работы - Услуги - Скидки</span>
                <img onClick={showAddWindow} className={c.plus} src={plus} alt="" />
            </div>
            
            
            
            
            <div className={c.subHeader}>
                 <p className={c.subHeaderItem}>Дата и время</p>
                 <p className={c.subHeaderItem}>Специалист</p>
                 <p className={c.subHeaderItem}>Работа Услуга</p>
                 <p className={c.subHeaderItem}>Итоговая стоимость</p>
                 <p className={c.subHeaderItem}>Скидка</p>
                 <p className={c.subHeaderItem}>Зароботок</p>
                 <p className={c.subHeaderItem}>Комментарий</p>
             </div>
             <div className={c.works}>
                 {
                    clientCard.works.map(el => 
                     <div className={c.work}>
                         <div className={c.item}>
                             <p>{new Date(el.date).toLocaleString("ru", {day: "2-digit", month: "long",year: "numeric"})}</p>
                             <p>в {el.time}</p>
                             <p>({convertNumberToTimeString(Number(el.spentTime))})</p>
                         </div>
                         <div className={c.item}>
                             {el.masterName}
                         </div>
                         <div className={c.item}>
                             {el.services.map((item) => <p>{item.name} - {item.cost}</p>)}
                         </div>
                         <div className={c.item}>
                             {el.totalCost}
                         </div>
                         <div className={c.item}>
                             {el.sale}
                         </div>
                         <div className={c.item}>
                             {el.totalProfit}
                         </div>
                         <div className={c.item}>
                             {el.comment}
                         </div>
                     </div>
                     ) 
                 }
            </div>
            </div>
        
    )
}
