
import { apiUserSuperPro } from "../apiServer/apiUserProPlus";
import { TCreateTeammateForm } from "../apiTypes/formTypes/fillModeFormTypes";
import { createNoteForNewUserType, noteInputType } from "../apiTypes/responses/notesResponsesType";
import { freeTimeForTeamType, workTimeForTeamType } from "../apiTypes/responses/workTimeResponseType";
import {  teamMateViewType,  updateTeammateRightsInputType, userProPlusStateType } from "../apiTypes/root/userProPlus.type";

import { setEnableSwitchAC, setIsLoadingAC } from "./AppReducer";
import { TimeTablePeriod } from "./CommonReducers";
import { addNewClientCardAC, editCardAC, editNoteWindowAC, showCreateTeammateAC, showNewEntryAC } from "./ModalWindowsReducer";
import {  notesStateType, Status } from "./NotesReducer";
import { thunkActionRootType, thunkDispatchRootType } from "./storeRedux";

const initState:userProPlusStateType = {
    teammates: {
        teamId: "",
        teammates: []
    },
    
    
    teammateWorkTimes: {teamId: "",teammates: [],owner: {userId: "",lastName: "",firstName: "",avatarUrl: ""}},
    teammateFreeTimes: {teamId: "",teammates: [],owner: {userId: "",lastName: "",firstName: "",avatarUrl: ""}},
    renderTimes: [],
    notesForTeam: [],
    activeDate: "",
   
} 

export  type userProPlusAT =  getTeammateAT | changeTeamMateRighAT |  getWorkTimesTeammateAT | setRenderTimesAT | getFreeTimesTeammateAT  | getNotesForWeekAT
        | addNoteForWeekAT  | setActiveDateForTimeTableAT | makeAdminTeammateAT | removeAdminTeammateAT  | changeNoteStatusForWeekAT | deleteNoteForWeekAT
        | editNoteAT
    
                                

type getTeammateAT = {
    type: "USER-PRO-PLUS/GET-TEAMMATE",
    users: teamMateViewType
}

type changeTeamMateRighAT = {
    type: "USER-PRO-PLUS/CHANGE-TEAMMATE-RIGHT",
    data: updateTeammateRightsInputType,
    userId: string
}


type getWorkTimesTeammateAT = {
    type: "USER-PRO-PLUS/GET-WORK-TIMES",
    payload: workTimeForTeamType
}
type getFreeTimesTeammateAT = {
    type: "USER-PRO-PLUS/GET-FREE-TIMES",
    payload: freeTimeForTeamType
}
type setRenderTimesAT = {
    type: "USER-PRO-PLUS/SET-RENDER-TIMES",
    payload: string[]
}


type getNotesForWeekAT = {
    type: "USER-PRO-PLUS/GET-NOTES-FOR-WEEK",
    payload: notesStateType[]
}
type addNoteForWeekAT = {
    type: "USER-PRO-PLUS/ADD-NOTES-FOR-WEEK",
    payload: notesStateType
}

type setActiveDateForTimeTableAT = {
    type: "USER-PRO-PLUS/SET-ACTIVE-DATE",
    activeDate: string
}
type makeAdminTeammateAT = {
    type: "USER-PRO-PLUS/MAKE-ADMIN",
    id: string
}
type removeAdminTeammateAT = {
    type: "USER-PRO-PLUS/REMOVE-ADMIN",
    id: string
}


type changeNoteStatusForWeekAT = {
    type: "USER-PRO-PLUS/CHANGE-NOTE-STATUS-WEEK",
    payload: {
        id: string,
        status: Status
    }
}
type deleteNoteForWeekAT = {
    type: "USER-PRO-PLUS/DELETE-NOTE",
    id: string
}
type editNoteAT = {
    type: "USER-PRO-PLUS/EDIT-NOTE",
    payload: notesStateType
}

export const UserProPlusReducer = (state: userProPlusStateType = initState, action: userProPlusAT):userProPlusStateType => {
    switch(action.type){
        case "USER-PRO-PLUS/GET-TEAMMATE":
            return {...state, teammates: {...action.users, teammates: action.users.teammates.map(t => ({...t,  user: {...t.user, avatarUrl: t.user.avatarUrl}}))} }
        case "USER-PRO-PLUS/GET-WORK-TIMES":
            return {...state,teammateWorkTimes: action.payload}
        case "USER-PRO-PLUS/SET-RENDER-TIMES":
            return {...state, renderTimes: action.payload}
        case "USER-PRO-PLUS/GET-FREE-TIMES":
            return {...state, teammateFreeTimes: action.payload}
       
       case "USER-PRO-PLUS/EDIT-NOTE":
        return {...state, notesForTeam: [...state.notesForTeam, action.payload]}
        case "USER-PRO-PLUS/GET-NOTES-FOR-WEEK":
            return {...state, notesForTeam: action.payload}
       
        case "USER-PRO-PLUS/SET-ACTIVE-DATE":
            return {...state, activeDate: action.activeDate}
        case "USER-PRO-PLUS/ADD-NOTES-FOR-WEEK":
           
            return {...state, notesForTeam: [...state.notesForTeam, action.payload]}
           
        case "USER-PRO-PLUS/CHANGE-NOTE-STATUS-WEEK":
            return {...state, notesForTeam: state.notesForTeam.map(n => n.id === action.payload.id ? ({...n,status: action.payload.status}) : n)}
        case "USER-PRO-PLUS/MAKE-ADMIN":
            return {...state, teammates: {...state.teammates, teammates:  state.teammates.teammates.map( t => t.id === action.id ? ({...t,isAdmin: true}) : t)}}
        case "USER-PRO-PLUS/REMOVE-ADMIN":
            return {...state, teammates: {...state.teammates, teammates:  state.teammates.teammates.map( t => t.id === action.id ? ({...t,isAdmin: false}) : t)}}
        case "USER-PRO-PLUS/DELETE-NOTE":
            return {...state,notesForTeam: state.notesForTeam.filter(n => n.id !== action.id)}
        default: 
            return state
    }
}

const getAllTeammatesForAdminAC = (data: teamMateViewType):getTeammateAT => {
    return {type: "USER-PRO-PLUS/GET-TEAMMATE",users: data}
}
const changeTeamMateRighAC = (data: updateTeammateRightsInputType,userId: string):changeTeamMateRighAT => {
    return {type: "USER-PRO-PLUS/CHANGE-TEAMMATE-RIGHT",data,userId}
}

const setRenderTimesForTeamAC = (payload: Array<string>):setRenderTimesAT => {
    return {type: "USER-PRO-PLUS/SET-RENDER-TIMES",payload}
}
const getWorkTimesForTeamAC = (payload: workTimeForTeamType):getWorkTimesTeammateAT => {
    return {type: "USER-PRO-PLUS/GET-WORK-TIMES",payload}
}
const getFreeTimesForTeamAC = (payload: freeTimeForTeamType):getFreeTimesTeammateAT => {
    return {type: "USER-PRO-PLUS/GET-FREE-TIMES",payload}
}

const getNotesForWeekAC = (payload: notesStateType[]):getNotesForWeekAT => {
    return {type: "USER-PRO-PLUS/GET-NOTES-FOR-WEEK",payload}
}
const addNoteForWeekAC = (payload: notesStateType):addNoteForWeekAT => {
    return {type: "USER-PRO-PLUS/ADD-NOTES-FOR-WEEK",payload}
}

const makeAdminTeammateAC = (id: string):makeAdminTeammateAT => {
    return {type: "USER-PRO-PLUS/MAKE-ADMIN",id}
}
const removeAdminTeammateAC = (id: string):removeAdminTeammateAT => {
    return {type: "USER-PRO-PLUS/REMOVE-ADMIN",id}
}

const removeNoteForWeekAC = (id: string):deleteNoteForWeekAT => {
    return {type: "USER-PRO-PLUS/DELETE-NOTE",id}
}
const editNoteAC = (payload: notesStateType):editNoteAT => {
    return {type: "USER-PRO-PLUS/EDIT-NOTE",payload}
}
export const changeNoteStatusForWeekAC = (payload: {id: string,status: Status}):changeNoteStatusForWeekAT => {
    return {type: "USER-PRO-PLUS/CHANGE-NOTE-STATUS-WEEK",payload}
}
export const setActiveDateForTimeTableAC = (activeDate: string):setActiveDateForTimeTableAT => {
    return {type: "USER-PRO-PLUS/SET-ACTIVE-DATE",activeDate}
}
export const createTeamMateTC = (data:  TCreateTeammateForm): thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiUserSuperPro.createTeammate(data)
        dispatch(showCreateTeammateAC(false))
        dispatch(getAllTeammatesForAdminTC())
    } catch (error ) {
        
    } finally {
        dispatch(setIsLoadingAC(false))
    }
}

export const getAllTeammatesForAdminTC = ():thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiUserSuperPro.getAllTeammatesForAdmin()
        dispatch(getAllTeammatesForAdminAC(res.data))
    } catch (error) {
        
    } finally {
        dispatch(setIsLoadingAC(false))
    }
}


export const changeTeamMateRighsTC = (data: updateTeammateRightsInputType, userId: string):thunkActionRootType => async dispatch => {
    dispatch(setEnableSwitchAC(false))
    try {
        const res = await apiUserSuperPro.changeTeamMateRights(data,userId)
        dispatch(changeTeamMateRighAC(data,userId))
    } catch (error) {
        
    } finally {
        dispatch(setEnableSwitchAC(true))
    }
}



export const getWorkTimesForTeamTC = (date: string):thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiUserSuperPro.getWorkTimes(date)
        dispatch(getWorkTimesForTeamAC(res.data))
    } catch (error) {
        
    }
    dispatch(setIsLoadingAC(false))
}
export const getRenderTimesForTeamTC = (date: string):thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiUserSuperPro.getRenderTime(date)
        dispatch(setRenderTimesForTeamAC(res.data))
    } catch (error) {
        
    }
}
export const getFreeTimesForTeamTC = (date: string):thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiUserSuperPro.getFreeTimes(date)
        dispatch(getFreeTimesForTeamAC(res.data))
    } catch (error) {
        
    }
    dispatch(setIsLoadingAC(false))
}



export const getTeammatesNotesForWeekTC = (masterId: string, start: string, end: string):thunkActionRootType => async dispatch =>  {
   dispatch(setIsLoadingAC(true))
    try {
            const res = await apiUserSuperPro.getNotesForWeek(masterId,start,end)
            dispatch(getNotesForWeekAC(res.data))
    } catch (error) {
        
    } finally {
        dispatch(setIsLoadingAC(false))
    }
}
export const getNoteForTeamInDayTC = (date: string):thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiUserSuperPro.getTeamNotes(date)
        dispatch(getNotesForWeekAC(res.data))
    } catch (error) {
        
    } finally {
        dispatch(setIsLoadingAC(false))
    }
}

export const createTeammateNoteTC = (data: noteInputType, userId: string, period: TimeTablePeriod, masterId: string, isOwner: boolean, date: string):thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiUserSuperPro.createNote(data, userId)
        
            dispatch(addNoteForWeekAC(res.data))
       
     
    } catch (error) {
    } finally {
        dispatch(setIsLoadingAC(false))
    }
}


export const makeAdminTeammateTC = (id: string):thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiUserSuperPro.makeAdmin(id)
        dispatch(makeAdminTeammateAC(id))
    } catch (error) {
        
    }
    finally {
        dispatch(setIsLoadingAC(false))

    }
}
export const removeAdminTeammateTC = (id: string):thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiUserSuperPro.removeAdmin(id)
        dispatch(removeAdminTeammateAC(id))
    } catch (error) {
        
    }
    finally {
        dispatch(setIsLoadingAC(false))

    }
}



export const removeNoteForWeekTeamTC = (id: string):thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiUserSuperPro.removeNoteForWeek(id)
        dispatch(removeNoteForWeekAC(id))
    } catch (error) {
        
    }
    dispatch(setIsLoadingAC(false))

}

export const editNotForTeamTC = (data: noteInputType, id: string, masterId: string):thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiUserSuperPro.editNote(data,id, masterId)
       dispatch(editNoteAC(res.data))
       dispatch(removeNoteForWeekAC(id))
       dispatch(editNoteWindowAC({value: false, note: null}))
    } catch (error) {
        
    }
    dispatch(setIsLoadingAC(false))
}




export const addNewClientAndNewEntryTC = (client: { photo: any, name: string, surname: string, patronymic: string, phone: string | null, gender: string, birthday: Date | null, agreement: boolean, waAgreement: boolean}, note: createNoteForNewUserType & {serviceId: string}): thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiUserSuperPro.creteClientAndNewEntry(client, note)
        dispatch(addNoteForWeekAC(res.data))
    } catch (error) {
        
    } finally {
        dispatch( addNewClientCardAC(false))
        dispatch(showNewEntryAC({value: false, fromQueue: false}))
        dispatch(setIsLoadingAC(false))
    }
}