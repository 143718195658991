import React from 'react';
import { createRoot } from 'react-dom/client';

import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store/storeRedux';
import { ThemeProvider } from 'styled-components';
import { theme } from './styles/components/Theme.styled';
import { GlobalStyles } from './styles/global/GlobalStyles';
import reportWebVitals from "./reportWebVitals";

// ReactDOM.render(
//   <Provider store={store}>
//     <BrowserRouter>
//       <ThemeProvider theme={theme}>
//         <App />
//         <GlobalStyles/>
//       </ThemeProvider>

//     </BrowserRouter>
//   </Provider>,

//   document.getElementById('root')
// );


const rootElement = document.getElementById("root") as HTMLElement
const root = createRoot(rootElement)
root.render( 
  <Provider store={store}>
    <BrowserRouter>
  <ThemeProvider theme={theme}/>
    <App/>
<GlobalStyles/>
  </BrowserRouter>
  </Provider>
  

)

reportWebVitals()