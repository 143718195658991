import { apiNotes } from "../apiServer/apiNote";
import { callListType } from "../apiTypes/root/callList.type";
import { setIsLoadingAC } from "./AppReducer";
import { thunkActionRootType } from "./storeRedux";


export type callListAT = getForPeriodAT | removeitemAT

type getForPeriodAT = {
    type: "CALL-LIST/GET-FOR-PERIOD",
    payload: callListType[]
}
type removeitemAT = {
    type: "CALL-LIST/REMOVE-ITEM",
    noteId: string
}

export const CallListReducer = (state: callListType[] = [],action:callListAT):callListType[] => {
    switch(action.type){
        case "CALL-LIST/GET-FOR-PERIOD":
            return action.payload
        case "CALL-LIST/REMOVE-ITEM":
            return state.filter(c => c.noteId !== action.noteId)
        default: 
         return state
    }
}

const getCallListForPeriodAC = (payload: callListType[]):getForPeriodAT => {
    return {type: "CALL-LIST/GET-FOR-PERIOD",payload}
}

export const deleteItemFromCallListAC = (noteId: string):removeitemAT => ({type: "CALL-LIST/REMOVE-ITEM", noteId})
export const getCallListForPeriodTC = (userId: string, start: string,end: string): thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiNotes.getCallListForPeriod(userId,start,end)
        dispatch(getCallListForPeriodAC(res.data))
    } catch (error) {
        
    } finally{
        dispatch(setIsLoadingAC(false))
    }
}
export const getCallListForTeamTC = (teamId: string, start: string,end: string): thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiNotes.getCallListForTeam(teamId,start,end)
        dispatch(getCallListForPeriodAC(res.data))
    } catch (error) {
        
    } finally{
        dispatch(setIsLoadingAC(false))
    }
}