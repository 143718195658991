
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AppRootState } from "../../store/storeRedux"
import { calendarStateType, updateDaysTC, workWithEndTimeWindowAC, workWithStartTimeWindowAC } from "../../store/CalendarReducer"
import { stateUserType, tariffType } from "../../store/UserReducer"
import { showModalForUpdateDayAC, showTimePickerAC } from "../../store/ModalWindowsReducer"
import { Icon } from "../../styles/components/Icon"
import { FlexBox } from "../../styles/components/FlexBox"
import { theme } from "../../styles/components/Theme.styled"
import {Text} from "../../styles/components/Text"
import { SuperButton } from "../../styles/components/SuperButton"
import { workDayUpdateType } from "../../apiTypes/responses/workTimeResponseType"
import { setUpdateWorkTimeErrorAC } from "../../store/ErrorMessagesReducer"

const selectError = (state:AppRootState) => state.errorState
const selectCalendar = (state:AppRootState) => state.calendarState

type propsType = {
    disabledActions?: boolean
}

export const ButtonsForChange = (props: propsType) => {
    const dispatch = useDispatch()
    const [error, setError] = useState<string>("")
    const errorState = useSelector(selectError)
    const calendarState = useSelector(selectCalendar)
    const user = useSelector<AppRootState, stateUserType>(state => state.user)
    const actionState = useSelector<AppRootState, calendarStateType>(state => state.calendarState)
    const updateDays = () => {
        const period = new Date(actionState.updatedDate).toLocaleDateString().split(".").slice(1).join(".")
        if (parseInt(actionState.startTime) > parseInt(actionState.endTime)) {
            setError("Время начала не может быть больше время окончания")
        } else {
            const data:workDayUpdateType = {
                period,
                date: new Date(actionState.updatedDate),
                startWorkTime: actionState.startTime,
                endWorkTime: actionState.endTime
            }
            dispatch(updateDaysTC(user.user?.id as string, data,calendarState.currentPeriod))
            // dispatch(showModalForUpdateDayAC(false))
        }
    }
    const divRefStart = useRef(null)
    const divRefEnd = useRef(null)
    const setPicker = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if(user.user?.tariff === tariffType.SuperPRO){
          if(props.disabledActions) return
          dispatch(showTimePickerAC(true))
          defineNeedWindow(e)
          return
        } else {
          dispatch(showTimePickerAC(true))
          defineNeedWindow(e)
        }
          
      }
    const defineNeedWindow = (e: any) => {
        //@ts-ignore
        if (divRefStart.current.contains(e.target)) {
            dispatch(workWithStartTimeWindowAC(true))
            dispatch(workWithEndTimeWindowAC(false))
        }
        //@ts-ignore
        else if (divRefEnd.current.contains(e.target)) {
            dispatch(workWithEndTimeWindowAC(true))
            dispatch(workWithStartTimeWindowAC(false))
        }
    }
    const start = actionState.startTime ? actionState.startTime : "Начало"
    const end = actionState.endTime ? actionState.endTime : "Конец"

    useEffect(() => {
        if (error) {
            setTimeout(() => { setError('') }, 3000)
        }
        if (errorState.updateWorkTimeError) {
            setTimeout(() => { dispatch(setUpdateWorkTimeErrorAC('')) }, 3000)
        }

    }, [error,errorState.updateWorkTimeError])
    return (
        <>
            <FlexBox direction="row" width="100%" gap={5} height="60px" padding="5px" marginTop="5px" >
                <FlexBox borderRadius="8px" padding="5px 5px 5px 28px" direction="row" align="center" justify="space-between" basis="100%" background="#fff" border="1px solid rgba(68, 68, 68, 0.4)" ref={divRefStart} onClick={(e) => {
                    setPicker(e)
                }} >
                    <Text font="mont" color={theme.colors.fonts.secondary} size={18}>{start}</Text>
                    <Icon svgID="row" width={8} height={4} />

                </FlexBox>
                <FlexBox borderRadius="8px" padding="5px 5px 5px 28px" direction="row" align="center" justify="space-between" basis="100%" background="#fff" border="1px solid rgba(68, 68, 68, 0.4)" ref={divRefEnd} onClick={(e) => {
                   setPicker(e)
                }}>
                    <Text font="mont" color={theme.colors.fonts.secondary} size={18}>{end}</Text>
                    <Icon svgID="row" width={8} height={4} />
                </FlexBox>

            </FlexBox>
            <FlexBox direction="row" height="70px" width="100%" justify="end" marginTop="5px" align="center">
                <SuperButton varinat="contained" maxWidth="164px" height="48px" style={{ opacity: `${actionState.startTime && actionState.endTime ? 1 : 0.6}` }}  disabled={!actionState.startTime || !actionState.endTime} onClick={updateDays}>Сохранить</SuperButton>
            </FlexBox>
            {error && <Text font="mont" color={theme.colors.fonts.errors} size={12}>{error} <span>&#10060;</span></Text>}
            {errorState.updateWorkTimeError && <Text font="mont" color={theme.colors.fonts.errors} size={12}>{errorState.updateWorkTimeError} <span>&#10060;</span></Text>}

        </>

    )
}