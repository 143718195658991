import { useId, useState } from "react";
import { FlexBox } from "../../../styles/components/FlexBox";
import { SuperButton } from "../../../styles/components/SuperButton";
import { theme } from "../../../styles/components/Theme.styled";
import { WaTimeTablePatterns } from "./Wa-TimeTable-Pattens";
import { WaTimeTableSending } from "./Wa-TimeTable-sending";
import { Text } from "../../../styles/components/Text";

enum Component {
  SENDINGS = "SENDINGS",
  PATTERNS = "PATTERNS",
}

export const WaTimeTable = () => {
 
 
  return (
    <FlexBox direction="column" width="100%" height="100%">
      <FlexBox
        width="100%"
        height="48px"
        direction="row"
        align="center"
        justify="start"
        gap={30}
        background={theme.colors.colorForm}
        padding="0 20px"
      >
       
        <Text size={16} font="mont" color={theme.colors.fonts.main}>Шаблоны</Text>
      </FlexBox>
        <FlexBox direction="row" height="100%">
           <WaTimeTablePatterns/>
        </FlexBox>
    </FlexBox>
  );
};
