import axios from "axios";
import { tokenService } from "./tokenService";
import { tarifHistoryItemsType } from "../apiTypes/root/tarifHistory.type";
import { apiAuth } from "./apiAuth";
import { clientRemindersViewForNotifType } from "../apiTypes/root/reminders.type";
import { addWaSendingTimeTableFT } from "../apiTypes/formTypes/wa.types";


const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_ENDPOINT}/api/user-pro`
})

instance.interceptors.request.use(
    (config) => {
        const token = tokenService.getToken()
        if(token)  config!.headers!.Authorization = token
        return config
    },
    (error) => Promise.reject(error)
    
)

instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { config } = error;
      if (error.response.status === 401 && !config._retry) {
        config._retry = true;
        try {
          const res = await apiAuth.refreshToken();
          tokenService.saveToken(res.data.accessToken);
          instance.defaults.headers.common.Authorization =
            tokenService.getToken();
          return instance(config);
        } catch (error) {
          tokenService.removeToken();
        }
      } else {
        // tokenService.removeToken();
      }
      return Promise.reject(error);
    }
  );

export const apiUserPro = {
    getTarifHistory(){
        return instance.get<tarifHistoryItemsType[]>(`/tarif-history`)
    },
    getPayHistory(){
        return instance.get("/pay-history")
    },
    getFirstFreeDate(){
      return instance.get("/first-free-date")
    },
    getClReminderForNotif(){
      return instance.get<clientRemindersViewForNotifType[]>("/notifications")
    },
    deactivateClientReminder(id: string){
      return instance.put(`notifications/deactivate/${id}`)
    },
    createWaPatternTimeTable(data: addWaSendingTimeTableFT){
      return instance.post("/WA/pattern",data)
    },
    deleteWaPatternTimeTable(id: string){
      return instance.delete(`/WA/pattern/${id}`)
    },
    editWaPatternTimeTable(id: string, data: addWaSendingTimeTableFT){
      return instance.put(`/WA/pattern/${id}`, data)
    },
    getWaPatternTimeTable(){
      return instance.get(`/WA/pattern/`)
    },

}