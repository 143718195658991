import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { AppRootState, thunkDispatchRootType } from '../store/storeRedux';
import { clientsStateType, getClientsForProPlus, getClientsTC, setClientsAC } from '../store/ClientsReducer';
import { Sort } from './Sort';
import { searchStateType } from "../store/SearchReducer";
import { getNotesTC, notesStateType } from "../store/NotesReducer";
import { stateUserType, tariffType } from "../store/UserReducer";
import { EditCard } from "./EditCard";
import { ModalWindowStateType, editCardAC, selectClientAC, setDateAndTimeAC, showNewClientWindowAC, showNewEntryAC, showRemoveClientWindowAC } from "../store/ModalWindowsReducer";
import { sessionsType } from "../store/SessionsReducer";
import { NavLink } from "react-router-dom";
import { Icon } from "../styles/components/Icon";
import { Avatar } from "../styles/components/Avatar";
import { FlexBox } from "../styles/components/FlexBox";
import { Text } from "../styles/components/Text";
import { theme } from "../styles/components/Theme.styled";
import { SuperButton } from "../styles/components/SuperButton";
import { TextField } from "../styles/components/TextField";
import { Box } from "../styles/components/Box";
import { TableHeader } from "../styles/components/TableHeader";
import { Column } from "../styles/components/Column";
import { TableRow } from "../styles/components/TableRow";
import { set, subDays } from "date-fns";
import { DeviceView } from "../store/AppReducer";
import { SortForProPlus } from "./SortForProPlus";
import { selectedMasterType, selectMasterAC } from "../store/CommonReducers";
import { SelectForFilterClients } from "./SelectForFilterClients";
type dataType = {
    userId: string | null,
    disabledActions: boolean
}

const selectAppState = (state: AppRootState) => state.appState
const selectModal = (state: AppRootState) => state.ModalWindowReducer
const selectProPlus = (state:AppRootState) => state.userProPlusState
const selectClients = (state:AppRootState) => state.client
const MainPageComponent = (props: dataType) => {
    const dispatch:thunkDispatchRootType = useDispatch()
    const appState = useSelector(selectAppState)
    const userProPlus = useSelector(selectProPlus)
    const client = useSelector(selectClients)
    const [sort, setSort] = useState<boolean>(false)
    const [newClientCount, setNewClientCount] = useState<number>(0)
    const modalState = useSelector(selectModal)
    const [selectedMaster, setSelectedMaster] = useState<selectedMasterType | 'all'>('all')
    const user = useSelector<AppRootState, stateUserType>(state => state.user)
    const [clients, setClients] = useState<clientsStateType[]>(client)
    const [editedClient, setEditedClient] = useState<clientsStateType | undefined>()
    const formatter = new Intl.NumberFormat("ru", {
        style: "currency",
        currency: "rur",
      });
    const removeClient = (clientsId: string) => {
        dispatch(showRemoveClientWindowAC({ value: true, clientID: clientsId }))
    }

    const showNewClientWindow = () => {
        const setOwner = ():string => {
            if(props.disabledActions) {
                return props.userId as string
            }  else if(selectedMaster === 'all') {
                return props.userId as string
            } else {
                return selectedMaster.masterID
            }
             
             
        }
        setOwner()
     dispatch(showNewClientWindowAC({ value: true, clients, userID: setOwner() }))

    }
   

    const searchClient = (e: ChangeEvent<HTMLInputElement>) => {
        const val = e.currentTarget.value
        const filter1 = (cl: clientsStateType) => `(${cl.name} ${cl.surname})`.toUpperCase().trim().includes(val.toUpperCase())
        const filter2 = (cl: clientsStateType) => `(${cl.surname} ${cl.name})`.toUpperCase().trim().includes(val.toUpperCase())
        const filter3 = (cl: clientsStateType) => cl.phone?.replaceAll("-","").replaceAll("+","").replaceAll("(","").replaceAll(" ","").replaceAll(")","").includes(val.replaceAll("-","").replaceAll("+","").replaceAll(")","").replaceAll(" ","").replaceAll("(",""))
        const copyClients = [...client]
        const searchClients:clientsStateType[] = copyClients.filter(cl => filter1(cl) || filter2(cl) || filter3(cl))
        setClients(searchClients)
    }



    const showNewEntry = (cl: clientsStateType) => {
        const selectedUser = userProPlus.teammates.teammates.find(t => t.user.userId === cl.userId)
        if(selectedUser){
            const {userId, firstName, avatarUrl} = selectedUser.user
            dispatch(selectMasterAC({masterID: userId,masterName: firstName, avatarUrl}))
        } else {
            dispatch(selectMasterAC({masterID: user.user?.id as string,masterName: user.user?.firstName as string, avatarUrl: user.user?.avatarUrl as string}))

        }
        dispatch(setDateAndTimeAC('', '', ''))
        dispatch(showNewEntryAC({value: true, fromQueue: false}))
        dispatch(selectClientAC(cl))
    }

    

    const editHandler = (cl: clientsStateType) => {
        dispatch(editCardAC(true))
        setEditedClient(cl)
        
    }
    // const clientCount = clients.length
    const clientCount = client.length

    useEffect(() => {
        dispatch(getClientsForProPlus())
    }, [client.length])
    useEffect(() => {
      if(client.length)  setClients(client)  
    }, [client.length])
    useEffect(() => {
        
        
        const dateCompare = subDays(new Date(), 14)
        let counter = 0
        
        //    clients.forEach(val => {
        //     //@ts-ignore
        //     if (val.createdAt && new Date(val.createdAt) > dateCompare) {
        //         counter++
        //     }
        //    }) 
        
        client.forEach(cl => {
            //@ts-ignore
            if (cl.createdAt && new Date(cl.createdAt) > dateCompare) {
                counter++
            
        } 
     } )
        setNewClientCount(counter)
    }, [JSON.stringify(client), client.length])
    useEffect(() => {
        if(selectedMaster === 'all') {
            setClients(client)
        } else {
            const data = client.filter(val => val.userId === selectedMaster.masterID)
            setClients(data)
        }
    },[selectedMaster])
    return (
        <FlexBox direction="column" height="100%" width="100%">
            <div style={{ position: "relative" }}>
                {sort && <SortForProPlus clients={clients} setClients={setClients} sort={sort} setSort={setSort} />}

            </div>

            <FlexBox direction="column" height="100%" width="100%">
                <Text className="mainPageTitle" size={18} color={theme.colors.fonts.main} font="mont">Клиенты</Text>
                <FlexBox direction="row" background={theme.colors.colorForm} width="100%" marginTop="12px" padding="12px" justify="space-between">
                    <FlexBox className="clientStatsContainer" direction="row" height="100%" align="center" gap={8}>
                        <FlexBox className="clientStats" width="120px" height="100%" direction="row" background="#fff" borderRadius="8px" align="center" justify="center">Всего: {clientCount}</FlexBox>
                        <FlexBox className="clientStats" width="120px" height="100%" direction="row" background="#fff" borderRadius="8px" align="center" justify="center">Новых: {newClientCount}</FlexBox>
                        <SuperButton className="sort" active={sort ? true : false} varinat="sort" onClick={(e) => setSort(prev => !prev)}>
                            <Icon svgID="sort" width={16} height={16} />
                        </SuperButton>
                        <Box className="clientSearch" width="max-content" component="search" position="relative">
                            <Icon svgID="search" width={13} height={12} />
                            <TextField width="283px" height="44px" kind="searchClient" onChange={searchClient} placeholder='Найти клиентов' type="text" />
                        </Box>
                    </FlexBox>

                    <SelectForFilterClients selectedMaster={selectedMaster} teammates={userProPlus.teammates.teammates} setSelectedMaster={setSelectedMaster}/>
                    <SuperButton className="newClientBtn" minWidth="200px" maxWidth="160px" onClick={() => showNewClientWindow()} varinat="add">
                        <Icon width={20} height={13} svgID="blMan" />
                        <span>Новый клиент</span>
                    </SuperButton>
                </FlexBox>
                <FlexBox direction="column" width="100%" height="100%" gap={16}>
                    <TableHeader className="clientTableHeader" width="100%" type="dynamic" offset="medium">
                        <Column maxWidth="100%" axis="column" component="TimeTable">
                            <Text size={13} color="#fff" font="mont">ФИО</Text>
                        </Column>
                        <Column maxWidth="100%" axis="column" component="TimeTable">
                            <Text size={13} color="#fff" font="mont">Телефон</Text>
                        </Column>
                        <Column maxWidth="100%" axis="column" component="TimeTable">
                            <Text size={13} color="#fff" font="mont">Визитов</Text>
                        </Column>
                        <Column maxWidth="100%" axis="column" component="TimeTable">
                            <Text size={13} color="#fff" font="mont">Добавлен</Text>
                        </Column>
                        <Column className="payment" maxWidth="100%" axis="column" component="TimeTable">
                            <Text size={13} color="#fff" font="mont">Оплачено</Text>
                        </Column>
                        <Column maxWidth="100%" axis="column" component="TimeTable">
                            <Text size={13} color="#fff" font="mont">Баланс</Text>
                        </Column>
                        <Column maxWidth="100%" axis="column" component="TimeTable">
                            <Text size={13} color="#fff" font="mont">Действие</Text>
                        </Column>
                        <Column maxWidth="100%" axis="column" component="TimeTable">
                            <Text size={13} color="#fff" font="mont"></Text>
                        </Column>
                    </TableHeader>
                    <Box height="100%" width="100%" position="relative">
                        {modalState.editCard && <EditCard setClients={setClients} editedClient={editedClient} />}
                        <Box gap={12} overFlowY="auto" position="absolute" width="100%" height="100%">
                            {
                                
                                    
                                    
                                        clients.map((cl,i) => 
                                            <TableRow minHeight="64px" key={cl.id || "cl.id" } component="Clients">
                                            <FlexBox gap={5} direction="row" align="center" justify="center" basis="100%">
                                                <FlexBox direction="row" basis="50%" align="center" justify="center">
                                                    {
                                                        ((user.user && user.user.tariff === "PRO") || (user.user && user.user.tariff === "SuperPRO"))
                                                            //&& user.user.balance && user.user.balance > 0
                                                            ? <NavLink  to={`/client/${cl.id}`}>
                                                                <Avatar size="newEntry" src={cl.photoURL} /> 
                                                            </NavLink>
                                                            : <>
                                                                <Avatar size="newEntry" src={cl.photoURL} />
                                                            </>
                                                    }
                                                </FlexBox>
                                                <FlexBox align="start" width="100%" direction="column">
                                                    <Text size={12} font="mont" color={theme.colors.fonts.main}>{cl.surname}</Text>
                                                    <Text size={12} font="mont" color={theme.colors.fonts.main}>{cl.name}</Text>
                                                    <Text size={12} font="mont" color={theme.colors.fonts.main}>{cl.patronymic}</Text>
                                                </FlexBox>
                                            </FlexBox>
                                            <FlexBox direction="row" align="center" justify="center" basis="100%">
                                                <Text size={12} font="mont" color={theme.colors.fonts.main}>{cl.phone}</Text>
                                            </FlexBox>
                                            <FlexBox direction="row" align="center" justify="center" basis="100%">
                                                <Text size={12} font="mont" color={theme.colors.fonts.main}>{cl.visitCount}</Text>
                                            </FlexBox>
                                            <FlexBox direction="row" align="center" justify="center" basis="100%">
                                                
                                                <Text size={12} font="mont" color={theme.colors.fonts.main}>{
                                                (new Date(cl.addedDate).toLocaleDateString())}
                                                </Text>
                                            </FlexBox>
                                            <FlexBox className="payment" direction="row" align="center" justify="center" basis="100%">
                                                <Text size={12} font="mont" color={theme.colors.fonts.main}> {formatter.format(cl.spentMoney)}</Text>
                                            </FlexBox>
                                            <FlexBox direction="row" align="center" justify="center" basis="100%">
                                                <Text size={12} font="mont" color={theme.colors.fonts.main}>{formatter.format(cl.balance)}</Text>
                                            </FlexBox>
                                            <FlexBox direction="row" align="center" justify="center" basis="100%">
                                                <SuperButton disabled = {props.disabledActions && cl.userId !== props.userId} onClick={() => showNewEntry(cl)} varinat="secondary">Записать</SuperButton>
                                            </FlexBox>
                                            <FlexBox direction="row" align="center" justify="center" basis="100%" gap={8}>
                                                <SuperButton disabled = {props.disabledActions && cl.userId !== props.userId} varinat="sort" onClick={(e) => editHandler(cl)}>
                                                    <Icon svgID="edit" width={18} height={16} />
                                                </SuperButton>
                                                <SuperButton disabled = {props.disabledActions && cl.userId !== props.userId} varinat="sort" onClick={(e) => removeClient(cl.id)}>
                                                    <Icon svgID="delete" width={18} height={16} />
                                                </SuperButton>
                                            </FlexBox>
                                        </TableRow>
                                        )
                                    }
                                       
                                        {/* {
                                            appState.deviceView === DeviceView.MOBILE &&
                                            <FlexBox  height="64px" padding="5px" gap={10} background={theme.colors.colorForm} marginTop="5px" direction="row" className="mobileClientRow">
                                                {
                                                    ((user.user && user.user.tariff === "PRO") || (user.user && user.user.tariff === "SuperPRO"))
                                                    ? <NavLink to={`/client/${cl.id}`}>
                                                        <Avatar size="large" src={cl.photoURL} />
                                                    </NavLink>
                                                    : <Avatar size="large" src={cl.photoURL} />
                                                }
                                                
                                                <FlexBox direction="column" gap={3}>
                                                    <Text font="mont" color={theme.colors.fonts.main} size={12}>{`${cl.surname} ${cl.name} ${cl.patronymic}`}</Text>
                                                    <Text font="mont" color={theme.colors.fonts.main} size={12}>{cl.phone}</Text>
                                                    <Text font="mont" color={theme.colors.fonts.main} size={12}>Баланс: ****</Text>
                                                </FlexBox>
                                                <FlexBox style={{ marginLeft: "auto" }} direction="row" align="center" justify="center" gap={5}>
                                                    <SuperButton varinat="sort" onClick={(e) => editHandler(cl)}>
                                                        <Icon svgID="edit" width={18} height={16} />
                                                    </SuperButton>
                                                    <SuperButton varinat="sort" onClick={(e) => removeClient(cl.id)}>
                                                        <Icon svgID="delete" width={18} height={16} />
                                                    </SuperButton>
                                                </FlexBox>
                                            </FlexBox>
                                        } */}
                                    

                                
                            
                        </Box>
                    </Box>

                </FlexBox>
            </FlexBox>
        </FlexBox>
    )
}

export const ClientsForProPlus = React.memo(MainPageComponent)