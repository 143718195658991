import { useForm } from "react-hook-form"
import { Border } from "../styles/components/Border"
import { FlexBox } from "../styles/components/FlexBox"
import { SuperButton } from "../styles/components/SuperButton"
import { Text } from "../styles/components/Text"
import { TextField } from "../styles/components/TextField"
import { theme } from "../styles/components/Theme.styled"
import { TcompleteRegister } from "../apiTypes/formTypes/completeRegisterType"
import PhoneInput from "react-phone-input-2"
import { useDispatch, useSelector } from "react-redux"
import { AppRootState, thunkDispatchRootType } from "../store/storeRedux"
import { useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { setRegisterLoginErrorAC, setRegisterPhoneErrorAC } from "../store/ErrorMessagesReducer"
import { completeRegisterTC } from "../store/AuthReducer"


const selectRegister = (state: AppRootState) => state.authState
const selectUser = (state: AppRootState) => state.user
const selectError = (state: AppRootState) => state.errorState

export const CompleteRegister = () => {
    const registerState = useSelector(selectRegister)
    const userState = useSelector(selectUser)
    const errorState = useSelector(selectError)
    const navigate = useNavigate()
    const dispatch:thunkDispatchRootType = useDispatch()
    const { register, handleSubmit, watch, setValue, getValues } = useForm<TcompleteRegister>()
    const saveData = (data: TcompleteRegister) => {
            dispatch(completeRegisterTC(data, registerState.userId))
    }
    useEffect(() => {
        const sub  = watch((value, {name, type}) => {
            if(value && name === "phoneNumber"){
                dispatch(setRegisterPhoneErrorAC(""))
            }
            if(value && name === "login"){
                dispatch(setRegisterLoginErrorAC(""))
            }
        })
        return () => sub.unsubscribe()
    },[watch])
    
    return (
        <FlexBox direction="row" justify="center" align="center" height="100%" width="100%">
            <FlexBox padding="20px" borderRadius="6px" align="center" justify="center"  width="60%" background="#f8f8f8" direction="row" gap={24}>
                <FlexBox width="100%" direction="column" gap={32}>
                    <FlexBox as={"ul"} align="start" width="100%" direction="column">
                        <Text as={"li"} size={18} color={theme.colors.fonts.main} font="mont">
                            Продолжая регистрацию вы даете согласие на хранение и обработку персональных данных:
                        </Text>
                        <Text weight={700} as={"li"} style={{ listStyle: "inside", paddingLeft: "10px" }} size={18} color={theme.colors.fonts.main} font="mont"> Фамилия, Имя, Отчество</Text>
                        <Text weight={700} as={"li"} style={{ listStyle: "inside", paddingLeft: "10px" }} size={18} color={theme.colors.fonts.main} font="mont">Телефон</Text>
                        <Text weight={700} as={"li"} style={{ listStyle: "inside", paddingLeft: "10px" }} size={18} color={theme.colors.fonts.main} font="mont"> E-mail </Text>

                    </FlexBox>
                    <FlexBox gap={8} align="start" direction="column">
                        <Text size={16} color={theme.colors.fonts.main} font="mont">Зачем это нужно? </Text>
                        <Text size={12} color={theme.colors.fonts.main} font="mont">
                            Процесс идентификации в системе - ввод логина и пароля, Мы гарантируем сохранность любых вводимых и передаваемых данных на наш сервер. Все данные используются в Ваших личных целях, для получения рассылки новостей или авторизации
                        </Text>
                    </FlexBox>
                </FlexBox>

                <Border width={"100%"} vertical height={0} borderColor="#c4c4c4" />


                <FlexBox as={"form"} onSubmit={handleSubmit(saveData)} gap={10} justify="space-between" height="100%" width="100%" direction="column">
                    <FlexBox direction="column" align="start">
                        <Text color={theme.colors.fonts.main} font="mont" size={14}>Фамилия</Text>
                        <TextField   {...register("lastName")} placeholder="Фамилия" width="100%" />
                    </FlexBox>
                    <FlexBox direction="column" align="start">
                        <Text color={theme.colors.fonts.main} font="mont" size={14}>Имя</Text>
                        <TextField {...register("firstName")} placeholder="Имя" width="100%" />
                    </FlexBox>
                    <FlexBox direction="column" align="start">
                        <Text color={theme.colors.fonts.main} font="mont" size={14}>Отчество</Text>
                        <TextField {...register("thirdName")} placeholder="Отчество" width="100%" />
                    </FlexBox>
                    <FlexBox direction="column" align="start">
                        <Text color={theme.colors.fonts.main} font="mont" size={14}>Телефон</Text>
                        <PhoneInput
                            value={getValues("phoneNumber")}
                            onlyCountries={['by', 'ru']}
                            country={'ru'}
                            onChange={(val, format, e) => setValue("phoneNumber",e.target.value)}
                            inputStyle={{

                                height: '50px'
                            }}

                        />
                        {errorState.registerPhone && <Text color={theme.colors.fonts.errors} size={14} font="mont">{errorState.registerPhone}</Text>}
                    </FlexBox>
                    <FlexBox direction="column" align="start">
                        <Text color={theme.colors.fonts.main} font="mont" size={14}>Должность</Text>
                        <TextField {...register("position")} placeholder="Должность" width="100%" />

                    </FlexBox>
                    <FlexBox direction="column" align="start">
                        <Text color={theme.colors.fonts.main} font="mont" size={14}>Логин</Text>
                        <TextField {...register("login")} placeholder="Логин" width="100%" />
                        {errorState.registerLogin && <Text color={theme.colors.fonts.errors} size={14} font="mont">{errorState.registerLogin}</Text>}

                    </FlexBox>
                    <SuperButton type="submit" hover hoverColor={theme.colors.fonts.main} varinat="contained" height="48px">Сохранить</SuperButton>
                </FlexBox>
            </FlexBox>
        </FlexBox>

    )
}