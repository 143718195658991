import { css } from "styled-components";
import { styled } from "styled-components";
import { theme } from "./Theme.styled";

type propsType = {
    varinat: "contained" | "add" | "outlined" | "fake" | "removeNote" | "counter" |  "tariff" | "changeTariff" | "sort"
    | "secondary" | "btn" | "photo" | "calendar" | "time" | "moreOptions" | "close" | "timeTableMobileDate" | "notification"
    | "settingTariff" | "search" | "selector" | "outlined-secondary" | "month-queue"
    height?: string,
    active?: boolean,
    hover?: boolean,
    hoverColor?:string,
    minWidth?:string,
    maxWidth?:string,
    isAllowed?: boolean,
    disabled?:boolean,
    component?:"timeTable"
}
export const SuperButton = styled.button<propsType>`

      &:disabled {
        opacity: 0.6;
      };  
        
    width: 100%;
    height: ${props => props.height ? props.height : "100%"};
    font-size: 12px;
    font-family: ${theme.fonts.primary};
    border-radius: ${theme.radius.medium};
    ${props => props.varinat === "contained" && css<propsType>`
        border: none;
        background: ${theme.colors.main};
        color: #fff;
    `};
    ${props => props.varinat === "selector" && css<propsType>`
        background: rgba(255, 255, 255, 0.29);
        border: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    `}
    ${props => props.varinat === "month-queue" && css<propsType>`
        width: 100%;
        height: 100%;
        background: #fff;
        border: none;
        color: #262626;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 11px;
        ${props => props.active && css<propsType>`
            background: ${theme.colors.main};
            color: #fff;
        `}
        &:hover {
            background: ${theme.colors.main};
            color: #fff;
        }
    `}
    ${props => props.varinat === "notification" && css<propsType>`
        background: 0;
        border: 0;
        position: relative;
        .tooltip {
                width: 112px;
                height: 30px;
                background: #5d5ffe;
                position: absolute;
                border-radius: 8px;
                z-index: 5;
                left: 45px;
                display: none;
                font-size: 10px;
                color: #fff;
            };
        ${props => props.disabled && css<propsType>`
            
            &:hover {
                .tooltip {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            .tooltip::after {
                content: "";
                background: #5d5ffe;
                position: absolute;
                transform: rotate(45deg);
                width: 10px;
                height: 11px;
                top: 23px;
                left: 92px;
                z-index: -1;
                color: #fff;
            }
        `}
    `};
    ${props => props.varinat === "outlined-secondary" && css<propsType>`
        height: ${props => props.height || '36px'};
        border-radius: 8px;
        background: #fff;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        border: 1px solid rgba(196, 196, 196, 0.50);
    `}
    ${props => props.varinat === "photo" && css<propsType>`
        width: 64px;
        height: 64px;
        border-radius: 50%;
        background: rgba(196, 196, 196, 0.50);
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
    `};
    ${props => props.varinat === "close" && css<propsType>`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            font-weight: 600;
            font-family: 'Inter';
            width: 40px;
            min-width: 40px;
            height: 40px;
            background: #FFFFFF;
            box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);
            border-radius: 8px;
            border: none;
    `};
    ${props => props.varinat === "time" && css<propsType>`
        width: 70px;
        min-height: 30px;
        height: 30px;
        border-radius: 8px;
        background: rgba(89, 136, 102, 0.80);
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        color: #fff;
        @media screen and (max-width: 768px) {
               width: 40px;
                };
    `};
    ${props => props.varinat === "timeTableMobileDate" && css<propsType>`
        display: none;
        flex-direction: column;
        width: 36px;
        height: 36px;
        padding: 5px;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        border: 1px solid #8E8E8E;
        background: rgba(228, 228, 228, 0.90);
        ${props => props.active && css<propsType>`
            background: #444;
            span {
                color: #fff
            }
        `}
        @media screen and (max-width: 768px) {
               display: flex;
                };

    `};
    ${props => props.varinat === "secondary" && css<propsType>`
        border: 1px solid;
        border-color: ${theme.colors.main};
        background:#fff;
        color: ${theme.colors.main};
        height: 44px;
    `};
    ${props => props.maxWidth && css<propsType>`
    max-width: ${props => props.maxWidth};
    `};
    ${props => props.minWidth && css<propsType>`
    min-width: ${props => props.minWidth};
    `};
    ${props => props.varinat === "add" && css<propsType>`
        border: none;
        background: ${theme.colors.main};
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2%;
        svg {
            fill: #fff;
            transform: scale(1.2);
        };
        &:hover {
            background: ${theme.colors.fonts.main};
        };
        ${props => props.component === "timeTable" && css<propsType>`
             @media screen and (max-width: 768px) {
               display: none;
               
                };
        `}
    `};
    ${props => props.varinat === "outlined" && css<propsType>`
        color: #262626;
        border: 1px solid #262626;
        background: 0;
        position: relative;
    `};
    ${props => props.varinat === "search" && css<propsType>`
        border-radius: 8px;
        border: 1px solid rgba(68, 68, 68, 0.267);
        background: rgb(255, 255, 255);
        display: flex;
        align-items: center;
        justify-content: center;
    `}
    ${props => props.varinat === "settingTariff" && css<propsType>`
        color: #262626;
        border: 1px solid #262626;
        background: 0;
        position: relative;
        &:hover {
            background: ${theme.colors.main};
            color: #fff;
            border: none;
            cursor: pointer;
        }
    `};
    ${props => props.varinat === "moreOptions" && css<propsType>`
       border: 1px solid rgba(68, 68, 68, 0.20);
        width: 36px;
        height: 36px;
        background: 0;
        display: flex;
        position: relative;
        .tooltip {
            display: none;
            width: 120px;
            height: 28px;
            position: absolute;
            bottom: 55px;
            z-index: 5;
            background: #5d5ffe;
            border-radius: 8px;
            color: #fff;
            font-size: 10px;
            transform: translate(-73px, 10px);
        }
        .tooltip::before {
            content: "";
            width: 19px;
            background: #5d5ffe;
            transform: rotate(45deg);
            height: 32px;
            top: 2px;
            position: absolute;
            left: 90px;
            z-index: -1;
        }
        svg {
            fill: #444;
            cursor: ${props => !props.isAllowed ? "not-allowed" : "pointer"};
        }
        
        &:hover {
            background: #444;
            ${props => !props.isAllowed && css<propsType>`
                .tooltip {
                    display: flex;
                }
            `}
           svg {
            fill: #fff;
           } 
        }
    `};
    ${props => props.varinat === "fake" && css<propsType>`
        background: 0;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
    `};
    ${props => props.varinat === "removeNote" && css<propsType>`
        background: 0;
        border: none;
        border-radius: 4px;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 3px;
        right: 3px;
       display: none;
       &:hover {
        cursor: pointer;
       }
    `};
    ${props => props.varinat === "counter" && css<propsType>`
        background: #5d5ffe;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        &:disabled {
            opacity: 0.6;
        }
    `};
    ${props => props.varinat === "sort" && css<propsType>`
        background: #fff;
        width: 44px;
        height: 44px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        ${props => props.active && css<propsType>`
        background: ${theme.colors.fonts.main};
        svg {
            fill: #fff;
        }
        `};
        &:hover {
            background: ${theme.colors.fonts.main};
            cursor: pointer;
            
        };
        &:hover svg {
            fill: #fff;
        }
        &[disabled]:hover {
            background:#fff;
             cursor: not-allowed;
        };
        &[disabled]:hover svg {
            fill: #000000
        }
        svg {
            transform: scale(1.2);
        };
        @media screen and (max-width: 768px) {
           width: 36px;
           height: 36px;
    };
    `};
    
    
    ${props => props.varinat === "btn" && css<propsType>`
        background: #fff;
        width: 50px;
        height: 50px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid ;
        border-color: ${theme.colors.main} ;
    `};
   
    ${props => props.varinat === "calendar" && css<propsType>`
       display :flex ;
       align-items: center;
       justify-content: center;
       background: #fff;
       border-radius: 8px;
       width: 36px;
       height: 36px;
       border: none;
       svg {
        fill: #444444;
        transform: scale(1.1);
       };
       &:hover {
        background: rgba(68, 68, 68, 0.80);
        svg {
            fill: #fff;
        }
       }
    `};
    ${props => props.hover && props.hoverColor && css<propsType>`
        &:hover {
            background: ${props => props.hoverColor}
        }
    `}
    ${props => props.varinat === "tariff" && css<propsType>`
        background: #598866;
        width: 220px;
        height: 65px;
        position: absolute;
        border: none;
        border-radius: 16px;
        color: white;
        font-size: 16px;
        font-weight: 600;
        bottom: -34px;
        right: 27px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        
        ${props => props.active && css<propsType>`
            opacity: 1;
            transition: 2s;
        `};
        ${props => !props.active && css<propsType>`
            opacity: 0;
            transition: 2s;
        `};
        
        @media screen and (max-width: 1500px) {
            height: 55px;
            bottom: -30px
    };
        @media screen and (max-width: 1350px) {
            height: 50px;
            bottom: -26px;
            span {
                font-size: 16px;
            }
    };
    @media screen and (max-width: 1270px) {
            height: 50px;
            bottom: -26px;
            width: 200px;
            gap: 15px;
            span {
                font-size: 16px;
            }
    };
    @media screen and (max-width: 1200px) {
        bottom: -20px;
            width: 70%;
            gap: 10px;
            height: 45px;
            span {
                font-size: 16px;
            }
    };
    @media screen and (max-width: 1140px) {
        bottom: -23px;
        right:5px;
            width: 70%;
            gap: 10px;
            height: 40px;
            span {
                font-size: 13px;
            };
            svg {
                transform: scale(0.7)
            }
    };
    `};
    ${props => props.varinat === "changeTariff" && css<propsType>`
        background: ${theme.colors.main};
        width: 220px;
        height: 65px;
        position: absolute;
        border: none;
        border-radius: 16px;
        color: white;
        font-size: 16px;
        font-weight: 600;
        bottom: -34px;
        right: 27px;
        display: flex;
        align-items: center;
        justify-content: center;
        ${props => props.active && css<propsType>`
            opacity: 1;
            transition: opacity 2s;
        `};
        ${props => !props.active && css<propsType>`
            opacity: 0;
            transition: opacity 2s;
        `};
       
        &:hover {
            background: #000;
            
        };
        @media screen and (max-width: 1500px) {
            height: 55px;
            bottom: -30px
    };
        @media screen and (max-width: 1350px) {
            height: 50px;
            bottom: -26px;
            span {
                font-size: 16px;
            }
    };
        @media screen and (max-width: 1270px) {
            height: 50px;
            bottom: -26px;
            width: 200px;
            span {
                font-size: 16px;
            }
    };
         @media screen and (max-width: 1200px) {
            height: 45px;
            bottom: -20px;
            width: 70%;

            span {
                font-size: 16px;
            }
    };
        @media screen and (max-width: 1140px) {
            bottom: -23px;
            right:5px;
            width: 70%;
            gap: 10px;
            height: 40px;
            span {
                font-size: 13px;
            };
            svg {
                transform: scale(0.7)
            }
    };
    .trash{
        @media screen and (max-width: 500px){
            height: 10px;
            width: 10px;
            overflow: hidden;
        }
    }
   
    `};
    
`