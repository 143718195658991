import { createGlobalStyle } from "styled-components";
import { theme } from "../components/Theme.styled";


export const GlobalStyles = createGlobalStyle`
    body{
        height: 100vh;
        font-family: "mont";
        overflow: hidden;
        &::-webkit-scrollbar-thumb {
            width: 0px;
        };
        &::-webkit-scrollbar-track {
            background: 0;
        };
        &::-webkit-scrollbar-button {
            background: 0;
        };
        /* overflow-x: hidden; */
        @media screen and (max-width: 768px) {
           /* height: 100%; */
            overflow: visible;
        };
    };
    #root {
        height: 100%;
        &::-webkit-scrollbar-thumb {
            width: 0px;
        };
        &::-webkit-scrollbar-track {
            background: 0;
        };
        &::-webkit-scrollbar-button {
            background: 0;
        }
    };
    ul {
        padding-inline-start: 0;
        padding: 0;
    };
    li {
        list-style: none;
    };
    a {
        display: contents;
        text-decoration: none;
    };
    input {
        padding-left: 10px;
        text-indent: 10px !important;
    }
    input[disabled]:hover {
        cursor: not-allowed;
    };
    
    textarea {
        text-indent: 10px;
        text-transform: none !important;
    }
    .freeTimeCalendars {
        @media screen and (max-width: 768px) {
           width: 335px !important;
            height: 345px !important;
        };
        .calendarFree {
            width: 315px;
            height: 345px;
        }
    }
    .removeNote {
        padding: 24px 64px;
        @media screen and (max-width: 768px) {
           width: 350px;
           position: fixed;
           top: 50%;
           left: 50%;
           transform: translate(-50%,-50%);
           padding: 20px;
           height: 180px;
           .quest {
            font-size: 16px;
           };
           .warn {
            font-size: 12px;
           }
        };
    }

    .radio {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 2px solid;
        border-color: ${theme.colors.main};
        input {
            opacity: 0;
        };
       
    };
    .newEntry {
        @media screen and (max-width: 768px) {
           width: 350px;
           padding: 24px 20px;
          
        };
    };
    .tariffContainer{
        .tariffCardItemsMobile {
            display: none;
        }
        @media screen and (max-width: 768px) {
          flex-direction: column;
          /* overflow-y: auto; */
          width: 100%;
          height: 95%;
            .tariffCardItems{
                display: none;
            };
            .tariffCardItemsMobile {
                display: flex;
            }
        };
    };
    .btnsPeriod {
        height: 50px;
    };

    .btnForChange {
        @media screen and (max-width: 768px) {
            display: flex;
           position: fixed;
           top: 50%;
           left: 50%;
            transform: translate(-50%,-50%);
        };
    };
    .btnForChangeContainer {
        display: none;
        @media screen and (max-width: 768px) {
            display: flex;
        };
    };
    .profileContent {
        @media screen and (max-width: 768px) {
            flex-direction: column;
            align-items: center;
            margin-bottom: 40px 
        };
    };
    .profileBox {
        @media screen and (max-width: 768px) {
            max-width: 400px;
        };
       
    };

    .profileAvatarBox {
        &:hover {
            cursor: pointer;
        }
    };
    .react-tel-input .selected-flag {
        padding: 0 5px !important;
    };
    .react-tel-input .selected-flag .arrow {
        left: 20px !important;
    };
    .react-tel-input .country-list .country {
        display: flex !important;
    };

    
    
    
   
   
   
`