import {
  add,
  addDays,
  addMonths,
  endOfMonth,
  endOfWeek,
  isAfter,
  isBefore,
  startOfMonth,
  startOfWeek,
  subMonths,
} from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { AppRootState } from "../../store/storeRedux";
import {
  currentMonthDataType,
  setMonthForSettingsAC,
  stateSettingsType,
} from "../../store/SettingReducer";
import { Text } from "../../styles/components/Text";
import { theme } from "../../styles/components/Theme.styled";
import { Box } from "../../styles/components/Box";
import { FlexBox } from "../../styles/components/FlexBox";
import { SuperButton } from "../../styles/components/SuperButton";
import { NextPrevButton } from "../../styles/components/NextButton";
import { Grid } from "../../styles/components/Grid";
import { useEffect, useState } from "react";
import { ru } from "date-fns/locale";
import { ModalLayer } from "../../styles/components/ModalLayer";
import React from "react";

type settingDateType = { dayOrder: number; dateCurrentDay: string }[];
type datesType = {
  date: Date;
  isPresent: boolean;
};

type propsType = {
  setDate: (d: Date) => void;
  showCalendar: (v: boolean) => void;
  inModal?: boolean;
};
export const SimpleCalendarWindow = (props: propsType) => {
  const dispatch = useDispatch();
  const [start, setStart] = useState<Date>(new Date());
  const [dates, setDates] = useState<Array<datesType>>([]);
  const prevMonth = () => {
    setStart(subMonths(start, 1));
  };
  const nextMonth = () => {
    setStart(addMonths(start, 1));
  };

  const dateHandler = (date: Date) => {
    props.setDate(date);
    props.showCalendar(false);
  };
  const renderDates = (): React.JSX.Element => {
    return (
      <Grid
        width="100%"
        height="100%"
        columns="1fr 1fr 1fr 1fr 1fr 1fr 1fr"
        gap={2}
      >
        {dates.map((d) => (
          <FlexBox
            border="2px solid #fff"
            className="date"
            align="center"
            justify="center"
            background={"#fff"}
            borderRadius="8px"
            width="100%"
            height="100%"
            direction="row"
            onClick={() => dateHandler(d.date)}
          >
            <Text font="mont" size={14} color={theme.colors.fonts.main}>
              {d.date.getDate()}
            </Text>
          </FlexBox>
        ))}
      </Grid>
    );
  };
  const fillCalendar = () => {
    const datesArray: datesType[] = [];
    const datesForSettngs: settingDateType = [];
    let firstDay = startOfMonth(start);
    let weekOfFirst = startOfWeek(firstDay, { locale: ru });
    const lastDay = endOfMonth(start);
    let weekOfEnd = endOfWeek(lastDay, { locale: ru });
    while (isBefore(weekOfFirst, weekOfEnd)) {
      if (weekOfFirst.getMonth() === start.getMonth()) {
        datesForSettngs.push({
          dayOrder:
            weekOfFirst.getDay() === 0
              ? weekOfFirst.getDay() + 7
              : weekOfFirst.getDay(),
          dateCurrentDay: weekOfFirst.toLocaleDateString(),
        });
      }
      datesArray.push({
        date: weekOfFirst,
        isPresent: weekOfFirst.getMonth() === start.getMonth(),
      });
      weekOfFirst = addDays(weekOfFirst, 1);
    }
    setDates(datesArray);
    dispatch(
      setMonthForSettingsAC(
        datesForSettngs,
        start.toLocaleString("ru", { month: "numeric", year: "numeric" })
      )
    );
  };

  let weekDays = ["пн", "вт", "ср", "чт", "пт", "сб", "вс"];
  const year = start.getFullYear();
  const month = start.toLocaleString("ru", { month: "long" });

  useEffect(() => {
    fillCalendar();
  }, [start]);

  return (
    <>
      {!props.inModal ? (
        <ModalLayer onClick={() => props.showCalendar(false)} />
      ) : (
        <ModalLayer
          onClick={() => props.showCalendar(false)}
          style={{ zIndex: 27, opacity: 0 }}
        />
      )}
      {
        <Box
          zIndex={35}
          position="fixed"
          boxShadow="0px 4px 8px 0px rgba(0, 0, 0, 0.25)"
          width="350px"
          height="370px"
          borderRaduis="16px"
          background="#fff"
          top="50%"
          left="50%"
          transform="translate(-50%,-50%)"
        >
          <FlexBox
            direction="column"
            align="center"
            justify="center"
            width="100%"
            height="100%"
            padding="14px 17px"
          >
            <Text size={14} color={theme.colors.fonts.main} font="mont">
              Выберите день для создания интервала
            </Text>
            <Text size={14} font="mont" color={theme.colors.fonts.main}>
              {year}
            </Text>

            <FlexBox
              padding="0 20px"
              direction="row"
              width="100%"
              justify="space-between"
              align="center"
            >
              <SuperButton onClick={prevMonth} maxWidth="15px" varinat="fake">
                <NextPrevButton component="calendar" type="prev" />
              </SuperButton>
              <Text
                transform="capitalize"
                font="mont"
                size={14}
                color={theme.colors.fonts.main}
              >
                {month}
              </Text>
              <SuperButton onClick={nextMonth} maxWidth="15px" varinat="fake">
                <NextPrevButton component="calendar" type="next" />
              </SuperButton>
            </FlexBox>
            <FlexBox
              height="100%"
              padding="15px 0"
              width="100%"
              direction="column"
            >
              <FlexBox direction="row" width="100%" justify="space-between">
                {weekDays.map((w) => (
                  <FlexBox
                    align="center"
                    justify="center"
                    width="100%"
                    direction="row"
                    key={new Date().toISOString() + w}
                  >
                    <Text
                      transform="capitalize"
                      weight={500}
                      font="Inter"
                      color={theme.colors.fonts.main}
                      size={15}
                    >
                      {w}
                    </Text>
                  </FlexBox>
                ))}
              </FlexBox>
              <FlexBox height="100%" direction="row">
                {renderDates()}
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </Box>
      }
    </>
  );
};
