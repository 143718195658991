import { useEffect, useState } from "react";
import { Box } from "../../../styles/components/Box";
import { useDispatch, useSelector } from "react-redux";
import { AppRootState, thunkDispatchRootType } from "../../../store/storeRedux";
import {
  getWorkDaysTC,
  getWorkTimesForDayTC,
} from "../../../store/CalendarReducer";
import { Table } from "../../../styles/components/Table";
import { TableRow } from "../../../styles/components/TableRow";
import { Column } from "../../../styles/components/Column";
import { stateUserType } from "../../../store/UserReducer";
import { TimeTableCellContainer } from "../../../styles/components/TimeTableCellContainer";
import { ButtonNote } from "../../../styles/components/ButtonNote";
import sprite from "../images/sprite.svg";
import { getNotesTC, notesStateType } from "../../../store/NotesReducer";
import {
  selectedMasterType,
  selectMasterAC,
  toggleSelectModeAC,
} from "../../../store/CommonReducers";
import { Note } from "../../Note";
import {
  getFreeTimesForTeamTC,
  getNoteForTeamInDayTC,
  getRenderTimesForTeamTC,
  getWorkTimesForTeamTC,
  setActiveDateForTimeTableAC,
} from "../../../store/UserProPlusReducer";
import { NoteForTeam } from "./NoteForTeam";
import { Icon } from "../../../styles/components/Icon";
import { sessionsType } from "../../../store/SessionsReducer";
import { calendarHelper } from "../../../utils/calendar/calendar-helper";
import { NavLink } from "react-router-dom";
import { FlexBox } from "../../../styles/components/FlexBox";
import { Text } from "../../../styles/components/Text";
import { theme } from "../../../styles/components/Theme.styled";
type propsType = {
  user: stateUserType;
  day: Date;
  sessions: sessionsType;
  disabledActions: boolean;
  showNoteWindow: (
    date?: string,
    time?: string,
    duration?: number,
    master?: null | selectedMasterType
  ) => void;
};

const selectUserProPlusState = (state: AppRootState) => state.userProPlusState;
export const TimeTableForDay = (props: propsType) => {
  const userProPlusState = useSelector(selectUserProPlusState);
  const dispatch: thunkDispatchRootType = useDispatch();

  const [workTimesLength, setWorkTimeLength] = useState(0);

  useEffect(() => {
    const date = new Date(
      props.day.toLocaleDateString().split(".").reverse().join("-")
    ).toISOString();
    dispatch(getNoteForTeamInDayTC(date));
    dispatch(getWorkTimesForTeamTC(date));
    dispatch(getFreeTimesForTeamTC(date));
    // dispatch(getWorkTimesForDayTC(calendarHelper.localeStringToDate(props.day.toLocaleDateString()).toISOString()))
    dispatch(
      getRenderTimesForTeamTC(
        calendarHelper
          .localeStringToDate(props.day.toLocaleDateString())
          .toISOString()
      )
    );
    dispatch(setActiveDateForTimeTableAC(date));
  }, [props.day, JSON.stringify(userProPlusState.notesForTeam)]);

  useEffect(() => {
    return () => {
      dispatch(setActiveDateForTimeTableAC(""));
      setWorkTimeLength(0);
    };
  }, []);
  useEffect(() => {
    if (userProPlusState.teammateWorkTimes.teammates.length) {
      setWorkTimeLength(0);
      userProPlusState.teammateWorkTimes.teammates.forEach((t) => {
        if (t.workTimes && t.workTimes.length) {
          setWorkTimeLength((prev) => prev + 1);
        }
      });
    }
  }, [JSON.stringify(userProPlusState.teammateWorkTimes.teammates), props.day]);
  return (
    <Box direction="row" position="relative" overFlowY="auto">
      <Table component="TimeTable">
        {!workTimesLength && (
          <FlexBox
            align="center"
            justify="center"
            height="100%"
            width="100%"
            direction="column"
          >
            <Text color={theme.colors.fonts.main} size={16} font="mont">
              У вас нет ниодного активного дня, для заполнения рабочего времени
            </Text>
            <p>
              <NavLink to={"settings"}>перейдите по ссылке</NavLink>
            </p>
          </FlexBox>
        )}
        {userProPlusState.renderTimes.map((t, i) => (
          <TableRow component="TimeTable" key={i} colors="other">
            <Column component="TimeTable">{t}</Column>
            {userProPlusState.teammates.teammates.map((us, idx) =>
              userProPlusState.teammateWorkTimes.teammates.find(
                (tm) =>
                  tm.userId === us.user.userId &&
                  tm.workTimes &&
                  tm.workTimes.some((w) => parseInt(w.start) === parseInt(t))
              ) ? (
                <TimeTableCellContainer width="100%">
                  {userProPlusState.notesForTeam.map((el) =>
                    el.userId === us.user.userId &&
                    parseInt(el.time) === parseInt(t) ? (
                      <NoteForTeam
                        isLast={
                          idx + 1 ===
                          userProPlusState.teammates.teammates.length
                        }
                        el={el}
                      />
                    ) : (
                      <></>
                    )
                  )}
                  {userProPlusState.teammateFreeTimes.teammates.map(
                    (tmt) =>
                      tmt.userId === us.user.userId &&
                      tmt.freeTimes &&
                      tmt.freeTimes.map(
                        (f) =>
                          parseInt(f.start) === parseInt(t) &&
                          (!props.disabledActions ||
                            tmt.userId === props.user.user?.id) && (
                            <ButtonNote
                              onClick={() =>
                                props.showNoteWindow(
                                  props.day.toISOString(),
                                  f.start,
                                  f.endNumber - f.startNumber,
                                  {
                                    masterID: us.user.userId,
                                    avatarUrl: us.user.avatarUrl,
                                    masterName: us.user.firstName,
                                  }
                                )
                              }
                              duration={f.endNumber - f.startNumber}
                              time={f.start}
                            >
                              <Icon width={18} height={16} svgID="people" />
                            </ButtonNote>
                          )
                      )
                  )}
                </TimeTableCellContainer>
              ) : (
                <TimeTableCellContainer
                  free
                  width="100%"
                ></TimeTableCellContainer>
              )
            )}
          </TableRow>
        ))}
      </Table>
    </Box>
  );
};
