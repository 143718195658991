

import { useEffect, useId, useState } from "react"
import { Box } from "../../../styles/components/Box"
import { FlexBox } from "../../../styles/components/FlexBox"
import { FormInputContainer } from "../../../styles/components/FormInputContainer"
import { ModalLayer } from "../../../styles/components/ModalLayer"
import { SuperButton } from "../../../styles/components/SuperButton"
import { Text } from "../../../styles/components/Text"
import { TextField } from "../../../styles/components/TextField"
import { theme } from "../../../styles/components/Theme.styled"
import { CheckBox } from "../../../styles/components/CheckBox"
import { useForm } from "react-hook-form"
import { addWaSendingTimeTableFT } from "../../../apiTypes/formTypes/wa.types"
import { AppRootState, thunkDispatchRootType } from "../../../store/storeRedux"
import { useDispatch, useSelector } from "react-redux"
import { addWaTimeTablePatternTC, editWaTimeTablePatternTC, waPatternTimeTableType } from "../../../store/ProsettingsReducer"
import { tariffType } from "../../../store/UserReducer"

enum Actions {
    note = "note",
    remove = "remove",
    edit = "edit"
}

type propsType = {
    setEditModal(v: boolean): void,
    pattern: waPatternTimeTableType,
    setEditedPattern(v: waPatternTimeTableType | null):void
}

const selectUser = (state:AppRootState) => state.user
const selectProSettings = (state:AppRootState) => state.proSettings
export const WaTimeTablePatternEdit = (props: propsType) => {
    const dispatch:thunkDispatchRootType = useDispatch()
    const user = useSelector(selectUser)
    const proSettings = useSelector(selectProSettings)
    const btns = [
        {id: useId(),title: "дата", pattern: "date"},
        {id: useId(),title: "время", pattern: "time"},
        {id: useId(),title: "мастер", pattern: "master"},
        {id: useId(),title: "услуга", pattern: "service"},
        {id: useId(),title: "продолжительность", pattern: "duration"},
        {id: useId(),title: "Имя клиента", pattern: "clientName"},
       
    ]
    const actions = [
        {id: useId(), title:"Запись", value: Actions.note},
        {id: useId(), title:"Удаление", value: Actions.remove},
        {id: useId(), title:"Редактирование", value: Actions.edit},
    ]
    const {handleSubmit, register, setValue, watch,getValues, formState: {errors}, setError,clearErrors} = useForm<addWaSendingTimeTableFT>({
        defaultValues: {
            action: props.pattern.action,
            name: props.pattern.name,
            period: props.pattern.period,
            text: props.pattern.text,
            active: props.pattern.active
        }
    })
    const addPattern = (e:any, val: string) => {
        e.preventDefault()
        setValue("text",`${getValues("text")}` + " " +"$"+`{${val}}`)
    }
    const saveData = (data: addWaSendingTimeTableFT) => {
        const matchPattern = proSettings.waTimeTablePatterns.find(p => p.period === Number(data.period) && p.action === data.action && p.id !== props.pattern.id)
        if(matchPattern ) setError("action",{message: "У вас уже есть шаблон с похожими характеристиками"})
        dispatch(editWaTimeTablePatternTC(data, props.pattern.id, user.user?.tariff as tariffType))
    }
    const closeWindow = (e:any) => {
        e.preventDefault()
        props.setEditModal(false)
    }

    useEffect(() => {
            const sub = watch((value, {name, type}) => {
                if(value.action && name === "action" && type === "change"){
                    clearErrors("action")
                    clearErrors("period")
                    return
                }
                if(value.period && name === "period" && type === "change"){
                    clearErrors("action")
                    clearErrors("period")
                    return
                }
            })

            return () => {
                sub.unsubscribe()
            }
    },[watch])
    useEffect(() => {
        return () => {
            props.setEditedPattern(null)
        }
    },[])
    return (
        <>
            <ModalLayer onClick={() => props.setEditModal(false)}/>
            <Box onSubmit={handleSubmit(saveData)} gap={18} padding="10px" height="600px" width="320px" as={"form"} background={theme.colors.colorForm} borderRaduis="8px" zIndex={33} modal>
                <Text size={14} font="mont" color={theme.colors.fonts.main}>Редактирование шаблона</Text>
                <FormInputContainer>
                    <Text font="mont" size={12} color={theme.colors.fonts.main}>Название</Text>
                    <TextField {...register("name")} placeholder="Название шаблона"/>
                </FormInputContainer>
                <FormInputContainer>
                <Text font="mont" size={12} color={theme.colors.fonts.main}>Выберите действие</Text>
                    <select {...register("action")} style={{height: "40px"}}>
                        {actions.map(a => <option value={a.value} key={a.id}>{a.title}</option>)}
                    </select>
                </FormInputContainer>
                <FormInputContainer>
                <Text font="mont" size={12} color={theme.colors.fonts.main}>Когда отправлять?</Text>
                    <select {...register('period')} style={{height: "40px"}}>
                            <option value={"none"}>Не выбрано</option>
                            <option value={0}>сразу</option>
                            <option value={1}>за день</option>
                    </select>
                </FormInputContainer>
                {errors.action?.message && <Text color={theme.colors.fonts.errors} size={12} font="mont">{errors.action.message}</Text>}
                <FormInputContainer>
                    <Text font="mont" size={12} color={theme.colors.fonts.main}>Текст шаблона</Text>
                    <FlexBox direction="row" style={{flexWrap: "wrap"}} width="100%" height="auto">
                        {
                            btns.map(b => <SuperButton onClick={(e) =>  addPattern(e,b.pattern)} minWidth="140px" key={b.id} varinat="time">{b.title}</SuperButton>)
                        }
                    
                    </FlexBox>
                    <textarea {...register("text")} cols={10} rows={8}/>
                </FormInputContainer>
                <FlexBox align="center" gap={10} direction="row" width="100%">
                                    <CheckBox variant="switch" render={() => <input {...register("active")} type="checkbox" />} value={watch("active")}  />
                                                    <Text color={theme.colors.fonts.main} font="mont" size={14}>Активна</Text>
                                    
                    
                </FlexBox>
                <FlexBox gap={10}  direction="row" height="32px">
                    <SuperButton onClick={(e) => closeWindow(e)} varinat="outlined">Отменить</SuperButton>
                    <SuperButton type="submit" varinat="contained">Создать</SuperButton>
                </FlexBox>
            </Box>
        </>
        
    )
}