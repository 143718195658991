export enum ClientRemindersStatus {
    OPEN = "OPEN",
    ACTIVE = "ACTIVE",
    CLOSE = "CLOSE"
}

export type remindersStateType = {
    id: string,
    userName: string,
    eventDate: Date,
    isPerosnal: boolean,
    comment: string,
    reminderDate: Date,
    status: ClientRemindersStatus
}


export type clientRemindersViewForNotifType = {
    id: string,
    eventDate: Date,
    isPersonal: boolean,
    comment: string,
    reminderDate: Date,
    status: ClientRemindersStatus,
    client: {
        name: string,
        surname: string,
        patronymic: string,
        photoURL: string
    }
}