import axios, { AxiosError, isAxiosError } from "axios";
import { tariffInfoType } from "../apiTypes/responses/tariffRespons.type";
import { rootUserType, tariffType } from "./UserReducer";
import { thunkActionRootType } from "./storeRedux";
import { apiUser } from "../apiServer/apiUser";
import { setIsLoadingAC } from "./AppReducer";
import {
  workCategoryType,
  workServiceType,
} from "../apiTypes/root/proSettings.type";
import { apiProSettings } from "../apiServer/apiProSettings";
import { createWorkFormType } from "../apiTypes/formTypes/createWorkFormType";
import {
  createWorkWindowAC,
  editWorkCategoryWindowAC,
  editWorkServiceWindowAC,
  setSearchTeammateWindowAC,
} from "./ModalWindowsReducer";
import { convertTimeToNumber } from "../utils/utils";
import { logoutTC } from "./AuthReducer";
import { apiUserSuperPro } from "../apiServer/apiUserProPlus";
import { ActionsWaTimeTable, addWaSendingTimeTableFT } from "../apiTypes/formTypes/wa.types";
import { apiUserPro } from "../apiServer/apiUserPro";


export type searchingTeamMateUserType = {
  id: string,
  lastName: string, 
  firstName: string,
  thirdName: string;
  avatarUrl: string
}
export type searchTeammateType = {
  user: searchingTeamMateUserType | null,
  isNotFound: boolean
}
export type ProSettingsType = {
  tariffInfo: tariffInfoType[];
  createNewUserMode: boolean;
  workCategories: Array<workCategoryType>;
  workService: Array<workServiceType>;
  findWorkKey: string | null;
  searchTeammate: searchTeammateType,
  waTimeTablePatterns: waPatternTimeTableType[]
};

export type waPatternTimeTableType = {
  id: string,
    active: boolean,
    text: string,
    action: ActionsWaTimeTable,
    name: string,
    userId: string,
    period: number,
    teamId: string | null
}
export type prosettingsAT =
  | createCategoryAT
  | getCategoryAT
  | createWorkServiceAT
  | getWorkServicesAT
  | changeWorkActiveStatusAT
  | changeWorkVidzhetStatusAT
  | deleteWorkServiceAT
  | setFindWorkKeyAT
  | editWorkServiceAT
  | removeWorkCategoryAT
  | editWorkCategoryAT
  | searchTeammateAT
  | addWaTimeTablePatternAT
  | deleteWaTimeTablePatternAT
  | editWaTimeTablePatternAT
  | getWaTimeTablePatternAT

type searchUserForTeamAT = {
  type: "PRO-SETTINGS/SEARCH-USER";
  user: rootUserType | null;
};

const initState: ProSettingsType = {
  tariffInfo: [],
  createNewUserMode: false,
  workCategories: [],
  workService: [],
  findWorkKey: null,
  searchTeammate: {
    user: null,
    isNotFound: false
  },
  waTimeTablePatterns: []
};
type createCategoryAT = {
  type: "PRO-SETTINGS/CREATE-CATEGORY";
  payload: workCategoryType;
};
type getCategoryAT = {
  type: "PRO-SETTINGS/GET-CATEGORY";
  payload: workCategoryType[];
};
type createWorkServiceAT = {
  type: "PRO-SETTINGS/CREATE-WORK-SERVICE";
  payload: workServiceType;
};
type getWorkServicesAT = {
  type: "PRO-SETTINGS/GET-WORK-SERVICE";
  payload: workServiceType[];
};
type changeWorkActiveStatusAT = {
  type: "PRO-SETTINGS/CHANGE-WORK-ACTIVE-STATUS";
  status: boolean;
  id: string;
};
type changeWorkVidzhetStatusAT = {
  type: "PRO-SETTINGS/CHANGE-WORK-VIDZHET-STATUS";
  status: boolean;
  id: string;
};
type deleteWorkServiceAT = {
  type: "PRO-SETTINGS/DELETE-WORK";
  id: string;
};
type setFindWorkKeyAT = {
  type: "PRO-SETTINGS/SET-WORK-FIND-KEY";
  value: string | null;
};

type editWorkServiceAT = {
  type: "PRO-SETTINGS/EDIT-WORK-SERVICE";
  id: string;
  payload: createWorkFormType;
};

type removeWorkCategoryAT = {
  type: "PRO-SETTINGS/REMOVE-WORK-CATEGORY",
  id: string
}
type editWorkCategoryAT = {
  type: "PRO-SETTINGS/EDIT-WORK-CATEGORY",
  payload: {id: string, name: string}
}
type searchTeammateAT = {
  type: "PRO-SETTINGS/SEARCH-TEAMMATE",
  payload: searchTeammateType
}
type addWaTimeTablePatternAT = {
  type: "PRO-SETTINGS/WA-TIMETABLE-PATTERN-ADD",
  payload: waPatternTimeTableType
}
type deleteWaTimeTablePatternAT = {
  type: "PRO-SETTINGS/WA-TIMETABLE-PATTERN-DELETE",
  id: string
}
type editWaTimeTablePatternAT = {
  type: "PRO-SETTINGS/WA-TIMETABLE-PATTERN-EDIT",
  payload: addWaSendingTimeTableFT,
  id: string
}
type getWaTimeTablePatternAT = {
  type: "PRO-SETTINGS/WA-TIMETABLE-PATTERN-GET",
  payload: waPatternTimeTableType[],
  
}

export const ProsettingsReducer = (
  state: ProSettingsType = initState,
  action: prosettingsAT
): ProSettingsType => {
  switch (action.type) {
    case "PRO-SETTINGS/CREATE-CATEGORY":
      return {
        ...state,
        workCategories: [...state.workCategories, action.payload],
      };
    case "PRO-SETTINGS/GET-CATEGORY":
      return { ...state, workCategories: action.payload };
    case "PRO-SETTINGS/CREATE-WORK-SERVICE":
      return { ...state, workService: [...state.workService, action.payload] };
    case "PRO-SETTINGS/GET-WORK-SERVICE":
      return { ...state, workService: action.payload };
    case "PRO-SETTINGS/CHANGE-WORK-ACTIVE-STATUS":
      return {
        ...state,
        workService: state.workService.map((w) =>
          w.id === action.id ? { ...w, isActive: action.status } : w
        ),
      };
    case "PRO-SETTINGS/CHANGE-WORK-VIDZHET-STATUS":
      return {
        ...state,
        workService: state.workService.map((w) =>
          w.id === action.id ? { ...w, isVidzhet: action.status } : w
        ),
      };
    case "PRO-SETTINGS/DELETE-WORK":
      return {
        ...state,
        workService: state.workService.filter((w) => w.id !== action.id),
      };
    case "PRO-SETTINGS/SET-WORK-FIND-KEY":
      return { ...state, findWorkKey: action.value };
    case "PRO-SETTINGS/EDIT-WORK-SERVICE":
      const category = state.workCategories.find(
        (c) => c.id === action.payload.categoryId
      );
      if (!category) {
        return state;
      } else {
        const categoryName = category.category;
        const newData: workServiceType = {
          ...action.payload,
          id: action.id,
          category: categoryName,
          duration: convertTimeToNumber(action.payload.duration),
        };
        return {
          ...state,
          workService: state.workService.map((w) =>
            w.id === action.id ? newData : w
          ),
        };
      }
      case "PRO-SETTINGS/REMOVE-WORK-CATEGORY":
        return {...state, workCategories: state.workCategories.filter( w => w.id !== action.id)}
      case "PRO-SETTINGS/EDIT-WORK-CATEGORY":
        return {...state, workCategories: state.workCategories.map(w => w.id === action.payload.id ? {...w, category: action.payload.name} : w)}
      case "PRO-SETTINGS/SEARCH-TEAMMATE":
        return {...state, searchTeammate: action.payload}
      case "PRO-SETTINGS/WA-TIMETABLE-PATTERN-ADD":
        return {...state, waTimeTablePatterns: [...state.waTimeTablePatterns, action.payload]}
      case "PRO-SETTINGS/WA-TIMETABLE-PATTERN-DELETE":
        return {...state, waTimeTablePatterns: state.waTimeTablePatterns.filter(v => v.id !== action.id)}
      case "PRO-SETTINGS/WA-TIMETABLE-PATTERN-EDIT":
        return {...state, waTimeTablePatterns: state.waTimeTablePatterns.map(v => v.id === action.id ? ({...action.payload, id: v.id, userId: v.userId, teamId: v.teamId}) : v)}
      case "PRO-SETTINGS/WA-TIMETABLE-PATTERN-GET":
        return {...state, waTimeTablePatterns: action.payload}
    default:
      return state;
  }
};

export const searchUserForTeamAC = (
  user: rootUserType | null
): searchUserForTeamAT => {
  return { type: "PRO-SETTINGS/SEARCH-USER", user };
};
export const setFindWorkKeyAC = (value: string | null): setFindWorkKeyAT => {
  return { type: "PRO-SETTINGS/SET-WORK-FIND-KEY", value };
};

const createCategoryAC = (payload: workCategoryType): createCategoryAT => {
  return { type: "PRO-SETTINGS/CREATE-CATEGORY", payload };
};
const createWorkServiceAC = (
  payload: workServiceType
): createWorkServiceAT => ({
  type: "PRO-SETTINGS/CREATE-WORK-SERVICE",
  payload,
});
const getCategoryAC = (payload: workCategoryType[]): getCategoryAT => ({
  type: "PRO-SETTINGS/GET-CATEGORY",
  payload,
});
const getWorkServicesAC = (payload: workServiceType[]): getWorkServicesAT => ({
  type: "PRO-SETTINGS/GET-WORK-SERVICE",
  payload,
});
const changeWorkActiveStatusAC = (
  id: string,
  status: boolean
): changeWorkActiveStatusAT => ({
  type: "PRO-SETTINGS/CHANGE-WORK-ACTIVE-STATUS",
  status,
  id,
});
const changeWorkVidzhetStatusAC = (
  id: string,
  status: boolean
): changeWorkVidzhetStatusAT => ({
  type: "PRO-SETTINGS/CHANGE-WORK-VIDZHET-STATUS",
  status,
  id,
});
const deleteWorkServiceAC = (id: string): deleteWorkServiceAT => ({
  type: "PRO-SETTINGS/DELETE-WORK",
  id,
});
const editWorkServiceAC = (
  id: string,
  payload: createWorkFormType
): editWorkServiceAT => ({
  type: "PRO-SETTINGS/EDIT-WORK-SERVICE",
  id,
  payload,
});

const removeWorkCategoryAC = (id: string):removeWorkCategoryAT => {
  return {type: "PRO-SETTINGS/REMOVE-WORK-CATEGORY",id}
}
const editWorkCategoryAC = (id: string, name: string):editWorkCategoryAT => {
  return {type: "PRO-SETTINGS/EDIT-WORK-CATEGORY",payload: {id, name}}
}
const addWaTimeTablePatternAC = (payload: waPatternTimeTableType):addWaTimeTablePatternAT => {
  return {type: "PRO-SETTINGS/WA-TIMETABLE-PATTERN-ADD",payload}
}
const getWaTimeTablePatternAC = (payload: waPatternTimeTableType[]):getWaTimeTablePatternAT => {
  return {type: "PRO-SETTINGS/WA-TIMETABLE-PATTERN-GET",payload}
}
const deleteWaTimeTablePatternAC = (id: string):deleteWaTimeTablePatternAT => {
  return {type: "PRO-SETTINGS/WA-TIMETABLE-PATTERN-DELETE",id}
}
const editWaTimeTablePatternAC = (id: string, payload: addWaSendingTimeTableFT):editWaTimeTablePatternAT => {
  return {type: "PRO-SETTINGS/WA-TIMETABLE-PATTERN-EDIT",id, payload}
}

export const searchTeammateAC = (payload: searchTeammateType):searchTeammateAT=> {
  return {type: "PRO-SETTINGS/SEARCH-TEAMMATE", payload}
}

export const searchTeammateTC = (workId: string):thunkActionRootType => async dispatch => {
  dispatch(setIsLoadingAC(true))
  try {
    const res = await apiUserSuperPro.getByWorkId(workId)
    dispatch(searchTeammateAC({user: res.data, isNotFound: false}))
  } catch (error:AxiosError | any) {
    if(isAxiosError(error)){
      if(error.response?.status === 404){
        dispatch(searchTeammateAC({user: null, isNotFound: true}))
      }
    }

  } finally {
    dispatch(setIsLoadingAC(false))
  }
}


export const createWorkCategoryTC =
  (category: string): thunkActionRootType =>
  async (dispatch) => {
    dispatch(setIsLoadingAC(true));
    try {
      const res = await apiProSettings.create(category);
      dispatch(createCategoryAC(res.data));
    } catch (error: AxiosError | any) {
      if (isAxiosError(error)) {
        if (error.response?.status === 401) {
          dispatch(logoutTC());
        }
      }
    } finally {
      dispatch(setIsLoadingAC(false));
    }
  };

export const getWorkCategoriesTC =
  (): thunkActionRootType => async (dispatch) => {
    dispatch(setIsLoadingAC(true));
    try {
      const res = await apiProSettings.getWorkCategories();
      dispatch(getCategoryAC(res.data));
    } catch (error: AxiosError | any) {
      if (isAxiosError(error)) {
        if (error.response?.status === 401) {
          dispatch(logoutTC());
        }
      }
    } finally {
      dispatch(setIsLoadingAC(false));
    }
  };

export const createWorkServiceTC =
  (dto: createWorkFormType): thunkActionRootType =>
  async (dispatch) => {
    dispatch(setIsLoadingAC(true));
    try {
      const res = await apiProSettings.createWorkService(dto);
      dispatch(createWorkServiceAC(res.data));
      dispatch(createWorkWindowAC(false));
    } catch (error: AxiosError | any) {
      if (isAxiosError(error)) {
        if (error.response?.status === 401) {
          dispatch(logoutTC());
        }
      }
    } finally {
      dispatch(setIsLoadingAC(false));
    }
  };

export const getWorkServicesTC =
  (): thunkActionRootType => async (dispatch) => {
    dispatch(setIsLoadingAC(true));
    try {
      const res = await apiProSettings.getWorkServices();
      dispatch(getWorkServicesAC(res.data));
    } catch (error: AxiosError | any) {
      if (isAxiosError(error)) {
        if (error.response?.status === 401) {
          dispatch(logoutTC());
        }
      }
    } finally {
      dispatch(setIsLoadingAC(false));
    }
  };

export const changeWorkActiveStatusTC =
  (id: string, status: boolean): thunkActionRootType =>
  async (dispatch) => {
    dispatch(setIsLoadingAC(true));
    try {
      const res = await apiProSettings.changeActiveStatusWork(id, status);
      dispatch(changeWorkActiveStatusAC(id, status));
    } catch (error: AxiosError | any) {
      if (isAxiosError(error)) {
        if (error.response?.status === 401) {
          dispatch(logoutTC());
        }
      }
    } finally {
      dispatch(setIsLoadingAC(false));
    }
  };

export const changeWorkVidzhetStatusTC =
  (id: string, status: boolean): thunkActionRootType =>
  async (dispatch) => {
    dispatch(setIsLoadingAC(true));
    try {
      const res = await apiProSettings.changeActiveStatusVidzhet(id, status);
      dispatch(changeWorkVidzhetStatusAC(id, status));
    } catch (error: AxiosError | any) {
      if (isAxiosError(error)) {
        if (error.response?.status === 401) {
          dispatch(logoutTC());
        }
      }
    } finally {
      dispatch(setIsLoadingAC(false));
    }
  };

export const deleteWorkServiceTC =
  (id: string): thunkActionRootType =>
  async (dispatch) => {
    dispatch(setIsLoadingAC(true));
    try {
      const res = await apiProSettings.deleteWorkService(id);
      dispatch(deleteWorkServiceAC(id));
    } catch (error: AxiosError | any) {
      if (isAxiosError(error)) {
        if (error.response?.status === 401) {
          dispatch(logoutTC());
        }
      }
    } finally {
      dispatch(setIsLoadingAC(false));
    }
  };

export const editWorkServiceTC =
  (id: string, payload: createWorkFormType): thunkActionRootType =>
  async (dispatch) => {
    dispatch(setIsLoadingAC(true));
    try {
      const res = await apiProSettings.editWorkService(payload, id);
      dispatch(editWorkServiceAC(id, payload));
    } catch (error: AxiosError | any) {
      if (isAxiosError(error)) {
        if (error.response?.status === 401) {
          dispatch(logoutTC());
        }
      }
    } finally {
      dispatch(setIsLoadingAC(false));
      dispatch(editWorkServiceWindowAC({ value: false, workService: null }));
    }
  };

  export const removeWorkCategoryTC = (id: string):thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
      const res = await apiProSettings.deleteWorkCategory(id)
      dispatch(removeWorkCategoryAC(id))
    } catch (error) {
      
    } finally {
      dispatch(setIsLoadingAC(false))
    }
  }

  export const editWorkCategoryTC = (id: string, name: string):thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
      const res = await apiProSettings.editWorkCategory(id, name)
      dispatch(editWorkCategoryAC(id,name))
      dispatch(editWorkCategoryWindowAC({value: false, category: {id: "",category: ""}}))
    } catch (error) {
      
    } finally {
      dispatch(setIsLoadingAC(false))
    }
  }

  export const sendTeammateTC = (userId: string):thunkActionRootType => async dispatch => {
    try {
        const res = await apiUserSuperPro.sendTeammate(userId)
        dispatch(setSearchTeammateWindowAC(false))
    } catch (error) {
      
    } finally {

    }
  }


  export const addWaTimeTablePatternTC = (data: addWaSendingTimeTableFT, tariff: tariffType):thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
      if(tariff === tariffType.SuperPRO){
        const res = await apiUserSuperPro.createWaPatternTimeTable(data)
      dispatch(addWaTimeTablePatternAC(res.data))
      } else {
        const res = await apiUserPro.createWaPatternTimeTable(data)
        dispatch(addWaTimeTablePatternAC(res.data))
      }
     
    } catch (error) {
      
    } finally {
      dispatch(setIsLoadingAC(false))
    }
  }
  export const getWaTimeTablePatternTC = (tariff: tariffType):thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
      if(tariff === tariffType.SuperPRO){

        const res = await apiUserSuperPro.getWaPatternTimeTable()
        dispatch(getWaTimeTablePatternAC(res.data))

      } else {

        const res = await apiUserPro.getWaPatternTimeTable()
        dispatch(getWaTimeTablePatternAC(res.data))
      }
     
    } catch (error) {

    } finally {
      dispatch(setIsLoadingAC(false))
    }
  }
  export const deleteWaTimeTablePatternTC = (id: string, tariff: tariffType):thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
      const res = await apiUserPro.deleteWaPatternTimeTable(id)
      dispatch(deleteWaTimeTablePatternAC(id))
    } catch (error) {
      
    } finally {
      dispatch(setIsLoadingAC(false))
    }
  }
  export const editWaTimeTablePatternTC = (data: addWaSendingTimeTableFT, id: string, tariff: tariffType):thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
      const res = await apiUserPro.editWaPatternTimeTable(id, data)
      dispatch(editWaTimeTablePatternAC(id, data))
    } catch (error) {
      
    } finally {
      dispatch(setIsLoadingAC(false))
    }
  }
