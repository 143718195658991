import { Avatar } from "../../../styles/components/Avatar"
import { FlexBox } from "../../../styles/components/FlexBox"
import { Icon } from "../../../styles/components/Icon"
import { Text } from "../../../styles/components/Text"
import { SuperButton } from "../../../styles/components/SuperButton"
import { NotifSettings } from "./NotifSettings"
import c from "../notif.module.css"
import { clientRemindersViewForNotifType } from "../../../apiTypes/root/reminders.type"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { thunkDispatchRootType } from "../../../store/storeRedux"
import { deactivateClientReminderTC } from "../../../store/NotificationReducer"
import { theme } from "../../../styles/components/Theme.styled"


type propsType = {
  clientReminders: clientRemindersViewForNotifType[]
}
type showSettingType = {
    id: string,
    value: boolean
}
type btnsArrayType = {
    title: string;
    cb: () => void;
  };
export const ClientNotif = (props: propsType) => {
    const dispatch:thunkDispatchRootType = useDispatch()
    const [isSetting, setIsSetting] = useState<showSettingType>({value: false, id: ""});
    const deactivateNotif = () => {
        dispatch(deactivateClientReminderTC(isSetting.id))
      };
    const btnsArray: btnsArrayType[] = [
        { title: "Удалить уведомление", cb: deactivateNotif },
      ];

      const settingHandler = (id: string) => {
        if(isSetting.value && (isSetting.id === id)){
            setIsSetting({value: false, id: ""})
        } else {
            setIsSetting({value: true, id})
        }
      }
    
    return (
        <>
              {props.clientReminders.map((r) => (
        <div
          className={c.notifBox}
          style={{
            width: "100%",
            height: "87px",
            borderRadius: "4px",
            background: "#f8f8f8",
            padding: "7px",
            display: "flex",
            marginTop: "10px",
            justifyContent: "space-between",
          }}
        >
          {isSetting && r.id === isSetting.id && <NotifSettings data={btnsArray} />}
          <div
            style={{
              width: "15%",
              height: "auto",
              boxSizing: "border-box",
              border: "4px solid rgb(177, 81, 129)",
              borderRadius: "93px",
              background: "url()",
            }}
          >
            <Avatar src={r.client.photoURL} size="adaptive" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              width: "60%",
            }}
          >
            <Text color={theme.colors.fonts.main} size={14} font="mont">
              Напоминание на {new Date(r.eventDate).toLocaleDateString()}
            </Text>
            <Text color={theme.colors.fonts.main} size={14} font="mont">
              Клиент:{" "}
              {`${r.client.surname} ${r.client.name} ${r.client.patronymic}`}
            </Text>
            <FlexBox style={{wordBreak: "break-all", flexWrap: "wrap"}}  width="100%" direction="row" >
              <Text  color={theme.colors.fonts.main} size={13} font="mont">{r.comment}</Text>
            </FlexBox>
          </div>
          <div
            style={{
              width: "20%",
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
            }}
          >
            <SuperButton
              onClick={() => settingHandler(r.id)}
              isAllowed
              varinat="moreOptions"
            >
              <Icon svgID="3points" width={36} height={36} />
            </SuperButton>
          </div>
        </div>
      ))}
        </>
    )
}