import { applyMiddleware, combineReducers, legacy_createStore as createStore } from "redux"
import thunk, { ThunkAction, ThunkDispatch } from "redux-thunk"
import { adminAT, AdminReducer } from "./AdminReducer"
import { CalendarReducer, calendarActionType } from "./CalendarReducer"
import { ClientCardReducer, clientCardAT } from "./ClientCardReducer"
import { ClientReducer, clientActionsType } from "./ClientsReducer"
import { ModalWindowReducer, actionsModalType } from "./ModalWindowsReducer"
import { NotesReducer, notesActionType } from "./NotesReducer"
import { ProsettingsReducer, prosettingsAT } from "./ProsettingsReducer"
import { QueueReducer, queueAT } from "./QueueReducer"
import { authAT, AuthReducer } from "./AuthReducer"
import { RemindersReducer, remindersAT } from "./RemindersReducers"
import { SearchReducer, searchActionType } from "./SearchReducer"
import { SessionsReducer, actionSessionType } from "./SessionsReducer"
import { SettingReducer } from "./SettingReducer"
import { TimeTableReducer, actionsTimeTableType } from "./TimeTableReducer"
import { UserReducer, actionUserTypes } from "./UserReducer"
import { CommonReducer, commonReducerAT } from "./CommonReducers"
import { AppReducer, AppReducerAT } from "./AppReducer"
import { ErrorMessagesReducer, errorAT } from "./ErrorMessagesReducer"
import { AnalyticReducer, analyticAT } from "./AnalyticReducer"
import { UserPayReducer, userPayAT } from "./UserPayReducer"
import { UserProReducer, userProAT } from "./UserPro.reducer"
import { CallListReducer, callListAT } from "./CallListReducer"
import { userProPlusAT, UserProPlusReducer } from "./UserProPlusReducer"
import { notificationAT, NotificationReducer } from "./NotificationReducer"




const rootReducers = combineReducers({
    user: UserReducer,
    authState: AuthReducer,
    client: ClientReducer,
    notes: NotesReducer,
    calendarState: CalendarReducer,
    settingState: SettingReducer,
    timeTableState: TimeTableReducer,
    search: SearchReducer,
    sessions: SessionsReducer,
    ModalWindowReducer: ModalWindowReducer,
    admin: AdminReducer,
    clientCard: ClientCardReducer,
    proSettings: ProsettingsReducer,
    queueState: QueueReducer,
    remindersState: RemindersReducer,
    commonReducerState: CommonReducer,
    appState: AppReducer,
    errorState: ErrorMessagesReducer,
    analyticState: AnalyticReducer,
    userPayState: UserPayReducer,
    userProState: UserProReducer,
    callList: CallListReducer,
    userProPlusState: UserProPlusReducer,
    notifications: NotificationReducer
})


export type AppRootState = ReturnType<typeof rootReducers>
export type AppActionsType =  notesActionType | AppReducerAT  | actionsModalType | actionUserTypes | errorAT | authAT | clientActionsType
 | calendarActionType | actionSessionType | searchActionType | actionsTimeTableType | analyticAT | prosettingsAT | userPayAT | clientCardAT | queueAT
 | remindersAT | userProAT | callListAT | adminAT | userProPlusAT | notificationAT | commonReducerAT
export type thunkActionRootType = ThunkAction<void,AppRootState,unknown, AppActionsType>
export type thunkDispatchRootType = ThunkDispatch<AppRootState, unknown, AppActionsType>
export const store = createStore(rootReducers, applyMiddleware(thunk))

