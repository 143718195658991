import { useForm } from "react-hook-form"
import { Box } from "../../styles/components/Box"
import { FlexBox } from "../../styles/components/FlexBox"
import { FormInputContainer } from "../../styles/components/FormInputContainer"
import { TextField } from "../../styles/components/TextField"
import { Text } from "../../styles/components/Text"
import { theme } from "../../styles/components/Theme.styled"
import { CheckBox } from "../../styles/components/CheckBox"
import { SuperButton } from "../../styles/components/SuperButton"
import { AppRootState, thunkDispatchRootType } from "../../store/storeRedux"
import { useDispatch, useSelector } from "react-redux"
import { showWaWindowAC } from "../../store/ModalWindowsReducer"
import { useEffect } from "react"
import { getWaTimeTablePatternTC } from "../../store/ProsettingsReducer"
import { FakeInput } from "../../styles/components/FakeInput"
import { Avatar } from "../../styles/components/Avatar"
import { sendMessageToClientFT } from "../../apiTypes/formTypes/wa.types"
import { sendWaMessageClientTC } from "../../store/WaReducer"
import { tariffType } from "../../store/UserReducer"

const selectProSettings = (state:AppRootState) => state.proSettings
const selectProPlus = (state:AppRootState) => state.userProPlusState
const selectModalState = (state:AppRootState) => state.ModalWindowReducer
const selectUser = (state:AppRootState) => state.user
export const WaSendWindow = () => {
    const proSettings = useSelector(selectProSettings)
    const dispatch:thunkDispatchRootType = useDispatch()
    const modalState = useSelector(selectModalState)
    const user = useSelector(selectUser)
    const userProplus = useSelector(selectProPlus)

    const {register, handleSubmit, watch, getValues, setValue} = useForm<sendMessageToClientFT>({defaultValues: {
        clientId: modalState.waWindow.client ? modalState.waWindow.client.id : ""
    }})
    const sendData = (data: sendMessageToClientFT) => {
        dispatch(sendWaMessageClientTC(data.clientId,data.text))
    }
    const closeWindow = (e:any) => {
        e.preventDefault()
        dispatch(showWaWindowAC({value: false, client: null, note: null}))
    }
   
    const getUser = () => {
        if(user.user?.tariff === tariffType.SuperPRO){
            const master = userProplus.teammates.teammates.find(t => t.user.userId === modalState.waWindow.note?.userId)
            if(!master) return
            return {lastName: master.user.lastName, firstName: master.user.firstName}
        } else {
            return {lastName: user?.user?.lastName, firstName: user?.user?.firstName}
        }
       

    }

    useEffect(() => {
         dispatch(getWaTimeTablePatternTC(user.user!.tariff))  
    },[])

    useEffect(() => {
        return () => {
            dispatch(showWaWindowAC({value: false, client: null, note: null}))

        }
    },[])
    useEffect(() => {
        const sub = watch((value, {name,type}) => {
            if(!value.pattern && type === "change" && name === "pattern"){
                setValue("text","")
            }
            if(value.pattern && type === "change" && name === "pattern"){
          
            }
        
        })
        
        return () =>  sub.unsubscribe()
    },[watch])
useEffect(() => {
    if(!watch("pattern")){
        setValue("text","")
        return
    }
    if(watch("pattern")){
        const pattern = proSettings.waTimeTablePatterns.find(v => v.id === watch("pattern"))
                const master = getUser()
                if(pattern && modalState.waWindow.note && modalState.waWindow.client) setValue("text",pattern.text.split(" ").map(el => {
                   if(el === "${date}") return new Date(modalState.waWindow.note!.date ).toLocaleDateString()
                   if(el === "${time}") return (modalState.waWindow.note!.time )
                   if(el === "${clientName}") return (`${modalState.waWindow.client?.name} ${modalState.waWindow.client?.patronymic}` )
                   if(el === "${master}") return (`${master?.lastName} ${master?.firstName}` )
                   return el
                } ).join(" ")
            )
    }
},[watch("pattern")])
    return(
        <Box onSubmit={handleSubmit(sendData)} gap={30} padding="10px" height="400px" width="320px" as={"form"} background={theme.colors.colorForm} borderRaduis="8px" zIndex={33} modal>
                        <FormInputContainer>
                            <Text font="mont" size={14} color={theme.colors.fonts.main}>Название</Text>
                           <FakeInput active={true}>
                                         {modalState.waWindow.client &&  (
                                           <FlexBox
                                             direction="row"
                                             align="center"
                                             justify="center"
                                             width="40px"
                                           >
                                             
                                               <Avatar size="newEntry" src={modalState.waWindow.client.photoURL} />
                                             
                                              
                                           
                                           </FlexBox>
                                         )}
                                         
                                         {modalState.waWindow.client && (
                                           <Text size={15} color="#fff" font="mont">
                                             {modalState.waWindow.client.surname} {modalState.waWindow.client.name}{" "}
                                             {modalState.waWindow.client.patronymic}
                                           </Text>
                                         )}
                                       </FakeInput>
                        </FormInputContainer>
                        <FormInputContainer>
                        <Text font="mont" size={14} color={theme.colors.fonts.main}>Выберите Шаблон</Text>
                            <select {...register("pattern")} >
                                <option value={""}>Не выбрано</option>
                                {proSettings.waTimeTablePatterns.map(a => <option  value={a.id} key={a.id}>{a.name}</option>)}
                            </select>
                        </FormInputContainer>
                        <FormInputContainer>
                            <Text font="mont" size={14} color={theme.colors.fonts.main}>Текст шаблона</Text>
                           
                            <textarea {...register("text")} cols={10} rows={8}/>
                        </FormInputContainer>
                        {/* <FlexBox align="center" gap={10} direction="row" width="100%">
                                            <CheckBox variant="switch" render={() => <input {...register("active")} type="checkbox" />} value={watch("active")}  />
                                                            <Text color={theme.colors.fonts.main} font="mont" size={14}>Активна</Text>
                                            
                            
                        </FlexBox> */}
                        <FlexBox gap={10}  direction="row" height="32px">
                            <SuperButton onClick={(e) => closeWindow(e)} varinat="outlined">Отменить</SuperButton>
                            <SuperButton type="submit" varinat="contained">Создать</SuperButton>
                        </FlexBox>
                    </Box>
    )
}