import { useForm } from "react-hook-form"
import { ButtonContainer } from "../../styles/components/ButtonContainer"
import { SimpleForm } from "../../styles/components/SimpleForm"
import { SuperButton } from "../../styles/components/SuperButton"
import { TextField } from "../../styles/components/TextField"
import { Title } from "../../styles/components/Title"
import { TSearchTeammateForm } from "../../apiTypes/formTypes/searchTeammateType"
import { useDispatch, useSelector } from "react-redux"
import { AppRootState, thunkDispatchRootType } from "../../store/storeRedux"
import { useEffect, useState } from "react"
import { addTeamMateAC, setSearchTeammateWindowAC, showCreateTeammateAC } from "../../store/ModalWindowsReducer"
import sprite from "../../images/sprite.svg"
import { IconContainer } from "../../styles/components/IconContainer"
import { TextContainer } from "../../styles/components/TextContainer"
import { Avatar } from "../../styles/components/Avatar"
import { Box } from "../../styles/components/Box"
import { theme } from "../../styles/components/Theme.styled"
import { socketService } from "../../apiServer/socketService"
import { searchTeammateAC, searchTeammateTC, sendTeammateTC } from "../../store/ProsettingsReducer"


const selectProSettings = (state: AppRootState) => state.proSettings
const selectUser = (state:AppRootState) => state.user
export const SearchTeamMateWindow = () => {
    const user = useSelector(selectUser)
    const proSettingsState = useSelector(selectProSettings)
    const dispatch:thunkDispatchRootType = useDispatch()
    const { register, handleSubmit } = useForm<TSearchTeammateForm>()
    const showCreatingTeammateWindow = (e: any) => {
        e.preventDefault()
        dispatch(setSearchTeammateWindowAC(false))
        dispatch(showCreateTeammateAC(true))
    }

    const search = (data:TSearchTeammateForm) => {
        dispatch(searchTeammateTC(data.profileID))
    }
   
    const sendTeammate = (userId?: string) => {
        if(userId){
            dispatch(sendTeammateTC(userId))
        } else {
            return
        }
    } 
   useEffect(() => {
    return() => {
        dispatch(searchTeammateAC({user: null,isNotFound: false}))
    }
   },[])
    
    return (
        <Box as={"form"} onSubmit={handleSubmit(search)} width="362px" height="244px" borderRaduis="16px" background={theme.colors.colorForm} direction="column" position="fixed" top="50%" left="50%" transform="translate(-50%,-50%)" zIndex={39} style={{alignItems:"center",justifyContent:"start"}} padding="34px 24px" gap={20} >
            <Title size={14}>Введите ID Сотрудника</Title>
            <>
                {
                    proSettingsState.searchTeammate.user
                        ? <>
                            <TextField gap={20} height="64px" width="320px" as="div">
                                
                                    <Avatar size="large" src={proSettingsState.searchTeammate.user.avatarUrl} />
                    
                                <TextContainer width={207}>
                                    <Title size={18}>{`${proSettingsState.searchTeammate.user.lastName} ${proSettingsState.searchTeammate.user.firstName} ${proSettingsState.searchTeammate.user.thirdName}`}</Title>
                                </TextContainer>
                            </TextField>
                            <ButtonContainer width={100}>
                                <SuperButton onClick={() => sendTeammate(proSettingsState.searchTeammate.user?.id)}  varinat="contained">Добавить</SuperButton>
                            </ButtonContainer>
                        </>
                        : !proSettingsState.searchTeammate.isNotFound
                            ? <>
                                <TextField {...register("profileID")} width="100%" placeholder="123-ABS" />
                                <ButtonContainer width={100}>
                                    <SuperButton type="submit" varinat="contained">Найти</SuperButton>
                                </ButtonContainer>
                            </>
                            : <>
                                <TextField gap={20} height="64px" width="320px" as="div">
                                    <IconContainer width={51} height={51}>
                                        <use xlinkHref={`${sprite}#refuse`}></use>
                                    </IconContainer>
                                    <TextContainer width={154}>
                                        <Title size={18}>Сотрудника нет в системе</Title>
                                    </TextContainer>
                                </TextField>
                                <ButtonContainer width={161}>
                                    <SuperButton onClick={showCreatingTeammateWindow} varinat="contained">Добавить нового</SuperButton>
                                </ButtonContainer>
                            </>
                }
            </>

        </Box>
    )
}