import { useDispatch, useSelector } from "react-redux"
import { ModalWindowStateType } from "../store/ModalWindowsReducer"
import { stateUserType } from "../store/UserReducer"
import { AppRootState, thunkDispatchRootType } from "../store/storeRedux"
import { useEffect } from "react"
import { Table } from "../styles/components/Table"
import { TableHeader } from "../styles/components/TableHeader"
import { Column } from "../styles/components/Column"
import { TableRowBox } from "../styles/components/TableRowBox"
import { TableRow } from "../styles/components/TableRow"
import { Cell } from "../styles/components/Cell"
import { Avatar } from "../styles/components/Avatar"
import { DragDown } from "../styles/components/DragDown"
import { FlexBox } from "../styles/components/FlexBox"
import { Box } from "../styles/components/Box"
import { getAllTeammatesForAdminTC, makeAdminTeammateTC, removeAdminTeammateTC } from "../store/UserProPlusReducer"
import { SuperButton } from "../styles/components/SuperButton"
import { RightsType } from "../apiTypes/root/userProPlus.type"
import { Text } from "../styles/components/Text"
import { theme } from "../styles/components/Theme.styled"


const selectUserProPluseState = (state:AppRootState) => state.userProPlusState
const userState = (state:AppRootState) => state.user

export const TeamMates = () => {
    const dispatch:thunkDispatchRootType = useDispatch()
   const userProPlusState = useSelector(selectUserProPluseState)
    const user = useSelector(userState)
   const  {teammates} = userProPlusState 
    const columnItems = [
        {title: "ФИО"},
        {title: "Клиенты"},
        {title: "Расписание"},
        // {title: "Аналитика"},
        {title: "Настройки"},
        {title: "Действие"},
    ]
   
    const checkOwner = (userId: string):boolean => {
        const owner = teammates.teammates.find(t => t.isOwner)
        if(!owner) return false
        const userIsOwner = owner.user.userId === user.user?.id
        return userIsOwner
    }
    const chekAllowed = ():boolean => {
        const man = teammates.teammates.find(t => t.user.userId === user.user?.id)
        if(!man) return false
        if(man.settingsRights !== RightsType.A || man.isOwner) return true
        return false
    }
   
    return (
        <Box  overFlowY="auto" direction="row" height="100%" width="100%">
            {/* {props.modalState && props.modalState.modeModalTeamMate && <WindowForTeamMate />} */}
            <Table>
                <TableHeader type="static" offset="small">
                    {columnItems.map(el => <Cell variant="simple">
                        <Column   component="any">{el.title}</Column>
                    </Cell> )}
                </TableHeader>
                <TableRowBox>
                {
                    teammates.teammates.map(el =>
                        el.user.userId === user.user?.id
                        ? <></>
                        :<TableRow>
                            <Cell  variant="simple">
                                <Avatar src={el.user.avatarUrl} size="small"/>
                                <FlexBox marginLeft="7px" direction="row" gap={5}>
                                    <Text transform="capitalize" color={theme.colors.fonts.main} size={12} font="mont">{el.user.lastName}</Text>
                                    <Text transform="capitalize" color={theme.colors.fonts.main} size={12} font="mont">{el.user.firstName}</Text>
                                    <Text transform="capitalize" color={theme.colors.fonts.main} size={12} font="mont">{el.user.thirdName}</Text>
                                </FlexBox>
                            </Cell>
                            <Cell  variant="simple">
                            <DragDown isAllowed = {chekAllowed()}
                             name="clientRights" teamMateID={el.user.userId} element={{value: el.clientRights, user: el}}/>
                            </Cell>
                            <Cell  variant="simple">
                            <DragDown isAllowed = {chekAllowed()} name="timeTableRights" teamMateID={el.user.userId} element={{value:  el.timeTableRights, user: el}}/>
                            </Cell>
                            <Cell  variant="simple">
                            <DragDown isAllowed = {chekAllowed()} name="settingsRights" teamMateID={el.user.userId} element={{value:  el.settingsRights, user: el}}/>
                            </Cell>
                            <Cell  variant="action">
                                {
                                    el.isAdmin ? <SuperButton disabled = {!checkOwner(el.user.userId)}  onClick={() => dispatch(removeAdminTeammateTC(el.id))} varinat="contained">Сделать сотрудником</SuperButton>
                                    : <SuperButton disabled = {!checkOwner(el.user.userId)} onClick={() => dispatch(makeAdminTeammateTC(el.id))} varinat="contained">Сделать админом</SuperButton>
                                }
                            </Cell>
                        </TableRow>
                        )
                }
            </TableRowBox>
            </Table>
           

           
        </Box>
    )

}