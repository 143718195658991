import { useEffect } from "react"
import { TableHeader } from "../styles/components/TableHeader"
import { useDispatch, useSelector } from "react-redux"
import { AppRootState, thunkDispatchRootType } from "../store/storeRedux"
import { Column } from "../styles/components/Column"
import { Avatar } from "../styles/components/Avatar"
import { Text } from "../styles/components/Text"
import { getAllTeammatesForAdminTC } from "../store/UserProPlusReducer"
import { userProPlusStateType } from "../apiTypes/root/userProPlus.type"


type propsType = {
    userID: string | null,
    day: Date
}
const selectUser = (state:AppRootState) => state.user
const selectUserProPlusState = (state: AppRootState) => state.userProPlusState
export const TeamMateHeader = (props: propsType) => {
    const dispatch:thunkDispatchRootType = useDispatch()
    const user = useSelector(selectUser)
    const userProPlusState = useSelector(selectUserProPlusState)
   useEffect(() => {
    if(!userProPlusState.teammates.teammates.length) {
        dispatch(getAllTeammatesForAdminTC())
    }   },[userProPlusState.teammates.teammates.length])
    return(
        <>
        <TableHeader type="dynamic" offset="medium">
            <Column  axis = "column" component="TimeTable">
                <Text size={13} color="#fff" font="mont" transform="capitalize">{props.day.toLocaleString("ru",{weekday: "long"})}</Text>
                <Text size={13} color="#fff" font="mont">{props.day.toLocaleString("ru", {day: "2-digit", month: "long", year: "numeric"})}</Text>
            </Column>
            {userProPlusState.teammates.teammates.map(el => 
                <Column component="TimeTable">
                <Avatar size="medium" src={el.user.avatarUrl} alt="" />
                <span>{el.user.firstName}</span>
                </Column>
                )}
                {/* <Column component="TimeTable">
                    <Avatar size="medium" src={user.user?.avatarUrl}></Avatar>
                    <Text size={14} font="mont" color="#fff">{user.user?.firstName}</Text>
                </Column> */}
        </TableHeader>
        </>
    )
}